import { PoolWarning, Pools } from '@/types/pools';
import { Network } from '../types';
import { CSP_ISSUE_POOL_IDS } from '@/constants/pool-lists/csp-issue';
import { Protocol } from '@/composables/useProtocols';

const pools: Pools = {
  IdsMap: {
    xMatic: {
      v1: '0xc17636e36398602dd37bb5d1b3a9008c7629005f0002000000000000000004c4',
      v2: '0xb20fc01d21a50d2c734c4a1262b4404d41fa7bf000000000000000000000075c',
    },
    stMatic: {
      v1: '0xaf5e0b5425de1f5a630a8cb5aa9d97b8141c908d000200000000000000000366',
      v2: '0x8159462d255c1d24915cb51ec361f700174cd99400000000000000000000075d',
    },
    mai4: {
      mai4: '0x06df3b2bbb68adc8b0e302443692037ed9f91b42000000000000000000000012',
      maiBbaUsd:
        '0xb54b2125b711cd183edd3dd09433439d5396165200000000000000000000075e',
    },
  },
  Pagination: {
    PerPage: 10,
    PerPool: 10,
    PerPoolInitial: 5,
  },
  BoostsEnabled: true,
  DelegateOwner: '0xba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1b',
  ZeroAddress: '0x0000000000000000000000000000000000000000',
  DynamicFees: {
    Gauntlet: [],
  },
  BlockList: [''],
  IncludedPoolTypes: [
    'Weighted',
    'Stable',
    'MetaStable',
    'LiquidityBootstrapping',
    'Investment',
    'StablePhantom',
    'ComposableStable',
    'FX',
    'Gyro2',
    'Gyro3',
    'GyroE',
  ],
  Stable: {
    AllowList: [
      '0x06df3b2bbb68adc8b0e302443692037ed9f91b42000000000000000000000012', // polygon MAI/DAI/USDC/USDT
      '0xfeadd389a5c427952d8fdb8057d6c8ba1156cc5600020000000000000000001e', // polygon WBTC/renBTC
      '0xf38cf113d2d4f60c36cbd95af2f48a9a0167045a00000000000000000000005b', // polygon,
      '0x0d34e5dd4d8f043557145598e4e2dc286b35fd4f000000000000000000000068', // tusd polygon
      '0x5028497af0c9a54ea8c6d42a054c0341b9fc616800020000000000000000007b', // dusd polygon
      '0xaf5e0b5425de1f5a630a8cb5aa9d97b8141c908d000200000000000000000366', // polygon staked matic
      '0xc31a37105b94ab4efca1954a14f059af11fcd9bb000000000000000000000455', // 4pool
      '0xc17636e36398602dd37bb5d1b3a9008c7629005f0002000000000000000004c4', // maticx metastable
      '0xb4b22bd6cdad0ab828be6f8a4086dfa54e9b373600020000000000000000058f', // Polygon tetuBAL-80BAL-20WETH
      '0xb797adfb7b268faeaa90cadbfed464c76ee599cd0002000000000000000005ba', // tetuBAL-80BAL-20WETH V2 (with short name)
      '0x0b8319061732b34cab22445fa83b81f950e4b7ed000000000000000000000709',
      '0x8159462d255c1d24915cb51ec361f700174cd99400000000000000000000075d',
      '0xb20fc01d21a50d2c734c4a1262b4404d41fa7bf000000000000000000000075c',
      '0xb54b2125b711cd183edd3dd09433439d5396165200000000000000000000075e', // mai / bb-am-USD
      '0x48e6b98ef6329f8f0a30ebb8c7c960330d64808500000000000000000000075b', // bb-am-USD
      '0xa48d164f6eb0edc68bd03b56fa59e12f24499ad10000000000000000000007c4', // ageur stable
      '0x2d46979fd4c5f7a04f65111399cff3da2dab5bd9000000000000000000000807', // ankr stable
      '0x47401399b2eca91930c99126df20a11531f99465000000000000000000000840', // 3brl pool
      '0x76afd126f46ab4fdf2ece8b1a2c149f7cf95d9fb00000000000000000000085c', // 2cad
      '0x92bc61bd96f275ea5507a3e1e5fbf0bc69cc98dc00000000000000000000085d', // 2sgd
      '0x7d60a4cb5ca92e2da965637025122296ea6854f900000000000000000000085e', // 2eur par
      '0x94970a3f6a6aab442aefad68ff57abec0b9e3c0100000000000000000000085f', // 2eur eurt
      '0x7913e4c8d00044689ff5c7c12d2f1b4a2fde4994000000000000000000000860', // 2eur eure
      '0x7982c1b61abdc36942301ff2377d92b43784f120000000000000000000000861', // 2try
      '0x7f408fbcfc88917bff6a79b0ed0646fa090627de000000000000000000000863', // 2jpy
      '0x9e0a3a9b5a4e0b6dc299a56ef19002f23842be8d000000000000000000000862', // 2mxn
      '0x05f21bacc4fd8590d1eaca9830a64b66a733316c00000000000000000000087e', // tetuQI
      '0x02d2e2d7a89d6c5cb3681cfcb6f7dac02a55eda400000000000000000000088f', // csMatic
      '0xe22483774bd8611be2ad2f4194078dac9159f4ba0000000000000000000008f0', // 2BRL
      '0xbf29ef6e23af0ac5b6bf931c8b3f1080f5bc120600000000000000000000091f', // vQi stable
      '0x34a81e8956bf20b7448b31990a2c06f96830a6e4000200000000000000000a14', // wUSDR
      '0x5dee84ffa2dc27419ba7b3419d7146e53e4f7ded000200000000000000000a4e', // frxETH / WETH
      '0xd80ef9fabfdc3b52e17f74c383cf88ee2efbf0b6000000000000000000000a65', // tetu boosted
      '0x513cdee00251f39de280d9e5f771a6eafebcc88e000000000000000000000a6b', // 2eur/par
      '0x77e97d4908be63394bc5dff72c8c7bddf1699882000000000000000000000a6a', // augeur
      '0x3db543faf7a92052de7860c5c9debabee59ed5bd000000000000000000000a62', // 4usd
      '0x65fe9314be50890fb01457be076fafd05ff32b9a000000000000000000000a96', // wsteth/eth
      '0xb3d658d5b95bf04e2932370dd1ff976fe18dd66a000000000000000000000ace', // bb-t-USD (tetu managed boosted pool)
      '0x9a020bdc2faff5bd24c6acc2020d01ff9f2c627a000000000000000000000ae2', // overnight davos usd
      '0x19017f2919a5fb7eca1f0d142330644dc2045423000000000000000000000af9', // 2EUR (EURe)
      '0x02559a4fa0f3dae55820a65eb48b7a2fcd82f361000000000000000000000af8', // 2EUR (EURs)
      '0x36a0ee903841584f47e3c774b59e0cbfba46080f000000000000000000000b0a', // ankMatic/Matic
      '0x71bd10c2a590b5858f5576550c163976a48af906000000000000000000000b27', // bb-t-MATIC (tetu managed boosted pool)
      '0x511a0fb938bb4aee2a3a9d74f68e37bac8318fbf000000000000000000000b18', // rmatic/wmatic
      '0xe78b25c06db117fdf8f98583cdaaa6c92b79e917000000000000000000000b2b', // bb-a-matic / maticx
      '0x216690738aac4aa0c4770253ca26a28f0115c595000000000000000000000b2c', // stmatic / bb-a-matic
      '0xb371aa09f5a110ab69b39a84b5469d29f9b22b76000000000000000000000b37', // bb-a-usd aave v3
      '0x4a77ef015ddcd972fd9ba2c7d5d658689d090f1a000000000000000000000b38', // wseth/bb-a-eth
      '0xe19ed40a47f9b0cea4ca6d372df66107758913ec000000000000000000000b41', // 2brl
      '0xd00f9ca46ce0e4a63067c4657986f0167b0de1e5000000000000000000000b42', // bb-a-weth/frxeth
      '0x8fbd0f8e490735cfc3abf4f29cbddd5c3289b9a7000000000000000000000b5b', // frax/bb-am-usd
      '0xac2cae8d2f78a4a8f92f20dbe74042cd0a8d5af3000000000000000000000be2', // stMATIC-bb-a-WMATIC
      '0x402cfdb7781fa85d52f425352661128250b79e12000000000000000000000be3', // MaticX-bb-a-WMATIC
      '0xb266ac3b7c98d7bcb28731dac0ef42dba1b276be000000000000000000000be4', // truMATIC/bb-a-WMATIC
      '0x9321e2250767d79bab5aa06daa8606a2b3b7b4c5000000000000000000000bf4', // bb-t-USD
      '0x03090a9811181a2afe830a3a0b467698ccf3a8b1000000000000000000000bf5', // bb-am-USD
      '0xab269164a10fab22bc87c39946da06c870b172d6000000000000000000000bfc', // wstETH-bb-a-WETH-BPT
      '0xf42ed61450458ee4620f5ef4f29adb25a6ef0fb6000000000000000000000bf8', // frxETH-bb-a-WETH
      '0xa8bf1c584519be0184311c48adbdc4c15cb2e8c1000000000000000000000bf6', // FRAX-bb-am-USD
      '0x42942cdec85078cf0e28e9cb5acd40cb53997ed6000000000000000000000bea', // BRX-jBRL
      '0x70ff0078d55ce9c1a0e668f35eb4400a4300122d000000000000000000000beb', // jCHF-vCHF
      '0x2645b13fd2c5295296e94a76280b968bdcbbdfed000000000000000000000c11', // DUSD/bbaUSD
      '0x7af62c1ebf97034b7542ccec13a2e79bbcf34380000000000000000000000c13', // tetubal/aurabal
      '0x89b753153678bc434c610b7e9182297cada8ff29000000000000000000000c21', // stMATIC-WMATIC-BPT
      '0xcd78a20c597e367a4e478a2411ceb790604d7c8f000000000000000000000c22', // maticX-WMATIC-BPT
      '0xdc31233e09f3bf5bfe5c10da2014677c23b6894c000000000000000000000c23', // wstETH-WETH-BPT
      '0xc474b8512664edc9e746ee5e786478aea15887d2000000000000000000000c25', // frxETH-WETH-BPT
      '0x8c89fb53d4d83173dd65b7a94569558ce393460f000000000000000000000c26', // FRAX-USDC-BPT
      '0x6da7f1c193ab6c4bd16eb14a6305bba8d25737ff000000000000000000000c29', // DUSD-USDC-BPT
      '0x6a6525d88de3c2434dc060f54eaec8fdfa12869c000000000000000000000c5e', // 2brl (BRLA)
      '0x46356123e062f93d6b0d56f6ff6c3744e87c22d7000000000000000000000ca6', // p-cs-kp-eur
      '0x4cd8a3df2536100ec552a37c813a9414123e1c03000000000000000000000d5e', // SWEEP-USDC-BPT
      '0x951d84e358dd8ad6b3ae6220981bcfc4baf95c84000000000000000000000d62', // TruMATIC-WMATIC
      '0x4b7586a4f49841447150d3d92d9e9e000f766c30000000000000000000000e8a', // USDC/USDT/DAI
    ],
  },
  Investment: {
    AllowList: [''],
  },
  Weighted: {
    // Deprecated list, no longer in use
    AllowList: [
      '0x7104ad560c1d74aabc108bc51114db121b927a9d000200000000000000000b2a', // 80XES/20USDC
      '0x3efb91c4f9b103ee45885695c67794591916f34e000200000000000000000b43', // bb-am-usd/2brl
      '0x7f4f4942f2a14b6ab7b08b10ada1aacede4ee8d4000200000000000000000b44', // stmatci-bb-wmatic/bb-am-usd
      '0x8fd39252d683fdb60bddd4df4b53c9380b496d59000200000000000000000b45', // wsteth-bb-a-weth/bb-am-usd
      '0xfaf3bc722d34146be83a2aac40b43148a51a9126000200000000000000000b4c', // 50kacy/50weth
      '0xbadcdaf6212b0bacf45cc32447cb615f85fe6adf000100000000000000000b87', // 50weth/50wsteth/50nex
      '0xb1cc24df1d3d2c6cb798b1129ddaae9e29d437c0000100000000000000000b8d', // staking defi pool
      '0x9841438683c2efbfb28c94ec341544b94e4f6dd5000100000000000000000b8f', // WBTC/USDC/PAXG/WETH
      '0x945f337307ea76fdaa2590d083423850f64e247f000100000000000000000b98', // 27wstETH-7WMATIC-25WBTC-7USDC-18stMATIC-8WETH-8DAI
      '0xd7786ac3f9071ddacaf9a5b0bf443c58f8d6cf67000100000000000000000ba8', // 25MKR-25WETH-25DAI-25NEX
      '0xe93a1e6fb57d2e2be40377841a6477cfe1ef55c2000100000000000000000bbb', // AGA/AGAR/WSTETH/STMATIC/USDC
      '0x7f6a9b65cf1fc091b0e012c2908f0f711dfc95b5000200000000000000000bab', // 80mooMvxMVLP-20wUSDR
      '0xed35f28f837e96f81240ebb82e0e3f518c7e8a2f000100000000000000000bb5', // STARV2-SuperPoolV1
      '0xb49bd41ed56340674e2d2d3cfbfa9807e4e1fe27000100000000000000000bc2', // 25SUSHI-25WETH-25AAVE-25agEUR
      '0xca0f8eb1b81e1a69fac97f1c898e0938c768eb20000200000000000000000bc1', // 30WBTC-702BRL (BRZ)
      '0x2e1fd968a7185f52cfb933e51b5bb7f8f43e6105000100000000000000000bc5', // 10WBTC-10USDC-40WETH-40NEX
      '0x3c2f277eedac899f67116ad598664f0f91bac210000100000000000000000bcd', // 20AGA-20RNDR-20MANA-20DIMO-20AGAr
      '0xe833723690873780b967ad94a1218eb410319411000200000000000000000bc8', // wstETHUSDC
      '0x2814a9f16d7b5b5826df47f702f16279ccd799c8000200000000000000000bd1', // 50wMatic-50stMatic
      '0xa874e67a1b203819bce84f161eba4b4eb3f4359b000200000000000000000bcc', // 50USDC-50TRYB
      '0xa5a935833f6a5312715f182733eab088452335d7000100000000000000000bee', // 30WBTC-20stMATIC-bb-a-WMATIC
      '0xaaf737aeb1e5f1dc9429de4a639fe16c42fa1fe3000200000000000000000bf9', // 50fireEP-50FBX
      '0x6036b4d28685d28cc0891b5f63c3449543cb9821000200000000000000000c07', // 50usdc-50Dai
      '0xd2f3b9e67c69762dd1c88f1d3dadd1649a190761000200000000000000000bf7', // 2BRL-bbamUSD
      '0x788d7f70ce60a4c1a91e5d0a7ee63d636177c52a000200000000000000000c0b', // MATIC-UAHT
      '0x809c86c9d01ef7e44345a8110474c551359aac44000200000000000000000c0a', // UAHT-USDC
      '0x8670b3b82ddf5e1137c638969c3f747923268d77000200000000000000000c09', // UAHT-USDT
      '0x401721a526e82d5c652fac001b691bb053380ff5000200000000000000000c0e', // 80BETS/20stMATIC
      '0x74c7a222fb745628760b01ca99f51f9473377b95000200000000000000000c08', // 90MASQ-10wstETH
      '0x84fead5f6fc27ca9ba5b89ddc3d6035dce511013000200000000000000000c12', // 50miMATIC-50USDT
      '0x0e6d6ec825c9db806384da12eba4fb67a85769fd000200000000000000000c14', // 50ankrMATIC-50stMATIC
      '0xa88e4fae334a2237e300592ae7d52705cade95a0000200000000000000000c17', // 50WBTC-50MaticX
      '0xf7fd196c4606fb78178a85d1059f8224ddbccd2d000200000000000000000c18', // 50DAI-50miMATIC
      '0xacb0bd6f7206174c64cef5e05c4d7871ea888d33000100000000000000000c19', // 33WBTC-33WETH-33NEX
      '0x93a97f352c8be88d1468b8f7e0c24ce535be9eae000100000000000000000c1a', // farmDE
      '0x5250525a8fd922ec87b42c42b0ea71fd46565cb0000200000000000000000c1e', // xWARU
      '0x1cca7b11a3b36082c13e022d441b2da3a4d7206f000100000000000000000c1d', // 33rETH-33WETH-33NEX
      '0x16b98793f3e6a17d15931a2c9f98fe28d1c845a1000100000000000000000c1f', // 1stMATIC-bb-a-WMATIC-BPT-0QI-50STARD-0fireEP-1bb-am-USD-1vQi-47STARV2
      '0xc976855d2201298b8b35ad523dfb4cbd67ad6838000200000000000000000c20', // 50WETH-50NEX
      '0x513f69b2e2a6fa0347529e6178002213cf60ce3d000200000000000000000c24', // 50WETH-50AAVE
      '0xec7b8286e906fd74f14d0a0bdf0d362e0d1a3f9c000100000000000000000c1c', // 45WETH-45WMATIC-10TSB
      '0x0dd7398a4366efad04bad15ad0d6f3efd1682cd0000200000000000000000c28', // 50SUSHI-50USDT
      '0x2fb9cc718d45867688da816c35858a436c1b62e4000200000000000000000c2d', // 50STARD-50STARV2
      '0x78e0f9e91fe77058d13a7162b72a5d5e5f2bbd23000200000000000000000c2f', // 50STARD-50wUSDR
      '0x6f662a90bc9c48a2acb0958df49c71f35d7bf248000200000000000000000c30', // 50tetuQi-50STARV2
      '0xc75daa752fb3f160b4c96364f2ee12e3434df655000200000000000000000c31', // 50wstETH-50bIB01
      '0xfff3a0c1a7d3d3d4531c2c455954dea79fa94cbf000200000000000000000c38', // 20RBW-80UNIM
      '0x7702a652b86d851d3582067f1f4aae5ef7854dee000200000000000000000c3f', // 85TQi-15Qi-5%-SDAO
      '0x1f769840cb572b853ef3b0df1a566d74dbc1b8de000100000000000000000c3e', // 70tetuQi-10QI-20vQi
      '0x040211b2492d7540ebb6e81ec84b116684fed129000200000000000000000c3d', // 85tetuQi-15QI
      '0x34299dd7f2971ce9673a6c156b9a5a49844492d0000200000000000000000c40', // 50DE-50DAI
      '0x4830eac1aaae42d62d1e596237997158e4beea0f000200000000000000000c42', // 50DE-50TBY
      '0xe93f9dd10b0ba38831cba4d5a9d092e22db130ec000200000000000000000c44', // BPT
      '0x8a352ffbc8c8bc87d7e33255a34f2cf5543e6416000200000000000000000c46', // 80CNT(NEW)-20WETH
      '0x3078fcdfe3a34feebcb13bbfc74a02790d0ad1cd000200000000000000000c4d', // WSD
      '0x80bd7a851d9ca7f2b708aa3d395c64be0bb049b7000200000000000000000c4b', // 1WMATIC-99AVA
      '0xe4f47361480bc3b37dc2d2842f97dee2da17f31b000200000000000000000c51', // AFSD
      '0x1d17bfefbf304b04939519b46c4c75fcf4889623000100000000000000000c52', // 15WMATIC-10BAL-15SAND-45GROSH-15TEL
      '0xafd8a58780f08fdac15c8c36cae7b1d6fa3f7616000100000000000000000c54', // 25WBTC-25SOIL-25WETH-25NEX
      '0x54c1cd3db3d6eeb1f041d02140b80639068a636c000100000000000000000c55', // 25WMATIC-25WBTC-25WETH-25NEX
      '0x6e9e1395534cc43b7295ae412e0293b48844dc0e000200000000000000000c56', // 1USDC-99SOL
      '0x530c6845f4acebc324e1f549589b4944e7b17079000100000000000000000c5a', // 25WBTC-25WETH-25SOL-25NEX
      '0x6ad06841ce54b8498d7159f50b22ecb05ea46b7c000200000000000000000c59', // 50fireFBX-50fireEP
      '0x9c362d37a40d0cd3cac9ffc2427eb5239dd4b194000100000000000000000c5c', // 33USDC-33WETH-33DAI
      '0x9627205fc978b118c2d10a8b7761974a26582d77000200000000000000000c5b', // 70WBTC-30USDC
      '0x51fc57e13a7837d4890f1bcffbad285b7d00f7c1000200000000000000000c5f', // 80DE-20WBTC
      '0x3234df8de6108482c3bd24eadcb4ed0f777b113e000200000000000000000c60', // 80DE-20WETH
      '0x1b82799aefc5049ea30a88b89171702b3cacfa74000200000000000000000c65', // 5020WETH-80BAL-50tetuBAL
      '0x02db2baa9a2b90b931416703ee83a11943d5c571000200000000000000000c66', // 50wstETH-50MaticX
      '0x7666f4daca9f9243a796aa429a7f43c77060470a000200000000000000000c68', // 10USDC-90SPHERE
      '0xaecbdbeb6f4c1e4ee5b65e397249a49691b538fc000100000000000000000c6a', // 20USDC-50PAXG-10DAI-20USDT
      '0x9105c470449921fb7061efceb318e30662d14621000100000000000000000c6c', // MATIC-ETH-BTC-MAI/StarSeeds-Protocol
      '0x559abdfcde5d1970dc1c98992742e0beb7441c00000100000000000000000c6b', // TetuQI-VQi-Matic/StarSeeds-Protocol
      '0xe603104baf3743ce615cce88f08f229f9620c924000200000000000000000c6d', // STARV2-StarSeeds-Protocol
      '0xafb5d11629f3fe66ff2d2595180c1ed83a5e7b55000100000000000000000c6f', // money-machine-v1-starseeds-protocol
      '0x1bc9a8f8855ca78896eee5d1d573fb5cbca2ce75000100000000000000000c6e', // LP-Test-StarSeeds-Protocol
      '0x4a818e84e67866484abbf0003da1bed9cc2bbafc000100000000000000000c71', // TetuBal-BalwETH-Matic-StarSeeds_Protocol
      '0x11ff73fa7ffa63d3de1d2e1afb77894274259d88000200000000000000000c75', // STARV2-Link/ArbitrageLP/StarSeedsProtocol
      '0x17abb6ffe47e4ab120aa57de5e1c7fc6fe307633000200000000000000000c74', // STARV2-wstETH/ArbitrageLP/StarSeeds_Protocol
      '0xb56ff8104cf3e42065b1613b4549aab12fa91646000200000000000000000c73', // STARV2-WBTC-arbitrage/StarSeeds-Protocol
      '0x2e7f4db55bf11e0d8c25b0007728dfb2d138f904000100000000000000000c72', // STARV2-APR-FARM-V1-StarSeeds_Protocol
      '0xd6e5704d4b43d79f1ea597a9c36105baf2dea993000100000000000000000c70', // Big-Three-V2-StarSeeds-Protocol
      '0xf8c6f6223ee9d7fa55a508ef96f951f92f412612000200000000000000000c77', // 20WMATIC-80EAC
      '0xed6850f0400c111e49ee3f4bea44d19b438b4611000200000000000000000c79', // 2CLP-TVaDAIv2-DAI
      '0xa5b7c3e5fdd26bb99a50a5dcb32b16bfa5060595000200000000000000000c7a', // JARVIS-ETH 8020
      '0x99d32658012027ff500a1446c35a8e5419ba45c0000100000000000000000c7e', // 33wstETH-33tetuBAL-BALWETH-33STARV2
      '0xdc8787134cf477f1e70c5b0ae402e57ea198dbd0000200000000000000000c7d', // STARV2-Tetu/Arbitrage/StarSeeds_Protocol
      '0xd04ad53b5d81bd9d129aaef7731eee1843c8dae0000200000000000000000c7b', // STARV2-MAI/Arbitrage/StarSeeds_Protocol
      '0x3443e3942053fc44ee07b7ad62a04276dd52b4a9000200000000000000000c7f', // STARV3-WMATIC/80-20/StarSeeds_Protocol
      '0x5367e1f7f691c6be07a16ded1c9b05f3cf38b217000200000000000000000c80', // STARV3-WBTC/65-35/StarSeeds_Protocol
      '0x140bec9e5749dfd6ea04c8587a87027617c381d2000200000000000000000c81', // STARV3-WETH/65-35/StarSeeds_Protocol
      '0x3f33dc5e2e6dd9c3619ba3aed37f8c5568161ab9000200000000000000000c85', // STARV3-STARV2/3-97/StarSeeds_Protocol
      '0x376bb22376e0c57b4dd50efee1bd3c986978a490000200000000000000000c88', // STARV3-STARV2/9-91/StarSeeds_Protocol
      '0x2dc47abce2a3d5644139c663493c2bb825d2b103000200000000000000000c84', // STARV3-LINK/65-35/StarSeeds_Protocol
      '0x2122186d224a586246a534577aa14c8c77a7a5f2000200000000000000000c82', // 1WMATIC-99waWMATIC
      '0x84ff1ba4faf7d74bc8e66693b2d3905424b93327000200000000000000000c87', // STARV3-TETU/50-50/StarSeeds_Protocol
      '0x41682f67b30d011fcb045550d572655b22040985000200000000000000000c83', // STARV3-MAI/50-50/StarSeeds_Protocol
      '0x122c69609ea77758694050d6aca03dc95cbc6c30000200000000000000000c86', // STARV3-TETUQI/17-83/StarSeeds_Protocol
      '0x432d58fe7a40c5cf159c229ee5874f1f0ce44c91000100000000000000000c89', // STARV3/FeeV2/StarSeeds_Protocol
      '0x5cd533140618743be9b2bcbf91f50238f3479578000100000000000000000c8a', // STARV3-TETUBAL-TETUBAL-BALWETH-XTETUBAL-MIMATIC-WMATIC
      '0x0d8573d0b4542797be945f741cb8e6b7117dc751000100000000000000000c8b', // STARV3-XTETUBAL-BALWETH-USDC/Arbitrage/StarSeeds_Protocol
      '0x2d753d4ebf91b137b161b69bdb8638cf4b80c4a5000200000000000000000c8d', // 50fireEP-50ICHI_Vault_LP
      '0x0abc5370a1bced0e78222dba60ca69189aad7ed9000200000000000000000c8c', // STARV3-XTETUBAL-BALWETH/Arbitrage/StarSeeds_Protocol
      '0xdcd3fc34d581ee474520b0f6bce9412df7d2e32f000200000000000000000c90', // 95DE-5DAI
      '0x4d1249c87e451de44b5bd7d4f17b2d333b3c3723000200000000000000000c92', // STARV3-xtetuBAL-BALWETH/50-50/StarSeeds_Protocol
      '0xeba5452e53293d195bf436dc53750c594504b34e000200000000000000000c93', // STARV3-tetuBAL-BALWETH/50-50/StarSeeds_Protocol
      '0x8d95d14a46b2c535494e725e532b3756e9026c72000200000000000000000c95', // STARV3-FBX/50-50/StarSeeds_Protocol
      '0x88b657ce391a034939e86d0698574c6b3a3e6365000200000000000000000c96', // STARV3-ICHI_Vault_LP/50-50/StarSeeds_Protocol
      '0x9c57dc0639523e7cc1dba4846d17d3f31d12f45a000200000000000000000c97', // STARV3-fireEP/50-50/StarSeeds_Protocol
      '0x91c22f98d8ca24a86a443c122c484ff91e38835b000200000000000000000c98', // STARV3-wstETH/50-50/StarSeeds_Protocol
      '0x1ae0ae6917f52a40748142f0373859e9635d3396000200000000000000000c99', // STARV3-STARV2/30-70/StarSeeds_Protocol
      '0xda53afe8ca5f935e19d1af46d7524460cea09862000200000000000000000c9a', // pStorage
      '0xd2ad70856dab2b68a9c43f63e2211053c2232c31000200000000000000000c9b', // 50GRT-50wPPC
      '0xdc7623c2c091c8f1f180a20de6443cfe4a8e7da7000200000000000000000c91', // 50GRT-50wPPC
      '0xaea9eac8fc07ed24502270d59fa41c03575758bb000200000000000000000c9c', // 50USDC-50wPPC
      '0x51a7c7be986f890faa9de665a8fd7f4d9398d9a1000200000000000000000c9e', // liveRetro-WMATIC/50-50/StarSeeds_Protocol
      '0x8ea81c2c0dcd0baf5ecbbc2dec334ebd56dec545000200000000000000000c9d', // STARV3-MAI/88-12/StarSeeds_Protocol
      '0x0156e921739ac090c3717e84d78b64bd08ea94ca000200000000000000000ca0', // STARV3-liveRetro/40-60/StarSeeds_Protocol
      '0xe2b1b8ae0a5440ee91ebd586506fbb4c0a8500bd000200000000000000000ca1', // oRETRO-WMATIC/50-50/StarSeeds_Protocol
      '0x17a00f034836c7a9c666cd46086713af3b58a9a5000200000000000000000ca2', // STARV3-oRETRO/40-60/StarSeeds_Protocol
      '0xda2e07ae9111821dea416ea1fbdaf4d2bd407fbc000200000000000000000ca3', // 50WMATIC-50wPPC
      '0x46ff08b620b2586fc068ae26b12db4422c405a95000100000000000000000ca4', // bStorage
      '0x8a70adba1114a969e801fe93fb9acbd12bc27cbd000100000000000000000ca8', // STARV3/Volatile-Growth/StarSeeds_Protocol
      '0x1265ad6024c9db3c70e2337e20007eafcef6ded2000200000000000000000caa', // STARV3-miMATIC/45-55/StarSeeds_Protocol
      '0x589e973033cd02bb4426b4438b54297591779dd4000200000000000000000cab', // 50WBTC-50WETH
      '0xd7b928ead272e9e610db5df16d4a607e31cc2587000200000000000000000cad', // PAXStorage
      '0xc31f6ecbafaa6c3a3c69d837f7ffdda74391fc83000200000000000000000cae', // STARV3-JRT/60-40/StarSeeds_Protocol
      '0xb09f896f6fe2cf4fe5bee2eab5ddef16ecea7acd000200000000000000000cb1', // 70wstETH-30WETH
      '0x6bf004bee6346852a29239b386ab4239ffbd66de000200000000000000000cb5', // LP-VNXAU-USDC
      '0x32cc63ffeccb7c0508d64e4d37145313cc053b27000200000000000000000cb4', // LP-VCHF-USDC
      '0xf16dc9f530ad9a68b7321911a85844a45b90bd67000100000000000000000cb6', // STARV3-wstETH-WMATIC-MiMATIC-Retro-oRetro-liveRetro/Growth/StarSeeds_Protocol
      '0x66fff1abb51ca188942a2417d1650fe09fd23a74000100000000000000000cb7', // STARV3-stMATIC-wstETH-oRetro-liveRetro-MiMATIC/Exit-Router/StarSeeds_Protocol
      '0x1399d2b825c927409ccd77e86447c40e378dc580000100000000000000000cb3', // 33VNXAU-33VCHF-33VEUR
      '0x69301ddb9273918303171b003e9e4ba27527176b000100000000000000000cb2', // STARV3/Growth/StarSeeds_Protocol
      '0xd550982a2840efae469fc17d107522b2ae87fe87000100000000000000000cb8', // STARV3-WMATIC-wstETH-MiMATIC-Retro-oRetro-liveRetro-xTetu/Growth/StarSeeds_Protocol
      '0xd7ca77afaab24f8a4a66def0cbaad312bf465983000200000000000000000cb9', // STARV3-WETH/50-50/StarSeeds-Protocol
      '0x17aa61c1af021759a0a2d21f3bfe8b7731083bfd000200000000000000000cbb', // STARV3-tetuQI/Test/StarSeeds_Protocol
      '0x70c7f91f35fb6b7bc9c80e810dacca62b111a8dd000200000000000000000cba', // STARV3-DYST/60-40/StarSeeds_Protocol
      '0x5f1109b2ff7ec99fc47c81b20b6344aec3843a94000200000000000000000cbd', // STARV3-M-wstETH-ETH/80-20/StarSeeds_Protocol
      '0x4e09635a37aae4681206cbbd1644a6be84dbe988000200000000000000000cbf', // STARV3-M-tetuQi/60-40/StarSeeds_Protocol
      '0x1049feba7c1471fded82f668acfa38b5cb892b0f000200000000000000000cc6', // STARV3-vQi/60-40/StarSeeds_Protocol
      '0x5e36bcffa79f0061cc1e266225359d36a0e4b71e000200000000000000000cc3', // DAO-WBTC/80-20/StarSeeds_Protocol
      '0x60f46b189736c0d2ae52a79382b64c1e2a86b0d9000200000000000000000cc4', // DAO-st/WMATIC(C)/80-20/StarSeeds_Protocol
      '0x58830189ac431c2c492829cd9e6d6527829281ec000100000000000000000cc5', // STARV3-DAO-STARV3-M/30-28-42/StarSeeds_Protocol
      '0x41530f7c7f118f07116a927253b10705116118c7000200000000000000000cc2', // STARV3-M-st/WMATIC(C)/80-20/StarSeeds_Protocol
      '0xa3d7e7fccc7a957f4ab16f0ec6f526e9bd5ea615000200000000000000000cc1', // STARV3-M-vQi/60-40/StarSeeds_Protocol
      '0x590d5b01468868bb3af8fc130c77219a1d968db8000200000000000000000cc0', // STARV3-STARV3-M/30-70/StarSeeds_Protocol
      '0x748d7abd46c879dba06843a6ae0221688e8d08c1000100000000000000000cc9', // STARV3-M-STARV2-tetuQi-vQi-oRetro-liveRetro-st/WMATIC(C)-wstETH-ETH(C)-30-30-10-10-5x4/StarSeeds_Protocol
      '0xba92fdde8d27540d50dfff601b673bf8ef5614d0000100000000000000000cc8', // STARV3-M-DAO-STARV2-wstETH-ETH(C)/40-25-20-15/StarSeeds_Protocol
      '0x8cff4f4ae0a3809ff91273bd0cd3e6805462d184000100000000000000000cca', // 25WBTC-25mooCompoundPolygonUSDC-25ICHI_Vault_LP-25ICHI_Vault_LP
      '0xd041829822b8af6b199c9031a966184ecda086ce000100000000000000000cc7', // STARV3-M-DAO-STARV2-st/WMATIC(C)/33-22-30-15/StarSeeds_Protocol
      '0xf313acc24aaa76af362ee4371251312cec90884d000100000000000000000ccc', // STARV3-oRETRO-STARV3-M/40-15-45/StarSeeds_Protocol
      '0xc59e114c470c96da70f45e67583a25dca0dbc259000100000000000000000ccd', // STARV3-liveRetro-STARV3-M/33-22-45/StarSeeds_Protocol
      '0x9a9563a975e8c61f60620971e10cc09eadc3716c000100000000000000000cce', // STARV3-oRETRO-st/MATIC(C)-wstETH-ETH(C)-STARV3-M/30-15-10-5-40/StarSeeds_Protocol
      '0xfe0737e6e22f7e4c39c526a458e44f618af53b40000100000000000000000ccb', // STARV3-M-miMATIC-STARV2/45-18-37/StarSeeds_Protocol
      '0x1cfb761fc8e626f83f4e4b235623029e8ca06a42000100000000000000000ccf', // STARV3-tetuQi-STARV3-M/30-20-50/StarSeeds_Protocol
      '0xa326d263145bd9849698eee4e0669fc0dedbab70000200000000000000000cd1', // STARV3-st/WMATIC(C)/80-20/StarSeeds_Protocol
      '0xb48f108dbd5122a59616db806de59bf347fe6369000200000000000000000cd0', // STARV2-wstETH-ETH(C)/80-20/StarSeeds_Protocol
      '0x611ee2908ac1ee2ad84815d3d1489717d6050175000100000000000000000cd2', // STARV3-vQi-STARV3-M/35-20-45/StarSeeds_Protocol
      '0x90dccf391004380bdafee7910cdd002b8c4de81d000200000000000000000cd4', // STARV2-DAO/60-40/StarSeeds_Protocol
      '0x9eafd3b6d65b0d099747e2942eed730176068267000200000000000000000cd5', // STARV2-st/WMATIC(C)/60-40/StarSeeds_Protocol
      '0xa500c998642f1eaa75b099faf381c6403d101cb7000200000000000000000cd3', // STARV3-RETRO/67-33/StarSeeds_Protocol
      '0xe5ccde10aa827c643142ea85c3b8200eb1bf7c70000100000000000000000cd6', // STARV3-M-st/MATIC(C)-WBTC-wstETH-ETH(C)/40-20x3/StarSeeds_Protocol
      '0x5aaf98c9f8368d714c2a9ccf30fda6e5d09cf24a000100000000000000000cdc', // STARV3-/M-tetuQi-DAO-stMATIC-wstETH-xtetuBAL/StarSeeds_Protocol
      '0x06d78cb8ef46174c379fd3433a7fb3d6eb042d61000100000000000000000cdb', // STARV3-oRETRO/Volume&Growth-V4/StarSeeds_Protocol
      '0xab83e7251819d0919270f5e6a64f551f38b78837000100000000000000000cda', // STARV3-WETH-wstETH-frxETH-xtetuBAL/StarSeeds_Protocol
      '0x8e21b7c9e7612f28e8904825fdb360d2cf45505c000100000000000000000cd8', // STARV3-WMATIC-stMATIC-miMATIC-MaticX-csMATIC
      '0xbf527eaa829ae397d6d6eacd6c5492c17f85f4cb000100000000000000000cd7', // STARV3-WBTC-WMATIC-miMATIC-WETH-oRetro/StarSeeds-Protocol
      '0x95c1383fea3b4a2223d374549c2a2881a11d923b000100000000000000000cdf', // 40WMATIC-40ICHI_Vault_LP-20STARV3-M/StarSeeds_Protocol
      '0xc3badbce85bb5528fdd6f50b8b3660894dd69c74000100000000000000000ce0', // 40WMATIC-40STEER_UNIV3_VAULT_272-20STARV3-M/StarSeeds_Protocol
      '0x79673cd4989d21db6fb69265a042c48a33d70398000100000000000000000ce2', // 3wstETH-3WMATIC-20STEER_UNIV3_VAULT_272-3WETH-50ICHI_Vault_LP-3miMATIC-12STARV3-6STARV3-M
      '0x50df79ec2b3e7b2181efc6ed481d5ab971fc9da2000100000000000000000ce4', // 5WMATIC-4DAO-36tetuQi-18QI-5miMATIC-18vQi-4xtetuBAL-BALWETH-10STARV3-M
      '0x2213a9eff264928ec7a0b2eaa4be1e9ec3cdfa9a000100000000000000000ce5', // 10WMATIC-30DAO-30STEERQV297-15STARV3-15STARV3-M/StarSeeds_Protocol
      '0x791f47f9b1d9c95853ab6e9d866e9e6c7d05689d000100000000000000000ce6', // 25WMATIC-10DAO-6WBTC-3oRETRO-8ICHI_Vault_LP-15STARV3-4xtetuBAL-BALWETH-30STARV3-M/StarSeeds_Protocol
      '0xd9eb6bbd54aa9a51ab02b624371929a8e08bc0f5000100000000000000000ce3', // 40WMATIC-40QI-20STARV2
      '0xda5d9e93f9d3506781c0660265fe195da96b84c7000100000000000000000cde', // STARV3-oRETRO-RETRO-liveRETRO/Exotic-V4.1/StarSeeds_Protocol
      '0x0c3391612cafc5f5d2d76b165c088d9819e712a4000200000000000000000cea', // 80WMATIC-20WETH
      '0x144a7fa17661ca06241d9a598bad0f893fd58c45000100000000000000000ceb', // 3DAO-38WBTC-6oRETRO-44STARV3-3xtetuBAL-BALWETH-6STARV3-M/StarSeeds_Protocol
      '0x34766324e1ec748ced10069ac0b66f1090829580000100000000000000000cec', // 38wstETH-3DAO-6oRETRO-44STARV3-3xtetuBAL-BALWETH-6STARV3-M/StarSeeds_Protocol
      '0x25a60a89eb718cb191ee53882332ce9c4d7a351c000200000000000000000ced', // 20WETH-80DPI
      '0x73117e763a58181a053680323b61d76bad00a6c0000200000000000000000ce8', // 50oRETRO-50STARV3/StarSeeds_Protocol
      '0x59c5cffc50b214481da7a6b626ea42e6b414d8d3000200000000000000000cf1', // 10WBTC-90STARV3/StarSeeds_Protocol
      '0x05e6bcf561769c5aae3b655d479f733513aea046000200000000000000000cef', // 10oRETRO-90STARV3/StarSeeds_Protocol
      '0x55e26b2511d5b543feacaa6e48ecbc1c8aa765d7000200000000000000000cee', // 10WMATIC-90STARV3/StarSeeds_Protocol
      '0x8c4916a75d084778d82f251b2a209a74cae4684a000200000000000000000cf2', // 20ICHI_Vault_LP-80STARV3/StarSeeds_Protocol
      '0x63434fc927525f1a20c33d4d0dab83c08449c4c9000200000000000000000cf0', // 10wstETH-90STARV3/StarSeeds_Protocol
      '0x314c7fcd6357c1c497023d3eebee4735a6e2c9f7000200000000000000000cf3', // 20WBTC-80STARV3/StarSeeds_Protocol
      '0xcd5421f97d8229b82ca060ccdac36dde990051a1000200000000000000000cf4', // 20oRETRO-80STARV3/StarSeeds_Protocol
      '0xbe0434ca47d8cbdc0e67c2045b2fdd683ca1f814000200000000000000000cf8', // 80STARV3-20FBX/StarSeeds_Protocol
      '0xb8989bbb0626297412a768f38dc0c5d6fb1afb0e000200000000000000000cf7', // 20TETU-80STARV3/StarSeeds_Protocol
      '0x9c2d9d722ccd1d0c20379b6b3841c3cb97100bc3000200000000000000000cf6', // 20QI-80STARV3-M/StarSeeds_Protocol
      '0x7cc02d5606aa8da29928ebe634986c99dc7dafb7000200000000000000000cf5', // 20tetuQi-80STARV3-M/StarSeeds_Protocol
      '0x5e65a0ef0c1a57cdcda3eaa162dac46dd6286af8000200000000000000000cf9', // 20DYST-80STARV3/StarSeeds_Protocol
      '0x9577155196cc40100d56c44a1d461694673e55e1000200000000000000000cfa', // 20vQi-80STARV3-M/StarSeeds_Protocol
      '0x3dbb91bdd5fc74c49146819ed58d7d204cf5a016000200000000000000000cfb', // 20WETH-80MIMO
      '0x5be8dd3c921a408b9e075a446e4cc5a4426d5964000200000000000000000cfc', // 80STARV3-20liveRetro/StarSeeds_Protocol
      '0xaa63aab023a7770049d4c3e8dad5989935debcf0000200000000000000000cfd', // 20JRT-80STARV3/StarSeeds_Protocol
      '0xb1cdf7dfe2076ffc722568e94aba7cf486928d9e000100000000000000000cfe', // 35WMATIC-35oRETRO-30STARV/StarSeeds_Protocol3
      '0x749bc036967cfd017e5475da2eb4c8b2188ad951000100000000000000000cff', // 12WMATIC-8WBTC-16oRETRO-8WETH-5miMATIC-30STARV3-5liveRetro-16STARV3-M/StarSeeds_Protocol
      '0x1fe67acef14b51a70b54d4eacc950b6c143c4527000200000000000000000d00', // 80STARV3-20RETRO/StarSeeds_Protocol
      '0xee6b0935fdf581e591a93d5d2e2448b518e631d0000200000000000000000d14', // 90SP-10MaticX/StarSeeds_Protocol
      '0x3557eb12eb5936172b098d20f7a14c472e7d0bdf000200000000000000000d12', // 50SP-50STARV2/StarSeeds_Protocol
      '0x58fc10a903ab6780598378d280b3c778ec171cb9000200000000000000000d10', // 90iBSTARV3-10MaticX/StarSeeds_Protocol
      '0x2e1924000f3821e29430c2d73591ef3a75af7970000200000000000000000d0f', // 50iBSTARV3-50STARV3/StarSeeds_Protocol
      '0xecc529860084aad5976568e3a571eaf093e875d7000200000000000000000d11', // 67SP-33iBSTARV3/StarSeeds_Protocol
      '0x17fe4b9395e1171e12a8e1204f333a57caca01c1000200000000000000000d0e', // 80SP-20STARV3/StarSeeds_Protocol
      '0xd56ddd6c3597e2954cee8aa7d0a2999ea54fff1e000200000000000000000d0d', // 90SP-10ICHI_Vault_LP/StarSeeds_Protocol
      '0x4938d6a9bf4800c0d2406c9e3af019c824f1e99c000200000000000000000d0c', // 50SP-50STARV3-M/StarSeeds_Protocol
      '0xf7379614925685c5d5c4710f918b8473cadeae14000200000000000000000d0b', // 10oRETRO-90SP/StarSeeds_Protocol
      '0x5f215d9034c38a01fbade4c1b864c4a3d9e0f48e000200000000000000000d0a', // 10WMATIC-90SP/StarSeeds_Protocol
      '0x0c6bad275868f65a8bb2dd6bef292a5c32b74667000200000000000000000d04', // 16oRETRO-84STARV3-M/StarSeeds_Protocol
      '0x128e46e6a6aa282a3ef995317403a211ac02bbd3000200000000000000000d03', // 6.84oRETRO-93.16STARV3-M/StarSeeds_Protocol
      '0xbc01f6f0df116c8e624935dc318ae9655c6f3dc0000200000000000000000d13', // 80SP-20miMATIC/StarSeeds_Protocol
      '0x763f96e09e77518b82108ba9d8cd014467813157000200000000000000000d15', // 50DAO-50SP/StarSeeds_Protocol
      '0x1fed618d1d7fa27970d927cc93f3d21e60f34b88000200000000000000000d16', // 90SP-10STARV3/StarSeeds_Protocol
      '0xbbaaa66e8df3a6427f68145f3e0713ed61b52a41000200000000000000000d08', // 90STARV3-10MaticX/StarSeeds_Protocol
      '0x35b75c5a12420ed11487a7fd6e21a545650068f2000200000000000000000d09', // 90STARV3-10csMATIC/StarSeeds_Protocol
      '0x4766d4e0099adce50d2754d38b03514d45facce0000200000000000000000d05', // 12WETH-88STARV3-M/StarSeeds_Protocol
      '0xcb9f21caca820dfc342a114b46d3dc18253c0532000100000000000000000d06', // 12DAO-44STARV3-44STARV3-M/StarSeeds_Protocol
      '0xc260f3c5a57caf193d1813d8fd0a02442073d6fa000100000000000000000d07', // 40DFX-20USDC-40TEL
      '0xedf5dabf96a06f5828263cf7e27915a58e3a9d7f000200000000000000000d02', // 8WMATIC-92STARV3-M/StarSeeds_Protocol
      '0x30aeac2ed5dcb59ed02c1218f88924ef1309d754000200000000000000000d17', // 80mooCompoundPolygonUSDC-20ICHI_Vault_LP
      '0x4e8c97baa6777ae00ed3fe1acb4dd54fb8fe19c5000200000000000000000d18', // 80WBTC-20ICHI_Vault_LP
      '0xda1503426f6cd9ebbab2964106f42b35c745d453000100000000000000000d19', // 30stMATIC-10SP-60STARV2/StarSeeds_Protocol
      '0x2493eedcc5a531480f94b9a8956e4d674f1b23b9000200000000000000000d27', // 50WCASH-50stMATIC/StarSeeds_Protocol
      '0x89c0e0991c2b0dd35dbb91ee972ddda7d225015b000200000000000000000d32', // 16RBW-84STARV3/Volume-V5/StarSeeds_Protocol
      '0x6ef8d440bb825f64b85cd4feb0b70636a29ef6b3000200000000000000000d2c', // 16WMATIC-84STARV3/Volume-V5/StarSeeds_Protocol
      '0xe605370fb09d841e114367f21381e5b8cfd2a28b000200000000000000000d2d', // 16mooCompoundPolygonUSDC-84STARV3/Volume-V5/StarSeeds_Protocol
      '0x117d4934272eb3e28b80a7d8b4227b32cabdcb1a000200000000000000000d33', // 84STARV3-16TEL/Volume-V5/StarSeeds_Protocol
      '0xf91c7dcba22757ba10ebd1245a837700d66641cc000200000000000000000d31', // 16tetuBAL-84STARV3/Volume-V5/StarSeeds_Protocol
      '0x51b52d8a6fa6f761fdb115b1b7dd78fac2b0cc5f000200000000000000000d2b', // 16WETH-84STARV3/Volume-V5/StarSeeds_Protocol
      '0xa9d2bdb4307b1a65854b22ed51d46c202308e43e000200000000000000000d30', // 16LINK-84STARV3/Volume-V5/StarSeeds_Protocol
      '0xa81864a7a20c813a9ca4bcf828745682c596a7aa000200000000000000000d2e', // 16miMATIC-84STARV3/Volume-V5/StarSeeds_Protocol
      '0x016f6004f6081e935a330832dfd05514c43b8534000200000000000000000d2f', // 16BAL-84STARV3/Volume-V5/StarSeeds_Protocol
      '0xf9d52b458ad70e8c61ac96915cffa51d62549bfb000200000000000000000d34', // 16GHST-84STARV3/Volume-V5/StarSeeds_Protocol
      '0x450a401a2c1b174775b6848acfe1faa45a44518e000200000000000000000d2a', // WBTC-STARV3/Volume-V5/StarSeeds_Protocol
      '0x3c4c55db89b1411d978a4dfb0fe563aaa91e9cac000200000000000000000d28', // 16oRETRO-84STARV3/StarSeeds_Protocol
      '0x63376dd1ace0b06117acff556a2ab515636568a0000100000000000000000d25', // stMATIC-SP-STARV3(-M)/StarSeeds_Protocol
      '0xf9cc17cb43c9e29916a12c986fda58a45c911abd000100000000000000000d24', // WMATIC-SP-STARV3(-M)/StarSeeds_Protocol
      '0x012b18da3f53aca8d36f7c2212445b29614ff1b5000100000000000000000d22', // oRETRO-SP-STARV3(-M)/StarSeeds_Protocol
      '0xcb78065d1593dde50a347d23b75595b5c26d34bc000100000000000000000d23', // WCASH-USDC-oRETRO-SP-mooCompoundPolygonUSDC-miMATIC-STARV3(-M)/StarSeeds_Protocol
      '0x1e087a0fcfbeb9dd3487728d3fefafb22a65b660000100000000000000000d26', // SP-wstETH-FBX-STARV3(-M)/StarSeeds_Protocol
      '0xa4da55ea14652a7db35c6584cd392e31aeb09141000100000000000000000d21', // wstETH-DAO-oRETRO-stMATIC-SP-STARV3(-M)-xtetuBAL/StarSeeds_Protocol
      '0x6312884d02cee5b797bef80389472413354518bb000200000000000000000d20', // 16WBTC-84STARV3-M/StarSeeds_Protocol
      '0xc76df676b10beabe8d730be74c950b030969cd82000200000000000000000d35', // 50QI-50WETH
      '0xe2eeb77b6f149fabcf8d64485ec14ad302d7e709000100000000000000000d1b', // WMATIC-WBTC-USDC-oRETRO-SP-WETH-STARV3(-M)/StarSeeds_Protocol
      '0xf655d9280ad6f3d564675e10cc3aee33e6bb898b000200000000000000000d36', // 80mooBalancer jEUR-PAR-20ICHI_Vault_LP
      '0xc38718d8ab36d6afb908cc99b67f728dfef18c1b000200000000000000000d37', // 1BAL-99USDT
      '0x29eaf134a98442044e3765fef60ca67552099ead000100000000000000000d39', // 16WMATIC-8oRETRO-52SP-16WETH-8miMATIC/V.G3/StarSeeds_Protocol
      '0xa7554e192c1915ccc02ce9ead2b9c1860ce11500000100000000000000000d3a', // 20wstETH-40DAO-20stMATIC-20SP/StarSeeds_Protocol
      '0x206a331f22c9fd4dd76980bcfb6ecc8c79ddf783000100000000000000000d3f', // 32DYST-26SP-16STARV3-26STARV3-M/V-3B/StarSeeds_Protocol
      '0x55dc13df97ed3f053f672baf56288c70c7f11c91000100000000000000000d3e', // 15wstETH-15WBTC-15stMATIC-10mooCompoundPolygonUSDC-10ICHI_Vault_LP-35STARV3-M/V-CG3/StarSeeds_Protocol
      '0xf15e1278b69bf3b0ce0761c010a620ccefc2ca33000100000000000000000d3d', // 35WBTC-20SP-15STARV3-30STARV3-M/V-E3/StarSeeds_Protocol
      '0x2bbf7a2296a848b5097a27bf45b91e5369b051cb000100000000000000000d3c', // 30SP-35WETH-15STARV3-20STARV3-M/V-E3/StarSeeds_Protocol
      '0xb99a2ca6bc3ee5f57a26e3945f3718aa67926354000100000000000000000d3b', // 20SP-40miMATIC-15STARV3-25STARV3-M/V-E3/StarSeeds_Protocol
      '0x3516b726d9b8fd0bc39b4e0a33f9d0bcb2a86f4a000100000000000000000d40', // 15wstETH-15WBTC-15PAXG-55VINU
      '0x41542ee4896c85d3255ba895c57bb892f926a51d000100000000000000000d43', // 32WMATIC-32WETH-36STARV3/V-C6/StarSeeds_Protocol
      '0xdfc570440745295ebfa489789daa96a5d18dfd0a000200000000000000000d42', // 50STARV3-50MASQ/V-C/StarSeeds_Protocol
      '0x5e6306d79b88a543885fc692864b9a090d6a80af000200000000000000000d41', // 50oRETRO-50SP/V-C/StarSeeds_Protocol
      '0xc71be57b68941ee0c7870b166bf6930fea0578e6000100000000000000000d44', // 30WBTC-30tBTC-40STARV3-M/V-EL/StarSeeds_Protocol
      '0xcd992a3d58e4dfa59135333c1dc018ab68c85e2f000100000000000000000d46', // 30WBTC-30USDC-40STARV3-M/V-C4B/StarSeeds_Protocol
      '0xd9a70c2f517262f88834373583eadb150b29199a000200000000000000000d45', // 40tBTC-60STARV3-M/V-CR4/StarSeeds_Protocol
      '0xa4fa3ccf6b98999e5b37f9fa788f40ef96104afe000200000000000000000d47', // 50WMATIC-50mooAuraPolyGyroscopestMATIC-MATIC/P-O/StarSeeds_Protocol
      '0x78796deb4eefb5c61dd5a55a3f72ec982594246f000100000000000000000d48', // MATIC-BTC-ETH-USDC-LINK-AAVE-MASQ-STARV3/StarSeeds_Protocol
      '0xff56b4ecca40f03a6e318eb8d00b5db306a92375000100000000000000000d49', // 15wstETH-12WBTC-8stMATIC-5PAXG-60VINU
      '0x9abb5ff9fbb1b5d2d2c6a65be5f2fdf9ac1d2a7b000100000000000000000d4a', // 15WMATIC-15WBTC-15USDC-15WETH-25STARV3-15STARV3-M/V-G7/StarSeeds_Protocol
      '0x97df6ba8a1ffc15ea410218212ca1de34f314748000100000000000000000d4b', // 22WMATIC-22WBTC-22WETH-34STARV3/V-G7.1/StarSeeds_Protocol
      '0xf64f6ab5cac83a464403a4f331a4fee73cd3fc36000100000000000000000d4e', // 33WBTC-33QI-33SP
      '0x37a4b3d215199971c171ebd5ba16f5fc82fb5c13000200000000000000000d4c', // 50WETH-50FRACTION
      '0xc1039c2e5ac873f4736057b20c7811d2a572fa78000100000000000000000d4f', // 12wstETH-15oRETRO-12stMATIC-37SP-12miMATIC-12liveRetro/V-G7.2/StarSeeds_Protocol
      '0x541a59c0019750aada67678daf4150ae5b0bde56000200000000000000000d50', // 50USDT-50MaticX
      '0xb7167bcfe2df6950ea32bdfc8adfa451026a3c62000200000000000000000d51', // 50WBTC-50LINK
      '0x97bfa798025505367266b1804b30a5dcd3f38bc3000200000000000000000d56', // 50LINK-50LDO
      '0x769109e27c8a5d9b12b445f1de56980744811f71000200000000000000000d55', // 95XEQ-5USDT
      '0xd559c09e48d9882bc2da98de3d885c21bd130676000200000000000000000d57', // 10stMATIC-90SP
      '0x439723303b075a0ee1969391513a2e071d9b5184000200000000000000000d5a', // 16wstETH-84SP/V-C6/StarSeeds_Protocol
      '0x22f220b66caa512e291f7b04fa64ac43ac363294000200000000000000000d59', // 16stMATIC-84SP/V-C6/StarSeeds_Protocol
      '0x7eb8a014e14d349fbca9374fb7592be4416b2ab5000200000000000000000d5c', // 20USDC-80STARV3-M/StarSeeds_Protocol
      '0x632d71dd6aba342da55915997c969d5089b28251000100000000000000000d5b', // 18WBTC-18USDC-64STARV3-M/V-C8/StarSeeds_Protocol
      '0xa4a03a57c89f39c66f2237c0643ba6eec36da5ec000200000000000000000d5d', // 90SP-10WETH/V-4.4B/StarSeeds_Protocol
      '0x62309056c759c36879cde93693e7903bf415e4bc000200000000000000000d5f', // 50WMATIC-50OLAS
      '0x30a73d5470f86dd1b6a0f970bc3f80e2b42e5e15000200000000000000000d60', // 12stMATIC-88SP/V-JLB/StarSeeds_Protocol
      '0x75f91277820f77b4811abcd4d04597f2f10fe7b1000200000000000000000d64', // 20WMATIC-80SP/StarSeeds_Protocol
      '0x6cfdf37a48c7531b1ba96bfa270644ff0f143900000200000000000000000d63', // 80SP-20WETH/StarSeeds_Protocol
      '0x54021d986e7517c8eddb6a133a859c80ab550f78000200000000000000000d6d', // 84STARV3-16APE/V-V4.4/StarSeeds_Protocol
      '0x59d6ad2d4b282167b63f23783c2c61b41c697045000100000000000000000d6e', // 12WMATIC-9RBW-55SP-12WETH-6miMATIC-6TEL/V-G4/StarSeeds_Protocol
      '0x8d4cef43e7ceeed6fbd532f8cbd38ded61aa1163000100000000000000000d6f', // 6wstETH-12oRETRO-6stMATIC-64SP-6miMATIC-6liveRetro/V-G5/StarSeeds_Protocol
      '0x4508547a807334b3a83f8da8262bbc3f230e3748000200000000000000000d70', // 50WMATIC-50DYST/StarSeeds_Protocol
      '0xe9376f78cfb41c5be528837de40ab70021a78b7f000200000000000000000d71', // 20DYST-80SP/StarSeeds_Protocol
      '0xf68e34eb2e1d46e222de3f651b8b190051b50eaf000100000000000000000d72', // 12WMATIC-12WBTC-20USDC-12RBW-12SP-12WETH-12STARV3-12STARV3-M/V-M8/StarSeeds_Protocol
      '0x0ba53b730efc9890493ae50195f692303d4e6b11000100000000000000000d73', // 20WMATIC-20USDC-20oRETRO-20RETRO-20liveRetro/StarSeeds_Protocol
      '0x513dde6bd1579f826e28a4354d42412ecc1c6b52000200000000000000000d79', // 2WETH-98JARVIS
      '0xbf04af559b2e934ebe8290fb41bc2c0dac2ebfb7000100000000000000000d75', // 25SP-25miMATIC-25STARV3-25STARV3-M/V-EV2/StarSeeds_Protocol
      '0xd7a8bd108ef57c9055e75832172c012de94e2be0000200000000000000000d74', // 20USDC-80STARV3/V-P1/StarSeeds_Protocol
      '0x1758128950b463de8820fe12bf3f49ea8024565c000100000000000000000d76', // 25WBTC-25SP-25STARV3-25STARV3-M/V-EV2/StarSeeds_Protocol
      '0x53fd82181b88f8d8ac299c449f9413320d1258a0000200000000000000000d6c', // 84STARV3-16MASQ/V-V4.4/StarSeeds_Protocol
      '0xaa9dcf8f1519a0fa767d3565072860e24cadcf5a000100000000000000000d6b', // 20WBTC-20USDC-60STARV3/V-S2/StarSeeds_Protocol
      '0x1f7f9a5fa7176512214665c3843d01e1452314a6000100000000000000000d69', // 20USDC-20WETH-60STARV3/V-S2/StarSeeds_Protocol
      '0x6c3a72d5f31fc824fd007421da8674145bb97eef000200000000000000000d67', // 16DYST-84SP/V-V4.4/StarSeeds_Protocol
      '0x4f5f802125b22598b8a63585222cf7373204313e000200000000000000000d66', // 84SP-16liveRetro/V-V4.4/StarSeeds_Protocol
      '0xedea3b2fe99994ee778283186a27a73bbbd26d69000100000000000000000d77', // 25SP-25WETH-25STARV3-25STARV3-M/V-EV2/StarSeeds_Protocol
      '0x61a3819458e4872f7c95a71b76d68c46a5b74a0c000200000000000000000d65', // 16oRETRO-84SP/V-V4.4/StarSeeds_Protocol
      '0x9dd7c9867c0078d8ad42b3366ffc6ba1ee89eaec000100000000000000000d78', // 20WMATIC-20USDC-20SP-20STARV3-20STARV3-M/V-EV3/StarSeeds_Protocol
      '0xcd04c6e8390c660b79e3fa1eced74dd8696a1cec000100000000000000000d7a', // 25RBW-25SP-25STARV3-25STARV3-M/V-EV2/StarSeeds_Protocol
      '0xc44129224d395d3f2dff7a2fd93d60af1c1a81ea000100000000000000000d7b', // 25SP-25STARV3-25TEL-25STARV3-M/V-EV2/StarSeeds_Protocol
      '0x769d6f13b4a8442f6bccbc6524f6651f2b2e578e000100000000000000000d7c', // 25LINK-25SP-25STARV3-25STARV3-M/V-EV2/StarSeeds_Protocol
      '0xbb270af43dd95e492eb23e5e927a8e6dd9b9184c000100000000000000000d7d', // 25UNIM-25SP-25STARV3-25STARV3-M/V-EV2/StarSeeds_Protocol
      '0x19d836c64388a8a3c732c007c4840e9e11eec2aa000200000000000000000d7f', // 20GRT-80STARV3/V-P1/StarSeeds_Protocol
      '0xa8c128cf5c08c42b38a0974f3e9a5735e065b37b000100000000000000000d68', // 20WMATIC-20USDC-60STARV3/V-S2/StarSeeds_Protocol
      '0xf6aedf6e057f5f816a14f69fb36e2e1b067b1115000100000000000000000d80',
      '0x035476819e3d074d8d734552be7738e055f773cc000200000000000000000d81', // JARVIS-ETH 8020 1%
      '0x9149d41d7948fec0d3abfd01e930884d824f12ee000200000000000000000d82', // JARVIS-ETH 9802 1%
      '0x518350d75d7c7b7c037f44fd1b21a91f4945ca75000200000000000000000d83', // 50USDC-50EMI
      '0xec8d0684418a3fa715a7ce01aa1794d8d29628ac000100000000000000000d86', // 20WBTC-20stMATIC-20SP-20STARV3-20STARV3-M/V-EV3/StarSeeds_Protocol
      '0x4df038673461f25d5de2ef609777cff4d2232140000100000000000000000d85', // 20wstETH-20SP-20miMATIC-20STARV3-20STARV3-M/V-EV3/StarSeeds_Protocol
      '0x71cd984fcda586ff58240d2ab480bdce8dda9088000200000000000000000d87', // 50USDT-50EMI
      '0x63683efe7839bb3c4e746bf5c4d1f5ee785a6a02000200000000000000000d89', // 20wstETH-80SP/V-B1/StarSeeds_Protocol
      '0x9fb6bbab51ef7f1715e683fc4d0acd305af9e38d000100000000000000000d88', // 20WBTC-20USDC-20SP-20STARV3-20STARV3-M/V-EV3/StarSeeds_Protocol
      '0x80b7e553bae1e69e1f8bb63cff5c3367c88c18a6000200000000000000000d8a', // 50wstETH-50CF
      '0xa0f31fb91d5b29c8a0f3eadb62ffd832fc03b2cb000200000000000000000d8c', // 20WETH-80STARV3/V-P1/StarSeeds_Protocol
      '0xf19f347166665bfb0fa954d9b0b8eb74dd3841b3000100000000000000000d8e', // 40SOL-10BAL-50GROSH
      '0xe1b94c3f2e892152a036dce7ce1429c9a2c0a4ad000200000000000000000d8f', // 50WMATIC-50stMATIC
      '0x2e03c1bcd3975db4965bd84fc00f700e0962f203000200000000000000000d91', // 20QI-80SP/V-C2/StarSeeds_Protocol
      '0x844ca8232b75dbbd70ba7414f072fb03096e0297000200000000000000000d90', // 80SP-20BIFI/V-C1/StarSeeds_Protocol
      '0xa18f0237a910a7e406434f5e69df6170af1f3452000200000000000000000d93', // 80STARV3-M-20MaticX/V-P1/StarSeeds_Protocol
      '0x5fd544ca5bc85d962356870f21aa3f6391d5ab33000200000000000000000d94', // 20miMATIC-80STARV3-M/V-P1/StarSeeds_Protocol
      '0xe5bb11a1fac1ec7962bfb687f10714e2fd0225ee000200000000000000000d96', // 50DFX-50USDT
      '0xc04a924213f8654239189d700759bd508c5fbd6d000200000000000000000d98', // 50UAHT-50WETH
      '0x3027c4be97505a871284c41eeeb6daeae8d83861000200000000000000000d97', // 50UAHT-50WBTC
      '0x0e263c7e02e00a3960ac9399aa16c7030d0488a9000200000000000000000d99', // 50SUSHI-50USDC
      '0xfceb87363c798af585d2463f63730ad475e0c208000200000000000000000d9a', // 50CRV-50USDT
      '0xcc1cd2c2e7bdc3b82d6754bce4c4cff8cf9a03c1000200000000000000000d9d', // aaveDE
      '0x65e4b8d153b5458610f0acfc56f41ad3c4909368000200000000000000000d9c', // balDE
      '0x5177f1422a80003a507d50bc1dd47cb065ddc0b2000200000000000000000da0', // 20WMATIC-80iBSTARV3/V-PO/StarSeeds_Protocol
      '0xff58f63dec700fb178b1f1f83b3b2f33e00b9e43000200000000000000000d9e', // wethDE
      '0x0e844bd17884cb0c6167f6ba561eb3d0534b29a2000100000000000000000d9f', // 20RBW-20UNIM-20SP-20STARV3-20STARV3-M/V-EV2/StarSeeds_Protocol
      '0x2c791387b56a7b1e47fe11903a2800fb6993ec00000200000000000000000da1', // 50USDT-50FACTR
      '0xe0818ec63e546726952ee3e11dd6506aee421d65000200000000000000000da4', // daiDE
      '0x6db090cc3b571d0fb365b8d0f1b8859a75554a59000200000000000000000da2', // crvDE
      '0xa39cefa0b9eefb52aab6bf4af2d4e656afe481cf000200000000000000000da3', // btcDE
      '0x223e7d4e9a4fd1ca689bfb87cc5542bcfdc4bdf5000200000000000000000da5', // 50miMATIC-50MaticX
      '0x1c1934ef0511a95e6fd6a04b6549472af22cda80000100000000000000000dab', // 20wstETH-20SP-20STARV3-20STARV3-M-20MaticX/V-EG2.1/StarSeeds_Protocol
      '0xaade36d880b5a528ed6b9ebd348f7003ceaed6c0000100000000000000000daa', // 5WMATIC-5WBTC-5tBTC-5USDC-15SP-5WETH-60STARV3-M/V-EG17/StarSeeds_Protocol
      '0xa9347bf6e6841a57ab3ab25e6f43e9434c9d293a000100000000000000000da9', // 8wstETH-8WMATIC-8WETH-8mooAuraPolyGyroscopestMATIC-MATIC-8ICHI_Vault_LP-52STARV3-8MaticX/V-EG16/StarSeeds_Protocol
      '0x9973d5a0487c8fa93c2dd8beba076c635145b56e000100000000000000000da8', // 10WMATIC-10WBTC-10USDC-15SP-10WETH-30STARV3-15STARV3-M
      '0xace19bba448c0cb21fa00350f3cba7e4f1429042000100000000000000000da6', // 6wstETH-9WMATIC-6stMATIC-52SP-9WETH-6ICHI_Vault_LP-6STARV3-6MaticX/V-M5/StarSeeds_Protocol
      '0x13eb17b5c3d9f073159c2b09447030133a18a538000200000000000000000dac', // 20WBTC-80STARV3/V-PR/StarSeeds_Protocol
      '0xcb14b8be85093205d353a4cb9c712879f2e325a7000200000000000000000daf', // 50USDC-50USDT
      '0x6ad3997cb8820e3af5a7b9dd71401d61b7b817b7000200000000000000000dad', // 50WBTC-50USDT
      '0x9662eb5aab72de20113c97a91364a6285008460e000200000000000000000dae', // 20WBTC-80STARV3-M/V-P1/StarSeeds_Protocol
      '0x15219e7b03c7e67a79b8fd6b427a3f008b4837c8000100000000000000000db5', // 10WMATIC-20WBTC-10USDC-60STARV3-M/V-C8/StarSeeds_Protocol
      '0x91efb2e3d2c3c936ac1a83fa25400188f7f2aeef000100000000000000000db4', // 15WMATIC-15WBTC-20SP-30STARV3-20STARV3-M/V-EV3.1/StarSeeds_Protocol
      '0x4fb042c8ef4484f5d16b4450a0a5fae08334dc66000100000000000000000db3', // 15WMATIC-20SP-15WETH-30STARV3-20STARV3-M/V-EV3.1/StarSeeds_Protocol
      '0xa8d0f5db2c67253b75c1b348a9767184e21930ec000100000000000000000db2', // 15WMATIC-15oRETRO-20SP-30STARV3-20STARV3-M/V-EV3.1/StarSeeds_Protocol
      '0xf4a88e05705ed34eb8f377db8a6dd56a1c77c33b000200000000000000000db6', // 30USDC-70USDT
      '0x8bea6aa483a787e6c5b994db1811f458637a0071000100000000000000000db7', // 25oRETRO-25SP-25STARV3-25STARV3-M
      '0x1ed031beb9eea8f2138fc60e0ed521d5c7771be3000100000000000000000db8', // 5wstETH-15WMATIC-5oRETRO-5stMATIC-60SP-10WETH/V-SCM28/StarSeeds_Protocol
      '0x95c89c796ee66c9a2e15c1ae6ec42f101d2d44dc000100000000000000000db9', // 10WMATIC-30oRETRO-60SP/V-VGR39/StarSeeds_Protocol
      '0x5ada63b2112f4299199ee9cc2bf632dfe37c2058000200000000000000000dba', // 20DE-80STARV3/V-VR/StarSeeds_Protocol
      '0x3bd8a254163f8328efcc4f8c36da566753462433000200000000000000000dc1', // 80TEL-20USDC
      '0x19127998126b6c7d32d596ee15d32719b1789c8e000200000000000000000dbf', // 80TEL-20WMATIC
      '0xe1e09ce7aac2740846d9b6d9d56f588c65314ecb000200000000000000000dbe', // 80TEL-20WBTC
      '0xca6efa5704f1ae445e0ee24d9c3ddde34c5be1c2000200000000000000000dbd', // 80TEL-20WETH
      '0xa0ef0f4f24662050b7e504d1015a8cd7d2a8a51f000200000000000000000dbc', // 20BAL-80TEL
      '0x061dffa319c0ff4a97eaaccdb723cf4f7513ca02000100000000000000000dc0', // 6wstETH-16WMATIC-8WBTC-8oRETRO-6stMATIC-8SP-8WETH-40STARV3/V-R44/StarSeeds_Protocol
      '0xc7c09bd12aa00099d45e350e4b2d9b082a374aac000100000000000000000dc2', // 10WMATIC-10WBTC-10USDC-10WETH-40STARV3-20STARV3-M/V-R45/StarSeeds_Protocol
      '0xeb51266192adcc8eadab4357ce4f8fc97b5ffc64000100000000000000000dc7', // 40SP-40STARV3-20liveRetro/V-VR6/StarSeeds_Protocol
      '0xd819064c12ec65b7dffacbb4986059272e5e527e000200000000000000000dc5', // 34oRETRO-66SP/V-VR19/StarSeeds_Protocol
      '0x173f7cf0b29b1178b64aa9c0ebdcec5955c61f6b000100000000000000000dc6', // 20oRETRO-20SP-50STARV3-10STARV3-M/V-EV3.4/StarSeeds_Protocol
      '0xa4c11238cbdab9dd18e6fb209e62741c3cdb1d89000200000000000000000dc8', // 66SP-34BIFI/V-VMR/StarSeeds_Protocol
      '0xbb7c1c329595c7b5fcd51ec39527d27a3aed3869000100000000000000000dc9', // 12WMATIC-6WBTC-16oRETRO-4stMATIC-6LINK-42SP-6WETH-8STARV3/V-VM41/StarSeeds_Protocol
      '0xa9ac2aa4fac0f42445a68671e3cc49fadcc35b7d000100000000000000000dcb', // 33USDC-33WETH-33XSGD
      '0xfd2b6b09fa1f1add782bda6be7f41ddf7a38436a000100000000000000000dd7', // 4WMATIC-4USDC-12MATIC-S-8SP-16SSG-16SSC-32STARV3-8STARV3-M/V-FER/StarSeeds_Protocol
      '0xe12b6551958c832dc5c268c9c5ca8f0a05f4a033000200000000000000000dd5', // 50MATIC-S-50STARV3/V-CR/StarSeeds_Protocol
      '0x7b66c0f2bd1c66028ad3916b043cf79686d6e898000200000000000000000dd4', // 80MATIC-S-20WETH/V-VR/StarSeeds_Protocol
      '0x35404ffdbc187cf7e88040fbd09b3c75ec142e16000200000000000000000dd3', // 20USDC-80MATIC-S/V-VR/StarSeeds_Protocol
      '0x71d13c58e880f0c44dd6dd2c8defc53848fae258000200000000000000000dd2', // 20stMATIC-80MATIC-S/V-VR/StarSeeds_Protocol
      '0x11fd6ceb8ee0571b24222ac725dadae0b1eb74eb000200000000000000000dd1', // MATIC-S/MATIC
      '0x97d5f448cf3846619a34595c1664f6bbf74a76fe000100000000000000000dd0', // 20WMATIC-10USDC-40MATIC-S-5SP-20STARV3-5STARV3-M/V-EV4/StarSeeds_Protocol
      '0x9497bd5657a745c531f1a0da69d218dc5fda4b61000200000000000000000dcf', // 34ETHBULL3X-66SP/V-VR4/StarSeeds_Protocol
      '0xba67ccbcba84932950f49672c689cacfdd475fff000100000000000000000dcd', // 20WMATIC-15USDC-5yvWMATIC-A-15stMATIC-20MATIC-S-20STARV3-5MaticX/V-PO/StarSeeds_Protocol
      '0x6a375e938cee961a76d7ba0ed713055c3bb8d469000100000000000000000dcc', // 20ETHBEAR2X-20USDC-20ETHBULL3X-20SP-20WETH/V-PO/StarSeeds_Protocol
      '0xcfaa8bbc421b4c8ac86b3654a22b86c10b4757b9000100000000000000000dd9', // 33WMATIC-33WBTC-33USDC
      '0x18a9a941c65ffa50c2cc56e60d9c3e553b52a4ad000100000000000000000dd8', // 10WMATIC-5WBTC-5USDC-5stMATIC-40MATIC-S-10SP-5WETH-20STARV3/V-GR47/StarSeeds_Protocol
      '0x6a859910bb60a6ef6c5b8886ff39e5e5311f6d8e000200000000000000000ddf', // 20WMATIC-80SSC/V-VR/StarSeeds_Protocol
      '0x7d31147efef097bbf41767e5591ae72a4463882b000100000000000000000ddd', // 10WBTC-20MATIC-S-10WETH-40SSC-20STARV3/V-GR50/StarSeeds_Protocol
      '0xdd35f8d90ca82a44eedcd2ef4fd06aaaccd94eed000200000000000000000ddc', // 34SP-66SSC/V-VR3/StarSeeds_Protocol
      '0xaa68bb84ee4ebddac7c966aaa298d0428bd18047000100000000000000000ddb', // 10WMATIC-10MATIC-S-30SP-40SSC-10STARV3/V-EV4.1/StarSeeds_Protocol
      '0xea62af18e468eafb9b8d1b479eeec22f1e867347000100000000000000000dda', // 20WMATIC-20DAO-20SP-20STARV3-20STARV3-M/StarSeeds_Protocol
      '0x37860d295058cc54ebd97f3f0cd7ee4a593bfc2e000100000000000000000de9', // 20WBTC-20MATIC-S-60STARV3/V-GR17/StarSeeds_Protocol
      '0x12e0ad411080bf860aea108dd78ba3288fed2ddb000100000000000000000de8', // 20MATIC-S-20WETH-60STARV3/V-GR17/StarSeeds_Protocol
      '0x48f4ab52367badf2d36ddb6c05f6d148ce3a4c61000100000000000000000de7', // 15WBTC-25MATIC-S-60SSC/V-GR16/StarSeeds_Protocol
      '0xae8a4f72eb54c80dc484178bcae5bbcb7a3b44e1000200000000000000000de6', // 66SSC-34STARV3-M/V-GR/StarSeeds_Protocol
      '0x9ba442091c6bdb491643dded1108223b54c5edde000100000000000000000de5', // 25MATIC-S-15WETH-60SSC/V-GR16/StarSeeds_Protocol
      '0x70e5b0c93dec8cf47e6b22ab8a65417ec538813a000200000000000000000de4', // 40MATIC-S-60SSC/V-VR/StarSeeds_Protocol
      '0xa01ca371a70d8d89c017c85ce855f36e59b30810000200000000000000000de3', // 60SSC-40STARV3/V-VR/StarSeeds_Protocol
      '0x2b9b87240a36197c6b119581e81db21adcea026d000100000000000000000de2', // 20MATIC-S-20WETH-40SSC-20STARV3/V-GR15/StarSeeds_Protocol
      '0xea645e6ce8dbdebb78f512cb932bac9a9d89ef82000100000000000000000de1', // 8WMATIC-8WBTC-8USDC-8MATIC-S-8SP-8WETH-40SSC-12STARV3/V-GR14/StarSeeds_Protocol
      '0xc191cf918143bc615a58238e8c57a86a51284c8d000200000000000000000de0', // 50wstETH-50WMATIC
      '0x2a2eab56adbdd3cf27e1a8da5b16791f8f93cb53000100000000000000000dea', // 25AVAX-25LINK-25USDT-25AAVE
      '0xb6d505aa298fc07ce72f02d6190c0b2344660b06000200000000000000000deb', // 5WMATIC-95BAL
      '0x0df5dabfccbf5b077ce71ea3c8fa461c81fbc45e000200000000000000000def', // 50ETH-S-50STARV3/V-CR/StarSeeds_Protocol
      '0xc32fcf4e4e0e3dfd69763afbccb4d36e4d276246000200000000000000000dee', // 50BTC-S-50STARV3/V-CR/StarSeeds_Protocol
      '0x1f845a3d1c4147cdc78de8171c73d5ba71076193000100000000000000000ded', // 20WMATIC-20BTC-S-60STARV3/StarSeeds_Protocol
      '0xe4c260646d18746cd9314da7e67ca733db923a3c000100000000000000000df1', // 20WMATIC-20ETH-S-60STARV3/StarSeeds_Protocol
      '0x671cdaf4b49e0707535ed67adc4bd259a7fab6f6000200000000000000000df0', // ETH_Yield_Index/StarSeeds_Protocol
      '0x1d91cfb5c4a98d1d9835201b3e7dd9f56ee50512000200000000000000000dec', // BTC_Yield_Index-WBTC/StarSeeds_Protocol
      '0xcd66584758106fb3ea5576980c5c41a25aca0ead000100000000000000000df2', // 25ETH-S-25MATIC-S-25BTC-S-25STARV3/StarSeeds_Protocol
      '0x75816b25b89bfd4c9aa7571b1f5526ad6a901181000200000000000000000df4', // TBYDE
      '0xf82b621431145639db353b7ce17a434c64467969000200000000000000000df3', // 80LINK-20TEL
      '0x0c4165257ec93ffed385531dded9d725a1648de8000200000000000000000df5', // 20WETH-80SSC/V-VR/StarSeeds_Protocol
      '0x65d6e1341ac3bf6d11bea4f5ca20a910fac349ed000100000000000000000df8', // 6WMATIC-28SP-12WETH-30SSC-24STARV3/StarSeeds_Protocol
      '0x921b0bf476cfad7f2e3a5a31892de84a1b54322a000100000000000000000df6', // 5WMATIC-5WBTC-30SP-5WETH-40SSC-15STARV3/StarSeeds_Protocol
      '0x02d71553597f2ed2a371e92fb6065f859bed9dcc000100000000000000000df7', // 10WMATIC-5oRETRO-25SP-15WETH-30SSC-15STARV3/V-VR5/StarSeeds_Protocol
      '0x16e009212e7f1465d8b5085f68201be70082454e000100000000000000000df9', // 28SP-16WETH-32SSC-24STARV3/StarSeeds_Protocol
      '0x16761d0e33f37c9154571a0cc408d711ce4d2ea0000200000000000000000dfa', // 50SSG-50STARV3/StarSeeds_Protocol
      '0x711604535a526b22da674a04910b7d62d4c80934000100000000000000000dfb', // 16WMATIC-28SP-32SSC-24STARV3/StarSeeds_Protocol
      '0xcdc143b295b4b4f2f8052b1ae57b68bcf8171b99000100000000000000000dfc', // 12WMATIC-12WBTC-12ETH-S-12MATIC-S-12WETH-12BTC-S-28STARV3/StarSeeds_Protocol
      '0x6b99a931a02f4a1da5d45e10f30589157f9cb48f000100000000000000000e0d', // 10WMATIC-45GHST-45SP/StarSeeds_Protocol
      '0xe3aae5748bac1fdf68108070a506fcce46e1e337000100000000000000000e0f', // 10WMATIC-45SP-45DFX/StarSeeds_Protocol
      '0xacbfbf441cc804fdf5a6421265be042fd5e49403000100000000000000000e0e', // 6WMATIC-47SP-47MASQ/StarSeeds_Protocol
      '0x5855e62f286563e84c83c91357b9ecee3852b71d000100000000000000000e10', // 8WMATIC-10oRETRO-42SP-35RETRO-5liveRetro/StarSeeds_Protocol
      '0x2607bed0f8022eca127368710d1d4a1582677129000100000000000000000e0c', // 45GHST-10WETH-45STARV3/StarSeeds_Protocol
      '0x682d2c37ece8f62b112fcd94e42419fa606bbcc0000100000000000000000e0b', // 8WMATIC-46TETU-46SP/StarSeeds_Protocol
      '0x97957cf303f7e6d7fc56959edc0b2eab27d3eee5000100000000000000000e05', // 10WETH-45STARV3-45FBX/StarSeeds_Protocol
      '0x34c0cf546e1a2e9321146c4f01cb675617dc09bc000100000000000000000e0a', // 6WMATIC-6WBTC-44BAL-44STARV3-M/StarSeeds_Protocol
      '0xf8e58924060c1c33978b96401292086f3acfb30c000100000000000000000e09', // 10WMATIC-45SP-45BAL/StarSeeds_Protocol
      '0x13d931d85402a88ccf5fef939a4e208224fcb028000100000000000000000e08', // 10WETH-45BAL-45STARV3/StarSeeds_Protocol
      '0x68786b11847300e8ba607af79b62f20b2585f20d000100000000000000000e04', // 5ETH-S-10MATIC-S-25SP-10WETH-40SSG-10STARV3/StarSeeds_Protocol
      '0xa5787be3be104b739184ded5534702d47e2203e7000200000000000000000e03', // 20WMATIC-80SSG/StarSeeds_Protocol
      '0x71f5a6139f4b340bbf32ee7577c93e19b5ed379a000100000000000000000e06', // 10WMATIC-45SP-45FBX/StarSeeds_Protocol
      '0x76d0dda880d4bd0fc771356d6f29104cb740aeaf000200000000000000000e02', // 20SP-80SSG/StarSeeds_Protocol
      '0xc17b8c60d67cc7e550076d9c0708b68552f04591000100000000000000000e01', // 4ETH-S-4MATIC-S-24SP-8WETH-44SSG-4BTC-S-12STARV3/StarSeeds_Protocol
      '0xca6fbe9a344868a5e7c8e48b239bd43018a34c3d000100000000000000000e00', // 6ETH-S-6MATIC-S-16SP-66SSG-6BTC-S/StarSeeds_Protocol
      '0xfbb90cc3dcfd2929342863974bc35f91adea115f000200000000000000000dfe', // 50BTC-S-50STARV3-M/StarSeeds_Protocol
      '0x6f698b3d51c40430d551aa736181cb421c809cd4000100000000000000000dff', // 8WMATIC-4ETH-S-4MATIC-S-24SP-44SSG-4BTC-S-12STARV3/StarSeeds_Protocol
      '0xb42660a24fbbb024deecc1c29a037d1c60f1c495000200000000000000000dfd', // 50ETH-S-50SP/StarSeeds_Protocol
      '0xdc8ac5b19a8ea42deecef2b2ea2376fa8d87be83000100000000000000000e14', // 17WMATIC-17WBTC-17USDC-17WETH-17DAI-17USDT
      '0x45b8fa37cf7b68330a754e30a248d328e7853802000100000000000000000e18', // 20WBTC-20SSG-20BTC-S-20STARV3-20STARV3-M/StarSeeds_Protocol
      '0x8135a990806dba0825984bff925b0dbe6425ac27000100000000000000000e19', // 8WMATIC-16WBTC-8SP-16BTC-S-20STARV3-32STARV3-M/StarSeeds_Protocol
      '0xf04b01d6f8ca6178464457198e2df610f45c4d50000100000000000000000e1a', // 10WMATIC-60SSG-30SSC/StarSeeds_Protocol
      '0xf9b956ffa8ca1c850e9a63eec4305a8d75f44fc9000100000000000000000e17', // 20WMATIC-20MATIC-S-20SP-20SSG-20STARV3/StarSeeds_Protocol
      '0xf57c2f90a769233cd4d77cb1ab2c2ba836e3d614000100000000000000000e16', // 20ETH-S-20SP-20WETH-20SSG-20STARV3/StarSeeds_Protocol
      '0x665ebbf1728f51c85aef837c191f4ab202a1da6c000100000000000000000e1c', // 16ETH-S-32SP-16WETH-36STARV3/StarSeeds_Protocol
      '0x033bde353d7d3ea611e964acf0de445af8ccb665000100000000000000000e26', // 6ETH-S-6MATIC-S-18SP-6USD-S-6BTC-S-40STAR-L-18STARV3-M/StarSeeds_Protocol
      '0x7120e2d47ca5f912d7c125d5c10b35afca93e849000100000000000000000e25', // 20SP-12WETH-48STAR-L-20STARV3-M/StarSeeds_Protocol
      '0x38f086ecd7194c89470e6e03ac9608d2ed2101a8000100000000000000000e24', // 12WBTC-20SP-48STAR-L-20STARV3-M/StarSeeds_Protocol
      '0xaee816029d12abca57636fb299e7262a1f2c0ba8000100000000000000000e23', // 12WMATIC-20SP-48STAR-L-20STARV3-M/StarSeeds_Protocol
      '0x2374355a0aaccf4fcceed7307799b3d4610e230b000200000000000000000e22', // 40SSG-60STAR-L/StarSeeds_Protocol
      '0x59966c6e67e749337ebdd84b69c4541dff46707e000200000000000000000e21', // 20USDC-80USD-S/StarSeeds_Protocol
      '0xcd46cae6479bd64cab28b9225fc3b2461493f91a000200000000000000000e20', // 40MATIC-S-60SSG/StarSeeds_Protocol
      '0x6a69f0d0cc1bb7dc94d64bc26975a91e3deb8eb0000100000000000000000e1f', // 3WMATIC-3WBTC-20SP-4WETH-40SSG-10STARV3-20STARV3-M/StarSeeds_Protocol
      '0x54ef2bc9019e88c0e30ee40e73ea6fff1767bf8c000100000000000000000e1e', // 6WMATIC-12ETH-S-6MATIC-S-24SP-12WETH-6BTC-S-22STARV3-12STARV3-M/StarSeeds_Protocol
      '0xfea8f0f9bfb402a761c4d3a899f22ed9aa8c33c9000100000000000000000e1d', // 6WMATIC-9ETH-S-9MATIC-S-36SP-3WETH-37STARV3/StarSeeds_Protocol
      '0x9a86a281540b48fe8e61b16b9e6dca1f42113f8e000200000000000000000e27', // 50STARV3-50STAR-L/StarSeeds_Protocol
      '0xd2f530efe8c8c3d98eb11ceeab22bae48ffd8595000100000000000000000e33', // 6WMATIC-18ETH-S-18MATIC-S-18BTC-S-40STAR-L/StarSeeds_Protocol
      '0xf2b12fe65b7dbf32bbb6f61a2185d9d4571709ae000100000000000000000e32', // 4WMATIC-2WETH-28SSG-10SSC-6STARV3-50STAR-L/StarSeeds_Protocol
      '0x09eef1d077caded7e507526cfa8ca8d8692504fb000100000000000000000e31', // 4WMATIC-28SP-8STARV3-60STAR-L/StarSeeds_Protocol
      '0x4240702329478516149b2eff78445e60e87aa53b000100000000000000000e2d', // 15ETH-S-15MATIC-S-35SP-35STARV3/StarSeeds_Protocol
      '0xb21a2fbc34671434646307263e15e15783030629000100000000000000000e30', // 6WMATIC-12SP-24SSG-8STARV3-38STAR-L-12STARV3-M/StarSeeds_Protocol
      '0x258d82b1b87b8b7b383b8c040768f1b7df473889000100000000000000000e2f', // 6WMATIC-3WBTC-22SP-3WETH-12STARV3-32STAR-L-22STARV3-M/StarSeeds_Protocol
      '0x2bd3a592711be60332d00fa4c21cec86e45f53ad000100000000000000000e2e', // 6WMATIC-4WBTC-24SP-4WETH-10SSG-12STARV3-16STAR-L-24STARV3-M/StarSeeds_Protocol
      '0x21c35be1caf1c0e9ca4dcb3956b4a09db788e8a7000100000000000000000e2c', // 8ETH-S-8MATIC-S-20SP-8BTC-S-36STAR-L-20STARV3-M/StarSeeds_Protocol
      '0x5b79c5f195aba417ebaf811ac64b276369477653000100000000000000000e2b', // 10ETH-S-10MATIC-S-30STARV3-50STAR-L/StarSeeds_Protocol
      '0xf2588ec06f915c58b65115fe0ba65fab0d743fb8000100000000000000000e2a', // 6WMATIC-6USDC-12MATIC-S-20SP-12USD-S-24STARV3-20STARV3-M/StarSeeds_Protocol
      '0x47cdb07cc4f0078f9122ebb77b565dfebf26668b000100000000000000000e29', // 8WMATIC-10ETH-S-10MATIC-S-16SP-10USD-S-10BTC-S-20STARV3-16STARV3-M/StarSeeds_Protocol
      '0xd1c9aabd7695309cf69f0fb34163b5340d010d68000100000000000000000e28', // 8WMATIC-8ETH-S-8MATIC-S-32SP-8WETH-36STAR-L/StarSeeds_Protocol
      '0xb75795998cee74e8b1dc33d1399f170cc02fc12e000100000000000000000e37', // CSC-4
      '0x343faca5cdd6d203ed616d93a899d282d287676b000100000000000000000e35', // 4WMATIC-16SP-26SSG-6SSC-8STARV3-40STAR-L/StarSeeds_Protocol
      '0x6cdf112502e809017e0ef2f41a004495e488a55f000100000000000000000e34', // 4WMATIC-36SP-2WETH-8STARV3-50STAR-L/StarSeeds_Protocol
      '0xa11cc998c83547fa007d8b4a93a098cc4af77684000100000000000000000e38', // 33USDC-33WETH-33XSGD
      '0x6944e4ef181169a12a980d3e8a81f0e6b7e142ad000200000000000000000e39', // 80SHN-20DAI
      '0xaafafaef775ebadd68341ba8d3cc912df8386896000100000000000000000e3a', // 10wstETH-20stMATIC-70SP/StarSeeds_Protocol
      '0xa416103c7265b5b817bf5f2ca51c597217b48f5f000200000000000000000e3b', // 50WBTC-50MaticX
      '0x8b2f8d4ececfd114d201e152187f52836f420f66000100000000000000000e3c', // 10WMATIC-5WBTC-5USDC-25SP-5WETH-30STARV3-20STARV3-M/StarSeeds_Protocol
      '0xc7c800090356429d1da0be55dba7e60a633409e8000100000000000000000e3d', // 20WMATIC-25SP-35STARV3-20STARV3-M/StarSeeds_Protocol
      '0x6646b0f3ceceb8e11cd2270801a2e467793d88ad000100000000000000000e3e', // 20WMATIC-30SSG-25STARV3-25STAR-L/StarSeeds_Protocol
      '0x58e18d874668a2387ef982548aead4e907f49428000100000000000000000e42', // NEX/BTC/ETH/rETH
      '0x74da9cfaef6c12e3433ef9576fc153ed8a194383000200000000000000000e43', // 50WMATIC-50USDC
      '0xdc33bb959ead63b555047a1412ef6f389b2230c8000200000000000000000e45', // 50USDC-50GHST
      '0xa3c724d6b57643984c1e08a2ab82d2b0b308d327000200000000000000000e46', // 50WMATIC-50USDC
      '0xd0201ed56c08d1ade14a2587b55cf39208c95c4c000100000000000000000e47', // 25WBTC-25USDC-25WETH-25NEX
      '0xf5a17027a2332df9510f8bd03b7a0ee1529479f3000100000000000000000e4b', // 9WMATIC-18WBTC-9USDC-9ETH-S-9MATIC-S-9USD-S-18BTC-S-19STARV3/StarSeeds_Protocol
      '0x9c559661cff20d73fa435f6040c2c81b748f1b9f000100000000000000000e4a', // MATIC-USDC-BTC-ETH/S-Token-Superpool
      '0x8c2335705ce02be9ebfba503187eb9640bfd29a1000100000000000000000e4c', // 9WMATIC-9USDC-18ETH-S-9MATIC-S-9USD-S-18WETH-9BTC-S-19STARV3/StarSeeds_Protocol
      '0x8ede76c41a387fd5a4359d74230c3b0c0cb73b26000100000000000000000e4d', // 33GHST-33stMATIC-33LDO
      '0xbb6afaff926aa25f9edb21c7a90bc4b53a33968c000100000000000000000e4e', // 30DFX-30GHST-40GROSH
      '0x34689e3c2d4920e217a50836f8f7a3fa9cff8d27000100000000000000000e4f', // 16WBTC-16ETH-S-16MATIC-S-16USD-S-16BTC-S-20STARV3/StarSeeds_Protocol
      '0x2f67bae2f1b84dec6f8a25425470a4a77082574a000100000000000000000e50', // 20WMATIC-15ETH-S-15MATIC-S-15USD-S-15BTC-S-20STARV3/StarSeeds_Protocol
      '0x8b11b5ef87538a6700c7fbba092bfcca234452ec000100000000000000000e51', // 20WMATIC-20WBTC-20USDC-20WETH-20NEX
      '0x40e57c0b2b674c8fbe5ba9e1e3bafce9f16f94be000200000000000000000e52', // 80AURA-20WETH
      '0xe3cabafd5a6add5f63ab00df175e951af7f9835e000200000000000000000e54', // maticDE
      '0x6248761a5617aef3ed46bf9bede38b53e9f91ebe000200000000000000000e53', // 50RBW-50USDT
      '0xd794289763b54a04d5fbbc8fd5710c618212eb84000200000000000000000e58', // 50USDC-50USDT
      '0x418681127fb0c4fb5ee01202fbe4ab8c8f4eb84a000100000000000000000e59', // 33TXAG-33WETH-33TXAU
      '0x6e62aa2e6548152267d9d08ceeb71677af2de0ce000200000000000000000e5a', // 50RBW-50WETH
      '0x8fca769e97af63d98390728e19eff01fd072acf9000200000000000000000e5b', // LP-EURS-USDC
      '0x446f7c6e2f44df67ac9d85baa0bef7fbcd26fff3000100000000000000000e5d', // 8USDC-16ETH-S-16MATIC-S-16USD-S-16BTC-S-28STARV3/StarSeeds_Protocol
      '0x53a3c44ea1cff14c2671a39c629591277af20cb3000100000000000000000e5e', // 8WBTC-16ETH-S-16MATIC-S-16USD-S-16BTC-S-28STARV3/StarSeeds_Protocol
      '0xd9a173a234c971474893c7356c517d77499e3d76000200000000000000000e5f', // 50WBTC-50USDC
      '0x4109b8d28486a4331c5bc1e1a9d96690630a98c1000200000000000000000e60', // 50WMATIC-50DATA
      '0x99596b4595d4343ef388a706b214ad8ac05e5436000100000000000000000e61', // 25WMATIC-25USDC-25WETH-25DAI
      '0x6a2e50e02aa0e37473f03d2243fa59af61985b9f000100000000000000000e62', // 15WMATIC-15MATIC-S-15SP-10USD-S-30STARV3-15STARV3-M/StarSeeds_Protocol
      '0x9288c1d61fdbc03e3ec95d78c9f4851946c82ec1000100000000000000000e63', // 14USDC-7ETH-S-7MATIC-S-14SP-14USD-S-7BTC-S-23STARV3-14STARV3-M/StarSeeds_Protocol
      '0x4deb1cafcf25265045d1d7e7e5e75450d012a95e000100000000000000000e64', // 16WBTC-8MATIC-S-16SP-8USD-S-16BTC-S-20STARV3-16STARV3-M/StarSeeds_Protocol
      '0x5092977abbe36232bf48a9c5f053bac47848ea63000200000000000000000e66', // 25WMATIC-75USDC
      '0x038ea3611fe46d441680ee72134d60e68f90599e000200000000000000000e6c', // 50STARV4-50STARV3/StarSeeds_Protocol
      '0xb4fbbe7caf8c168db44f43f793f5cffe452a6686000200000000000000000e6b', // 80STARV4-20WETH/StarSeeds_Protocol
      '0xfc3cb74efae04e0f85f161a3110f326a1bbdbf4f000200000000000000000e6a', // 80STARV4-20USDT/StarSeeds_Protocol
      '0x261b84933e803867f50a9f06dfe9a391955b1bad000200000000000000000e69', // 20USDC.E-80STARV4/StarSeeds_Protocol
      '0x6830f35dfbf8d93f53528c109d593572dd2982ef000200000000000000000e68', // 20GHST-80STARV4/StarSeeds_Protocol
      '0x44a0f5e2b99e83fee14719259ba8f0035e93f9da000200000000000000000e67', // 20WMATIC-80STARV4/StarSeeds_Protocol
      '0x61b58ad4eb2e278c1f35f77ef4ed44c71ee16ad8000200000000000000000e72', // 80STARV4-20SP
      '0x65d5cfd61cf474674eaa94e8f6a53851dd071b2a000200000000000000000e71', // 50STARV4-50SP
      '0x5f04bf9cae499bb971911f3af385c469398739ba000200000000000000000e6e', // 20WBTC-80STARV4/StarSeeds_Protocol
      '0xf1ac085911e5bccbdcfc4094251c346d58d97741000200000000000000000e70', // 50STARV4-50MATIC-S
      '0xe3880fbbd06c2432e4ea752b5a3701f9f0a71e45000200000000000000000e79', // 34SP-66STARV3
      '0xb4e75a4dd4e4566984cdd93dbac3754fe55cbd17000200000000000000000e78', // 20SP-80STARV3
      '0xd87166bda6c20cabdc1dc149ad1273f765247e15000100000000000000000e7a', // 10WMATIC-40STARV4-20SP-10WETH-20STARV3
      '0xfa9fdbf9bfab4dacaf93dc1219782c4b8b5ab47a000100000000000000000e7b', // 8WMATIC-6WBTC-8USDC-8GHST-24STARV4-20MATIC-S-20SP-6WETH/StarSeeds_Protocol
      '0x8659fdc3fe6e514d012fab063e8528778f364a75000100000000000000000e7c', // 8WMATIC-6WBTC-6USDC-6GHST-24STARV4-20MATIC-S-24SP-6WETH/StarSeeds_Protocol
      '0x21a5e99a438ff4044327581ed8c11df3c36b8955000100000000000000000e7d', // 5WMATIC-35STARV4-25MATIC-S-10SP-25USD-S/StarSeeds_Protocol
      '0xbdd21c863b7b2ed65550c68dbeeedf1be534eb3d000100000000000000000e7e', // 12WMATIC-8USDC-6GHST-24STARV4-16MATIC-S-12SP-16USD-S-6WETH/StarSeeds_Protocol
      '0x5eb79ae444ff5c52b56b2a6875d516b2bf866c17000100000000000000000e82', // 10WMATIC-40STARV4-20MATIC-S-10SP-20USD-S/StarSeeds_Protocol
      '0x7990f5939b4b07948b902e7e92376aa5ac1327ed000100000000000000000e81', // 36STARV4-20MATIC-S-12SP-20USD-S-12WETH/StarSeeds_Protocol
      '0x2ddcd41d2aa1ca8586aaa46737d3ee2729941c87000100000000000000000e83', // 10WBTC-40STARV4-15MATIC-S-15USD-S-20STARV3-M/StarSeeds_Protocol
      '0xa09b32dac1b669603074120077ea19b4ecac5ca2000100000000000000000e80', // 8GHST-28STARV4-24MATIC-S-16SP-24USD-S/StarSeeds_Protocol
      '0xaab20a3d6f587b86d48bd78cd79a4080bb2fb1c1000100000000000000000e7f', // 8USDC-28STARV4-24MATIC-S-16SP-24USD-S/StarSeeds_Protocol
      '0x9a6d5cd0b3fa11f3ec57c4f9f98966ceaa592e04000200000000000000000e84', // 99DE-1WMATIC
      '0x99ea8823656aa635a809e538f2e78a253f9add24000100000000000000000e89', // 35WBTC-20STARV4-15MATIC-S-15SP-15USD-S
      '0x43aa957bff5c08458a7c88242e733f5cd962c712000100000000000000000e88', // 20STARV4-15MATIC-S-15SP-15USD-S-35WETH/StarSeeds_Protocol
      '0x691975eb3a86db07a659861959e91bb053518831000100000000000000000e87', // 35USDC-20STARV4-15MATIC-S-15SP-15USD-S/StarSeeds_Protocol
      '0xfc17c5156a56512852c1942caf086350fd4a9094000100000000000000000e86', // 35GHST-20STARV4-15MATIC-S-15SP-15USD-S/StarSeeds Protocol
      '0x406102fac5993c3ce675a66083cc4994b14f4004000100000000000000000e85', // 35WMATIC-20STARV4-15MATIC-S-15SP-15USD-S/StarSeeds_Protocol
      '0x36f86269c9dae8d7367de239f48d1de9c535d7f7000200000000000000000e8b', // 50USDC-50DAI
      '0xd99cba6607d96f286b4b78bb0a0585394afe5e48000200000000000000000e8d', // 50USDC-50USDT
      '0x5192e55b7f93e0d06fac821376c24b4c1817b773000100000000000000000e8f', // 33WMATIC-33WETH-33PORIGON
      '0x569d61d3aa7d8713f843d5556b4d34575538ae37000200000000000000000e90', // 50USDC-50USDT
      '0xf2c0bc92ce33b512c4fe422dd6688fa828682034000100000000000000000e95', // 15WMATIC-15GHST-SS-12AI-15USDC-15STARV4-10BULL-10STAR-L-8SFL/StarSeeds_Protocol
      '0xc7c9a198865abeb4d47f4a84f8bebac396aa8177000100000000000000000e94', // 12WMATIC-10AIMX-10BONSAI-12USD-SS-18STARV4-12WETH-16STAR-L-10OM/StarSeeds_Protocol
      '0xbc0144caf275099e5812b42724d4f432771efd3c000200000000000000000e92', // 50EAV-50USDT
      '0x37dae53197d3cc7ff2ce5eaab7eaeffec2286057000100000000000000000e93', // 24WMATIC-8GHST-SS-16USDC-16GHST-8USD-SS-8STARV4-12WETH-8MATIC-SS/StarSeeds_Protocol
      '0xf33eca7cacbb2adb86e92f9bbb98c8c752f00276000100000000000000000e91', // 10WMATIC-10WBTC-10GHST-30STARV4-40STAR-L/StarSeeds_Protocol
      '0xf9e4d41f781a386faf0d481a91be8949972cec46000100000000000000000e9c', // 8GHST-SS-40AI-8USD-SS-12STARV4-8STAR-L-8MATIC-SS-8BTC-SS-8ETH-SS/StarSeeds_Protocol
      '0x26a48e18feea36b7e4c9f3259750b4a32c044df6000100000000000000000e9d', // 8GHST-SS-8USD-SS-12STARV4-40BULL-8STAR-L-8MATIC-SS-8BTC-SS-8ETH-SS/StarSeeds_Protocol
      '0xe8c5826866321e8160f3703281ca9f6bf2e465ce000100000000000000000e9b', // 8GHST-SS-8USD-SS-12STARV4-8STAR-L-8MATIC-SS-8BTC-SS-40OM-8ETH-SS/StarSeeds_Protocol
      '0x705b4aec6525cd064c2c450a1bc8cbb10c5061c4000100000000000000000e9a', // 8GHST-SS-40BONSAI-8USD-SS-12STARV4-8STAR-L-8MATIC-SS-8BTC-SS-8ETH-SS/StarSeeds_Protocol
      '0xa7b7b1534f2dcf31da4563be3d5c6167bd133378000100000000000000000e99', // 8GHST-SS-40GHST-8USD-SS-12STARV4-8STAR-L-8MATIC-SS-8BTC-SS-8ETH-SS/StarSeeds_Protocol
      '0x2afac430f42e572ba2db375e43195fed75fef74b000100000000000000000e98', // 8GHST-SS-8USD-SS-12STARV4-40WETH-8STAR-L-8MATIC-SS-8BTC-SS-8ETH-SS/StarSeeds_Protocol
      '0x4f689c1110e0bbaf4563babea88f3c27eaa0d0ee000100000000000000000e9e', // 8GHST-SS-40AIMX-8USD-SS-12STARV4-8STAR-L-8MATIC-SS-8BTC-SS-8ETH-SS/StarSeeds_Protocol
      '0x552bac9eb753f04a6b527da5b65832e476c7c06d000100000000000000000e97', // 8GHST-SS-40USDC-8USD-SS-12STARV4-8STAR-L-8MATIC-SS-8BTC-SS-8ETH-SS/StarSeeds_Protocol
      '0x132c8c14179c495ec662b00284c86e89fc25717e000100000000000000000e9f', // 8GHST-SS-8USD-SS-12STARV4-8STAR-L-8MATIC-SS-8BTC-SS-8ETH-SS-40SFL/StarSeeds_Protocol
      '0x6178958a64393ef312a048e56e898755c77ed80a000100000000000000000e96', // 40WMATIC-8GHST-SS-8USD-SS-12STARV4-8STAR-L-8MATIC-SS-8BTC-SS-8ETH-SS/StarSeeds_Protocol
      '0x1dcea0bfbbe6848f117640d534c9b60f41b9f2a8000100000000000000000ea1', // wBTC/wETH/USDC
      '0x3353664e3565f0b0815b785aa068e731da320096000100000000000000000ea0', // Vinu Farm
      '0xe9d79508d22543e36f941ef51aab1e15ac1f7ba5000200000000000000000eab', // 20WMATIC-80RAW/StarSeeds_Protocol
      '0x29b57a05b80ccbdb16a4304dd2c2b00d753cafb6000100000000000000000eac', // 10GHST-SS-20AI-10USD-SS-15STARV4-15STAR-L-10MATIC-SS-10BTC-SS-10ETH-SS/StarSeeds_Protocol
      '0xc19540addaf4e664278ae9f9738003cdf5984326000100000000000000000eaa', // 10GHST-SS-10USD-SS-15STARV4-20RAW-15STAR-L-10MATIC-SS-10BTC-SS-10ETH-SS/StarSeeds_Protocol
      '0xdd736d581466e99fec2c3b3964e77ac91078c9e7000100000000000000000ea9', // 10GHST-SS-20GHST-10USD-SS-15STARV4-15STAR-L-10MATIC-SS-10BTC-SS-10ETH-SS/StarSeeds_Protocol
      '0xa2c2766fcbe7b44ab35e6bf949720fb19fdb2516000100000000000000000ea8', // 10GHST-SS-20USDC-10USD-SS-15STARV4-15STAR-L-10MATIC-SS-10BTC-SS-10ETH-SS/StarSeeds_Protocol
      '0xd5b22175c938690eaa112ffc690326341cc86327000100000000000000000ea7', // 20WMATIC-10GHST-SS-10USD-SS-15STARV4-15STAR-L-10MATIC-SS-10BTC-SS-10ETH-SS/StarSeeds_Protocol
      '0x1f88ac601b4802e20ac89f96fe54d24c7415a5b8000100000000000000000ead', // 33USDC-33USDGLO-33SBC
      '0xc3bb2db921d6f7314a66083b8e9e2903ca1feee8000200000000000000000eb7', // 50WBTC-50USDC
    ],
  },
  Factories: {
    '0xa5bf2ddf098bb0ef6d120c98217dd6b141c74ee0': 'oracleWeightedPool',
    '0x8e9aa87e45e92bad84d5f8dd1bff34fb92637de9': 'weightedPool',
    '0xc66ba2b6595d3613ccab350c886ace23866ede24': 'stablePool',
    '0xdae7e32adc5d490a43ccba1f0c736033f2b4efca': 'stablePool', // Metastable
    '0x751a0bc0e3f75b38e01cf25bfce7ff36de1c87de': 'liquidityBootstrappingPool', // LBP
    '0x41b953164995c11c81da73d212ed8af25741b7ac': 'liquidityBootstrappingPool', // LBP (zero protocol fee)
    '0x0f7bb7ce7b6ed9366f9b6b910adefe72dc538193': 'managedPool', // Polygon Managed
    '0xc128a9954e6c874ea3d62ce62b468ba073093f25': 'boostedPool', // polygon stablephantom
    '0xca96c4f198d343e251b1a01f3eba061ef3da73c1': 'stablePool', // stable pool v2,
    '0x136fd06fa01ecf624c7f2b3cb15742c1339dc2c4': 'composableStablePool', // ComposableStable
    '0x0e39c3d9b2ec765efd9c5c70bb290b1fcd8536e3': 'weightedPool', // weighted pool v2
    '0x7bc6c0e73edaa66ef3f6e2f27b0ee8661834c6c9': 'composableStablePool', // ComposableStable V3
    '0x82e4cfaef85b1b6299935340c964c942280327f4': 'weightedPool', // weighted pool v3
    '0x627d759314d5c4007b461a74ebafa7ebc5dfed71': 'fx', // fx
    '0xfc8a407bba312ac761d8bfe04ce1201904842b76': 'weightedPool', // weighted pool v4
    '0x1a79a24db0f73e9087205287761fc9c5c305926b': 'gyroE',
    '0xe2fa4e1d17725e72dcdafe943ecf45df4b9e285b': 'composableStablePool', // ComposableStable V5
    '0xf169c1ae8de24da43a3dc5c5f05de412b4848bd3': 'fx', // fx
    '0x171665a8d7e7306869a43e8efd312dfee6027352': 'fx', // fx
  },
  Stakable: {
    VotingGaugePools: [
      '0x0297e37f1873d2dab4487aa67cd56b58e2f27875000100000000000000000002',
      '0x03cd191f589d12b0582a99808cf19851e468e6b500010000000000000000000a',
      '0x186084ff790c65088ba694df11758fae4943ee9e000200000000000000000032',
      '0x614b5038611729ed49e0ded154d8a5d3af9d1d9e00010000000000000000001d',
      '0x7c9cf12d783821d5c63d8e9427af5c44bad92445000100000000000000000051',
      '0xc31a37105b94ab4efca1954a14f059af11fcd9bb000000000000000000000455',
      '0x0d34e5dd4d8f043557145598e4e2dc286b35fd4f000000000000000000000068',
      '0x06df3b2bbb68adc8b0e302443692037ed9f91b42000000000000000000000012',
      '0xaf5e0b5425de1f5a630a8cb5aa9d97b8141c908d000200000000000000000366',
      '0xfeadd389a5c427952d8fdb8057d6c8ba1156cc5600020000000000000000001e',
      '0xcf354603a9aebd2ff9f33e1b04246d8ea204ae9500020000000000000000005a',
      '0x5a6ae1fd70d04ba4a279fc219dfabc53825cb01d00020000000000000000020e',
      '0xea4e073c8ac859f2994c07e627178719c8002dc00002000000000000000003dc',
      '0x10f21c9bd8128a29aa785ab2de0d044dcdd79436000200000000000000000059',
      '0x36128d5436d2d70cab39c9af9cce146c38554ff0000100000000000000000008',
      '0x805ca3ccc61cc231851dee2da6aabff0a7714aa7000200000000000000000361',
      '0xb204bf10bc3a5435017d3db247f56da601dfe08a0002000000000000000000fe',
      '0xdb1db6e248d7bb4175f6e5a382d0a03fe3dcc813000100000000000000000035',
      '0xce66904b68f1f070332cbc631de7ee98b650b499000100000000000000000009',
      '0xc17636e36398602dd37bb5d1b3a9008c7629005f0002000000000000000004c4',
      '0x2dbc9ab0160087ae59474fb7bed95b9e808fa6bc0001000000000000000003db',
      '0xb797adfb7b268faeaa90cadbfed464c76ee599cd0002000000000000000005ba',
      '0x8f9dd2064eb38e8e40f2ab67bde27c0e16ea9b080002000000000000000004ca',
      '0x48e6b98ef6329f8f0a30ebb8c7c960330d64808500000000000000000000075b',
      '0xb54b2125b711cd183edd3dd09433439d5396165200000000000000000000075e',
      '0x8159462d255c1d24915cb51ec361f700174cd99400000000000000000000075d',
      '0xb20fc01d21a50d2c734c4a1262b4404d41fa7bf000000000000000000000075c',
      '0x8ac5fafe2e52e52f5352aec64b64ff8b305e1d4a0002000000000000000007ab',
      '0x05f21bacc4fd8590d1eaca9830a64b66a733316c00000000000000000000087e',
      '0x4973f591784d9c94052a6c3ebd553fcd37bb0e5500020000000000000000087f',
      '0xe2f706ef1f7240b803aae877c9c762644bb808d80002000000000000000008c2',
      '0x4a0b73f0d13ff6d43e304a174697e3d5cfd310a400020000000000000000091c',
      '0xe22483774bd8611be2ad2f4194078dac9159f4ba0000000000000000000008f0',
      '0xa48d164f6eb0edc68bd03b56fa59e12f24499ad10000000000000000000007c4',
      '0x7d60a4cb5ca92e2da965637025122296ea6854f900000000000000000000085e',
      '0x34a81e8956bf20b7448b31990a2c06f96830a6e4000200000000000000000a14',
      '0xf3312968c7d768c19107731100ece7d4780b47b2000200000000000000000a50',
      '0x5dee84ffa2dc27419ba7b3419d7146e53e4f7ded000200000000000000000a4e',
      '0xeab6455f8a99390b941a33bbdaf615abdf93455e000200000000000000000a66',
      '0x577f6076e558818a5df21ce4acde9a9623ec0b4c000200000000000000000a64',
      '0x77e97d4908be63394bc5dff72c8c7bddf1699882000000000000000000000a6a',
      '0x513cdee00251f39de280d9e5f771a6eafebcc88e000000000000000000000a6b',
      '0xd80ef9fabfdc3b52e17f74c383cf88ee2efbf0b6000000000000000000000a65',
      '0x65fe9314be50890fb01457be076fafd05ff32b9a000000000000000000000a96',
      '0xb3d658d5b95bf04e2932370dd1ff976fe18dd66a000000000000000000000ace',
      '0x216690738aac4aa0c4770253ca26a28f0115c595000000000000000000000b2c',
      '0xe78b25c06db117fdf8f98583cdaaa6c92b79e917000000000000000000000b2b',
      '0x36a0ee903841584f47e3c774b59e0cbfba46080f000000000000000000000b0a',
      '0x9f9f548354b7c66dc9a9f3373077d86aaaccf8f2000200000000000000000a4a',
      '0xb371aa09f5a110ab69b39a84b5469d29f9b22b76000000000000000000000b37',
      '0x4a77ef015ddcd972fd9ba2c7d5d658689d090f1a000000000000000000000b38',
      '0xe19ed40a47f9b0cea4ca6d372df66107758913ec000000000000000000000b41',
      '0x7f4f4942f2a14b6ab7b08b10ada1aacede4ee8d4000200000000000000000b44',
      '0xd00f9ca46ce0e4a63067c4657986f0167b0de1e5000000000000000000000b42',
      '0x8fd39252d683fdb60bddd4df4b53c9380b496d59000200000000000000000b45',
      '0x3efb91c4f9b103ee45885695c67794591916f34e000200000000000000000b43',
      '0x924ec7ed38080e40396c46f6206a6d77d0b9f72d00020000000000000000072a',
      '0x8fbd0f8e490735cfc3abf4f29cbddd5c3289b9a7000000000000000000000b5b',
      '0xb266ac3b7c98d7bcb28731dac0ef42dba1b276be000000000000000000000be4',
      '0xac2cae8d2f78a4a8f92f20dbe74042cd0a8d5af3000000000000000000000be2',
      '0x402cfdb7781fa85d52f425352661128250b79e12000000000000000000000be3',
      '0x9321e2250767d79bab5aa06daa8606a2b3b7b4c5000000000000000000000bf4',
      '0x03090a9811181a2afe830a3a0b467698ccf3a8b1000000000000000000000bf5',
      '0xf42ed61450458ee4620f5ef4f29adb25a6ef0fb6000000000000000000000bf8',
      '0xd2f3b9e67c69762dd1c88f1d3dadd1649a190761000200000000000000000bf7',
      '0xa8bf1c584519be0184311c48adbdc4c15cb2e8c1000000000000000000000bf6',
      '0xab269164a10fab22bc87c39946da06c870b172d6000000000000000000000bfc',
      '0x2645b13fd2c5295296e94a76280b968bdcbbdfed000000000000000000000c11',
      '0x42942cdec85078cf0e28e9cb5acd40cb53997ed6000000000000000000000bea',
      '0x89b753153678bc434c610b7e9182297cada8ff29000000000000000000000c21',
      '0xcd78a20c597e367a4e478a2411ceb790604d7c8f000000000000000000000c22',
      '0xdc31233e09f3bf5bfe5c10da2014677c23b6894c000000000000000000000c23',
      '0xc474b8512664edc9e746ee5e786478aea15887d2000000000000000000000c25',
      '0x8c89fb53d4d83173dd65b7a94569558ce393460f000000000000000000000c26',
      '0x6da7f1c193ab6c4bd16eb14a6305bba8d25737ff000000000000000000000c29',
      '0x70ff0078d55ce9c1a0e668f35eb4400a4300122d000000000000000000000beb',
      '0x7af62c1ebf97034b7542ccec13a2e79bbcf34380000000000000000000000c13',
      '0xc77a0c0998816f7862aba46f19be6dda7cbb65a8000200000000000000000e77',
      '0x121363653c22f1c8bccbbf1b1aa8e1af85929784000200000000000000000e75',
      '0xba773022202887379f0733f111e4580f53296ada000200000000000000000e76',
      '0x4b7586a4f49841447150d3d92d9e9e000f766c30000000000000000000000e8a', // USDC/USDT/DAI
      '0x1dcea0bfbbe6848f117640d534c9b60f41b9f2a8000100000000000000000ea1', // USDC/WBTC/WETH
      '0x7c173e2a341faf5c90bf0ff448cd925d3731c604000200000000000000000eb8',
      '0x98f9220f128eed5e853cd3d23a2c33e1f98f8311000200000000000000000e55',
      '0x31ac27805b0665695071b640bf2abfbe0736c02e000200000000000000000e56',
      '0x418681127fb0c4fb5ee01202fbe4ab8c8f4eb84a000100000000000000000e59',
    ],
    AllowList: [
      '0xe6d8fcd23ed4e417d7e9d1195edf2ca634684e0e000200000000000000000caf',
    ],
  },
  Metadata: {
    '0xee278d943584dd8640eaf4cc6c7a5c80c0073e85000200000000000000000bc7': {
      points: [
        {
          protocol: Protocol.Gyro,
          multiple: '1',
          description:
            'LPs in this pool earn Gyroscope SPIN on the TVL of the pool.',
        },
      ],
    },
  },
  Deep: [
    '0x48e6b98ef6329f8f0a30ebb8c7c960330d64808500000000000000000000075b', // bb-am-USD (polygon)
    '0xb54b2125b711cd183edd3dd09433439d5396165200000000000000000000075e', // miMATIC/bb-am-USD (polygon)
    '0xd80ef9fabfdc3b52e17f74c383cf88ee2efbf0b6000000000000000000000a65', // tetu/qi (polygon)
    '0xb3d658d5b95bf04e2932370dd1ff976fe18dd66a000000000000000000000ace', // bb-t-USD (tetu managed boosted pool)
    '0x71bd10c2a590b5858f5576550c163976a48af906000000000000000000000b27', // bb-t-MATIC (tetu managed boosted pool)
    '0xe78b25c06db117fdf8f98583cdaaa6c92b79e917000000000000000000000b2b', // bb-a-matic / maticx
    '0x216690738aac4aa0c4770253ca26a28f0115c595000000000000000000000b2c', // stmatic / bb-a-matic
    '0xb371aa09f5a110ab69b39a84b5469d29f9b22b76000000000000000000000b37', // bb-a-usd aave v3
    '0x4a77ef015ddcd972fd9ba2c7d5d658689d090f1a000000000000000000000b38', // wseth/bb-a-eth
    '0x3efb91c4f9b103ee45885695c67794591916f34e000200000000000000000b43', // bb-am-usd/2brl
    '0x7f4f4942f2a14b6ab7b08b10ada1aacede4ee8d4000200000000000000000b44', // stmatci-bb-wmatic/bb-am-usd
    '0x8fd39252d683fdb60bddd4df4b53c9380b496d59000200000000000000000b45', // wsteth-bb-a-weth/bb-am-usd
    '0xd00f9ca46ce0e4a63067c4657986f0167b0de1e5000000000000000000000b42', // frxETH / bb-a-weth
    '0x8fbd0f8e490735cfc3abf4f29cbddd5c3289b9a7000000000000000000000b5b', // frax / bb-am-usd
    '0xca0f8eb1b81e1a69fac97f1c898e0938c768eb20000200000000000000000bc1', // 30WBTC-702BRL (BRZ)
    '0xac2cae8d2f78a4a8f92f20dbe74042cd0a8d5af3000000000000000000000be2', // stMATIC-bb-a-WMATIC
    '0x402cfdb7781fa85d52f425352661128250b79e12000000000000000000000be3', // MaticX-bb-a-WMATIC
    '0xb266ac3b7c98d7bcb28731dac0ef42dba1b276be000000000000000000000be4', // truMATIC/bb-a-WMATIC
    '0x9321e2250767d79bab5aa06daa8606a2b3b7b4c5000000000000000000000bf4', // bb-t-USD
    '0x03090a9811181a2afe830a3a0b467698ccf3a8b1000000000000000000000bf5', // bb-am-USD
    '0xab269164a10fab22bc87c39946da06c870b172d6000000000000000000000bfc', // wstETH-bb-a-WETH-BPT
    '0xf42ed61450458ee4620f5ef4f29adb25a6ef0fb6000000000000000000000bf8', // frxETH-bb-a-WETH
    '0xa8bf1c584519be0184311c48adbdc4c15cb2e8c1000000000000000000000bf6', // FRAX-bb-am-USD
    '0xd2f3b9e67c69762dd1c88f1d3dadd1649a190761000200000000000000000bf7', // 2BRL-bbamUSD
    '0x2645b13fd2c5295296e94a76280b968bdcbbdfed000000000000000000000c11', // DUSD/bbaUSD
  ],
  BoostedApr: [
    '0x48e6b98ef6329f8f0a30ebb8c7c960330d648085', // bb-am-USD
    '0xb54b2125b711cd183edd3dd09433439d53961652', // miMATIC/bb-am-USD
  ],
  DisabledJoins: [
    '0xfeadd389a5c427952d8fdb8057d6c8ba1156cc5600020000000000000000001e',
    '0xb54b2125b711cd183edd3dd09433439d5396165200000000000000000000075e',
    ...CSP_ISSUE_POOL_IDS[Network.POLYGON],
  ],
  Deprecated: {
    '0xc17636e36398602dd37bb5d1b3a9008c7629005f0002000000000000000004c4': {},
    '0xaf5e0b5425de1f5a630a8cb5aa9d97b8141c908d000200000000000000000366': {},
    '0x06df3b2bbb68adc8b0e302443692037ed9f91b42000000000000000000000012': {},
    '0xac2cae8d2f78a4a8f92f20dbe74042cd0a8d5af3000000000000000000000be2': {
      newPool:
        '0x89b753153678bc434c610b7e9182297cada8ff29000000000000000000000c21',
      description: 'deprecatedPool.hasNewPool.description',
    },
    '0x402cfdb7781fa85d52f425352661128250b79e12000000000000000000000be3': {
      newPool:
        '0xcd78a20c597e367a4e478a2411ceb790604d7c8f000000000000000000000c22',
      description: 'deprecatedPool.hasNewPool.description',
    },
    '0xab269164a10fab22bc87c39946da06c870b172d6000000000000000000000bfc': {
      newPool:
        '0xdc31233e09f3bf5bfe5c10da2014677c23b6894c000000000000000000000c23',
      description: 'deprecatedPool.hasNewPool.description',
    },
    '0xf42ed61450458ee4620f5ef4f29adb25a6ef0fb6000000000000000000000bf8': {
      newPool:
        '0xc474b8512664edc9e746ee5e786478aea15887d2000000000000000000000c25',
      description: 'deprecatedPool.hasNewPool.description',
    },
    '0xa8bf1c584519be0184311c48adbdc4c15cb2e8c1000000000000000000000bf6': {
      newPool:
        '0x8c89fb53d4d83173dd65b7a94569558ce393460f000000000000000000000c26',
      description: 'deprecatedPool.hasNewPool.description',
    },
    '0x2645b13fd2c5295296e94a76280b968bdcbbdfed000000000000000000000c11': {
      newPool:
        '0x6da7f1c193ab6c4bd16eb14a6305bba8d25737ff000000000000000000000c29 ',
      description: 'deprecatedPool.hasNewPool.description',
    },
    '0x65fe9314be50890fb01457be076fafd05ff32b9a000000000000000000000a96': {
      newPool:
        '0xdc31233e09f3bf5bfe5c10da2014677c23b6894c000000000000000000000c23 ',
      description: 'deprecatedPool.hasNewPool.description',
    },
  },
  GaugeMigration: {},
  BrandedRedirect: {
    FX: 'xave',
    Gyro2: 'gyro',
    Gyro3: 'gyro',
    GyroE: 'gyro',
  },
  Issues: {
    [PoolWarning.PoolProtocolFeeVulnWarning]: [
      '0xb54b2125b711cd183edd3dd09433439d5396165200000000000000000000075e',
    ],
    [PoolWarning.RenBTCWarning]: [
      '0xfeadd389a5c427952d8fdb8057d6c8ba1156cc5600020000000000000000001e',
    ],
    [PoolWarning.PoolOwnerVulnWarningGovernanceMigrate]: [
      '0x06df3b2bbb68adc8b0e302443692037ed9f91b42000000000000000000000012',
      '0xc17636e36398602dd37bb5d1b3a9008c7629005f0002000000000000000004c4',
    ],
    [PoolWarning.PoolOwnerVulnWarningGovernance]: [
      '0xf38cf113d2d4f60c36cbd95af2f48a9a0167045a00000000000000000000005b',
      '0x0d34e5dd4d8f043557145598e4e2dc286b35fd4f000000000000000000000068',
      '0x5028497af0c9a54ea8c6d42a054c0341b9fc616800020000000000000000007b',
      '0xc31a37105b94ab4efca1954a14f059af11fcd9bb000000000000000000000455',
    ],
    [PoolWarning.PoolOwnerVulnWarningEcosystemMigrate]: [
      '0xaf5e0b5425de1f5a630a8cb5aa9d97b8141c908d000200000000000000000366',
    ],
    [PoolWarning.PoolOwnerVulnWarningEcosystem]: [
      '0xb4670d1389c758e4380c4211bcbc85342688b9c50002000000000000000003d8',
    ],
    [PoolWarning.CspPoolVulnWarning]: CSP_ISSUE_POOL_IDS[Network.POLYGON],
    [PoolWarning.FxPoolVulnWarning]: [
      '0x216b176513c500dbe1d677939103e350a9373a390002000000000000000008da',
      '0x726e324c29a1e49309672b244bdc4ff62a270407000200000000000000000702',
      '0x7e8c6d9db2d1b522f32d050257df0dc524593a07000200000000000000000c4e',
      '0x835ec7212c6075b85730d504c8a19a7116db81b3000200000000000000000703',
      '0x8630bd161689403aea635f830e9ef5496e7e0bc1000200000000000000000c35',
      '0x882c7a84231484b3e9f3fd45ac04b1eb5d35b076000200000000000000000a91',
      '0x8a8275eda88a6f4cc2fa6a41837c1b5dc7a3b095000200000000000000000c36',
      '0xe5093fa1f24619eb16c704a808fad66fb65a1305000200000000000000000c50',
      '0xe93f9dd10b0ba38831cba4d5a9d092e22db130ec000200000000000000000c44',
      '0xfd24afa5416c8de94fdbaf344840f524155a4dd00002000000000000000008db',
    ],
  },
};

export default pools;
