import { Keys } from '../types';

const keys: Keys = {
  infura: 'daaa68ec242643719749dd1caba2fc66',
  alchemy: '',
  graph: '91429265f2a0b1852cd6665ce5fa6a3e',
  balancerApi: 'da2-7a3ukmnw7bexndpx5x522uafui',
};

export default keys;
