import { Network } from '@/lib/config/types';

export const NO_RECOVERY_EXIT_POOL_IDS: Record<Network, string[]> = {
  [Network.MAINNET]: [
    // bb-a-USD v1 and linear's doesn't support recovery exits.
    '0x7b50775383d3d6f0215a8f290f2c9e2eebbeceb20000000000000000000000fe',
    '0x2bbf681cc4eb09218bee85ea2a5d3d13fa40fc0c0000000000000000000000fd',
    '0x804cdb9116a10bb78768d3252355a1b18067bf8f0000000000000000000000fb',
    '0x9210f1204b5a24742eba12f710636d76240df3d00000000000000000000000fc',
    // bb-e-USD Euler linear pools have a specific exit to internal balances.
    // Context is provided in the withdrawal flow, so better to direct them there.
    // Standard recovery exits don't work for these pools.
    '0x3c640f0d3036ad85afa2d5a9e32be651657b874f00000000000000000000046b',
    '0xd4e7c1f3da1144c9e2cfd1b015eda7652b4a439900000000000000000000046a',
    '0xeb486af868aeb3b6e53066abc9623b1041b42bc000000000000000000000046c',
    // Weighted 80 XAV / 20 bb-a-USD v1 pool doesn't support recovery exits.
    '0x8a6b25e33b12d1bb6929a8793961076bd1f9d3eb0002000000000000000003e8',
  ],
  [Network.POLYGON]: [],
  [Network.ARBITRUM]: [],
  [Network.GNOSIS]: [],
  [Network.ZKEVM]: [],
  [Network.AVALANCHE]: [],
  [Network.BASE]: [],
  [Network.SEPOLIA]: [],
  [Network.FANTOM]: [],
  [Network.GOERLI]: [],
  [Network.OPTIMISM]: [],
  [Network.FRAXTAL]: [],
  [Network.MODE]: [],
};

export const CSP_ISSUE_POOL_IDS: Record<Network, string[]> = {
  [Network.MAINNET]: [
    '0x00c2a4be503869fa751c2dbcb7156cc970b5a8da000000000000000000000477',
    '0x02d928e68d8f10c0358566152677db51e1e2dc8c00000000000000000000051e',
    '0x04248aabca09e9a1a3d5129a7ba05b7f17de768400000000000000000000050e',
    '0x05513ca725b6ce035ca2641075474eb469f05f4c00020000000000000000041f',
    '0x0a0fb4ff697de5ac5b6770cd8ee1b72af80b57cf000000000000000000000496',
    '0x0afbd58beca09545e4fb67772faf3858e610bcd00000000000000000000004b9',
    '0x0d05aac44ac7dd3c7ba5d50be93eb884a057d23400000000000000000000051c',
    '0x11839d635e2f0270da37e8ef4324d4d5d54329570002000000000000000004d8',
    '0x126e7643235ec0ab9c103c507642dc3f4ca23c66000000000000000000000468',
    '0x133d241f225750d2c92948e464a5a80111920331000000000000000000000476',
    '0x159cb00338fb63f263fd6f621df619cef71da9540000000000000000000004d5',
    '0x173063a30e095313eee39411f07e95a8a806014e0002000000000000000003ab',
    '0x1bd2f176a812e312077bca87e37c08432bb09f3e0000000000000000000005a1',
    '0x20b156776114e8a801e9767d90c6ccccc8adf398000000000000000000000499',
    '0x246ffb4d928e394a02e45761fecdba6c2e79b8eb000000000000000000000541',
    '0x25accb7943fd73dda5e23ba6329085a3c24bfb6a000200000000000000000387',
    '0x26c2b83fc8535deead276f5cc3ad9c1a2192e02700020000000000000000056b',
    '0x2b218683178d029bab6c9789b1073aa6c96e517600000000000000000000058c',
    '0x2ba7aa2213fa2c909cd9e46fed5a0059542b36b00000000000000000000003a3',
    '0x2bbf681cc4eb09218bee85ea2a5d3d13fa40fc0c0000000000000000000000fd',
    '0x2e52c64fd319e380cdbcfc4577ea1fda558a32e40002000000000000000005ba',
    '0x2f4eb100552ef93840d5adc30560e5513dfffacb000000000000000000000334',
    '0x2ff1a9dbdacd55297452cfd8a4d94724bc22a5f7000000000000000000000484',
    '0x3035917be42af437cbdd774be26b9ec90a2bd677000200000000000000000543',
    '0x331d50e0b00fc1c32742f151e56b9b616227e23e00000000000000000000047c',
    '0x334c96d792e4b26b841d28f53235281cec1be1f200020000000000000000038a',
    '0x335d1709d4da9aca59d16328db5cd4ea66bfe06b0000000000000000000004d6',
    '0x395d8a1d9ad82b5abe558f8abbfe183b27138af40000000000000000000004e5',
    '0x3bb22fc9033b802f2ac47c18885f63476f158afc000000000000000000000483',
    '0x3c640f0d3036ad85afa2d5a9e32be651657b874f00000000000000000000046b',
    '0x3cdae4f12a67ba563499e102f309c73213cb241c000000000000000000000335',
    '0x3dbb8d974b82e82ce79c20c0f5995f4f1f533ede000000000000000000000470',
    '0x3f7a7fd7f214be45ec26820fd01ac3be4fc75aa70002000000000000000004c5',
    '0x3fcb7085b8f2f473f80bf6d879cae99ea4de934400000000000000000000056d',
    '0x41503c9d499ddbd1dcdf818a1b05e9774203bf46000000000000000000000594',
    '0x4228290ee9cab692938ff0b4ba303fbcdb68e9f200020000000000000000057d',
    '0x454ed96955d04d2f5cdd05e0fd1c77975bfe5307000000000000000000000410',
    '0x481c5fc05d63a58aa2f0f2aa417c021b5d419cb200000000000000000000056a',
    '0x483006684f422a9448023b2382615c57c5ecf18f000000000000000000000488',
    '0x4a82b580365cff9b146281ab72500957a849abdc000000000000000000000494',
    '0x4c81255cc9ed7062180ea99962fe05ac0d57350b0000000000000000000005a3',
    '0x4c8d2e60863e8d7e1033eda2b3d84e92a641802000000000000000000000040f',
    '0x4cbde5c4b4b53ebe4af4adb85404725985406163000000000000000000000595',
    '0x4ce0bd7debf13434d3ae127430e9bd4291bfb61f00020000000000000000038b',
    '0x4ce277df0feb5b4d07a0ca2adcf5326e4005239d000000000000000000000518',
    '0x4fd4687ec38220f805b6363c3c1e52d0df3b5023000200000000000000000473',
    '0x4fd63966879300cafafbb35d157dc5229278ed230000000000000000000000e9',
    '0x50cf90b954958480b8df7958a9e965752f62712400000000000000000000046f',
    '0x53bc3cba3832ebecbfa002c12023f8ab1aa3a3a0000000000000000000000411',
    '0x5a6a8cffb4347ff7fc484bf5f0f8a2e234d34255000200000000000000000275',
    '0x5b3240b6be3e7487d61cd1afdfc7fe4fa1d81e6400000000000000000000037b',
    '0x60683b05e9a39e3509d8fdb9c959f23170f8a0fa000000000000000000000489',
    '0x60d604890feaa0b5460b28a424407c24fe89374a0000000000000000000004fc',
    '0x639883476960a23b38579acfd7d71561a0f408cf000200000000000000000505',
    '0x652d486b80c461c397b0d95612a404da936f3db30000000000000000000000e7',
    '0x6667c6fa9f2b3fc1cc8d85320b62703d938e43850000000000000000000004fb',
    '0x6a1eb2e9b45e772f55bd9a34659a04b6f75da68700000000000000000000040d',
    '0x6c56e72c551b5ac4bf54a620a76077ca768c8fe40002000000000000000004da',
    '0x70b7d3b3209a59fb0400e17f67f3ee8c37363f4900020000000000000000018f',
    '0x7337224d59cb16c2dc6938cd45a7b2c60c865d6a0000000000000000000004d4',
    '0x74cbfaf94a3577c539a9dcee9870a6349a33b34f000000000000000000000534',
    '0x779d01f939d78a918a3de18cc236ee89221dfd4e0000000000000000000004c7',
    '0x7b50775383d3d6f0215a8f290f2c9e2eebbeceb20000000000000000000000fe',
    '0x804cdb9116a10bb78768d3252355a1b18067bf8f0000000000000000000000fb',
    '0x813e3fe1761f714c502d1d2d3a7cceb33f37f59d00000000000000000000040c',
    '0x82698aecc9e28e9bb27608bd52cf57f704bd1b83000000000000000000000336',
    '0x8a6b25e33b12d1bb6929a8793961076bd1f9d3eb0002000000000000000003e8',
    '0x8e6ec57a822c2f527f2df7c7d7d361df3e7530a1000000000000000000000498',
    '0x8f4063446f5011bc1c9f79a819efe87776f23704000000000000000000000197',
    '0x9001cbbd96f54a658ff4e6e65ab564ded76a543100000000000000000000050a',
    '0x9210f1204b5a24742eba12f710636d76240df3d00000000000000000000000fc',
    '0x9516a2d25958edb8da246a320f2c7d94a0dbe25d000000000000000000000519',
    '0x959216bb492b2efa72b15b7aacea5b5c984c3cca000200000000000000000472',
    '0x968024662b9566b42d78af23a0f441bc8723fa83000200000000000000000418',
    '0x99c88ad7dc566616548adde8ed3effa730eb6c3400000000000000000000049a',
    '0x9b1c8407a360443a9e5eca004713e4088fab8ac0000000000000000000000497',
    '0x9b692f571b256140a39a34676bffa30634c586e100000000000000000000059d',
    '0x9d7f992c900fbea0ec314bdd71b7cc1becf76a33000200000000000000000573',
    '0x9fb771d530b0ceba5160f7bfe2dd1e8b8aa1340300000000000000000000040e',
    '0xa13a9247ea42d743238089903570127dda72fe4400000000000000000000035d',
    '0xa1697f9af0875b63ddc472d6eebada8c1fab85680000000000000000000004f9',
    '0xa3823e50f20982656557a4a6a9c06ba5467ae9080000000000000000000000e6',
    '0xa718042e5622099e5f0ace4e7122058ab39e1bbe000200000000000000000475',
    '0xa8b103a10a94f4f2d7ed2fdcd5545e807557330700000000000000000000048e',
    '0xac5b4ef7ede2f2843a704e96dcaa637f4ba3dc3f00000000000000000000051d',
    '0xac976bb42cb0c85635644e8c7c74d0e0286aa61c0000000000000000000003cb',
    '0xae37d54ae477268b9997d4161b96b8200755935c000000000000000000000337',
    '0xae8535c23afedda9304b03c68a3563b75fc8f92b0000000000000000000005a0',
    '0xb0f75e97a114a4eb4a425edc48990e6760726709000000000000000000000198',
    '0xb5e3de837f869b0248825e0175da73d4e8c3db6b000200000000000000000474',
    '0xb841b062ea8ccf5c4cb78032e91de4ae875560420002000000000000000005b7',
    '0xb9bd68a77ccf8314c0dfe51bc291c77590c4e9e6000200000000000000000385',
    '0xbb6881874825e60e1160416d6c426eae65f2459e000000000000000000000592',
    '0xbc0f2372008005471874e426e86ccfae7b4de79d000000000000000000000485',
    '0xbf2ef8bdc2fc0f3203b3a01778e3ec5009aeef3300000000000000000000058d',
    '0xbfa413a2ff0f20456d57b643746133f54bfe0cd20000000000000000000004c3',
    '0xc2b021133d1b0cf07dba696fd5dd89338428225b000000000000000000000598',
    '0xc443c15033fcb6cf72cc24f1bda0db070ddd9786000000000000000000000593',
    '0xc50d4347209f285247bda8a09fc1c12ce42031c3000000000000000000000590',
    '0xc5dc1316ab670a2eed5716d7f19ced321191f38200000000000000000000056e',
    '0xc8c79fcd0e859e7ec81118e91ce8e4379a481ee6000000000000000000000196',
    '0xcaa052584b462198a5a9356c28bce0634d65f65c0000000000000000000004db',
    '0xcbfa4532d8b2ade2c261d3dd5ef2a2284f7926920000000000000000000004fa',
    '0xcfae6e251369467f465f13836ac8135bd42f8a56000000000000000000000591',
    '0xd4e7c1f3da1144c9e2cfd1b015eda7652b4a439900000000000000000000046a',
    '0xd6e355036f41dc261b3f1ed3bbc6003e87aadb4f000000000000000000000495',
    '0xd7edb56f63b2a0191742aea32df1f98ca81ed9c600000000000000000000058e',
    '0xd997f35c9b1281b82c8928039d14cddab5e13c2000000000000000000000019c',
    '0xdba274b4d04097b90a72b62467d828cefd708037000000000000000000000486',
    '0xdc063deafce952160ec112fa382ac206305657e60000000000000000000004c4',
    '0xdec02e6642e2c999af429f5ce944653cad15e093000000000000000000000469',
    '0xe03af00fabe8401560c1ff7d242d622a5b601573000000000000000000000493',
    '0xe0fcbf4d98f0ad982db260f86cf28b49845403c5000000000000000000000504',
    '0xe2d16b0a39f3fbb4389a0e8f1efcbecfb3d1e6e10000000000000000000005a7',
    '0xe4dc3c1998ac693d68f4c77476d7c815694c3e94000200000000000000000416',
    '0xe6bcc79f328eec93d4ec8f7ed35534d9ab549faa0000000000000000000000e8',
    '0xe8c56405bc405840154d9b572927f4197d110de10000000000000000000005a4',
    '0xeb486af868aeb3b6e53066abc9623b1041b42bc000000000000000000000046c',
    '0xeb567dde03f3da7fe185bdacd5ab495ab220769d000000000000000000000548',
    '0xec3626fee40ef95e7c0cbb1d495c8b67b34d398300000000000000000000053d',
    '0xf22ff21e17157340575158ad7394e068048dd98b0000000000000000000004b8',
    '0xf57c794f42da72b38c8f610ff3b5e8502e48cbde00000000000000000000055c',
    '0xf71d0774b214c4cf51e33eb3d30ef98132e4dbaa00000000000000000000046e',
    '0xfa24a90a3f2bbe5feea92b95cd0d14ce709649f900000000000000000000058f',
    '0xfd11ccdbdb7ab91cb9427a6d6bf570c95876d1950000000000000000000004c2',
    '0xfebb0bbf162e64fb9d0dfe186e517d84c395f016000000000000000000000502',
    '0xfef969638c52899f91781f1be594af6f40b99bad00000000000000000000047b',
  ],
  [Network.POLYGON]: [
    '0x02e139d53ebf4033bf78ab66c6a1e7f1f204487f0002000000000000000009f9',
    '0x03090a9811181a2afe830a3a0b467698ccf3a8b1000000000000000000000bf5',
    '0x0320c1c5b6df19a194d48882aaec1c72940081d9000000000000000000000a7d',
    '0x04b54ea92d73de2d62d651db7d9778f0c49157d8000200000000000000000ba2',
    '0x0503dd6b2d3dd463c9bef67fb5156870af63393e00000000000000000000042e',
    '0x0889b240a5876aae745ac19f1771853671dc5d36000000000000000000000b3f',
    '0x0bc54e914f53f98d16035f4f0d948f3e09c2fac0000200000000000000000bac',
    '0x0c06e87c7b88d998f645b91c1f53b51294b12bca000100000000000000000bb9',
    '0x10b040038f87219d9b42e025e3bd9b8095c87dd9000000000000000000000b11',
    '0x117a3d474976274b37b7b94af5dcade5c90c6e85000000000000000000000aca',
    '0x11884da90fb4221b3aa288a7741c51ec4fc43b2f000000000000000000000a5f',
    '0x1379b816b9be611431d693290289c204720ca56d000100000000000000000b6f',
    '0x150e7b885bdfce974f2abe88a72fdbd692175c6f0002000000000000000009fd',
    '0x178e029173417b1f9c8bc16dcec6f697bc323746000000000000000000000758',
    '0x1aafc31091d93c3ff003cff5d2d8f7ba2e7284250000000000000000000003b3',
    '0x216690738aac4aa0c4770253ca26a28f0115c595000000000000000000000b2c',
    '0x216d6db0c28204014618482c369d7fbf0a8f3232000100000000000000000b60',
    '0x230ecdb2a7cee56d6889965a023aa0473d6da507000000000000000000000bf3',
    '0x252ff6a3a6fd7b5e8e999de8e3f5c3b306ed1401000200000000000000000bec',
    '0x25e57f4612912614e6c99616bd2abb9b5ae71e99000000000000000000000bf0',
    '0x2645b13fd2c5295296e94a76280b968bdcbbdfed000000000000000000000c11',
    '0x284eb68520c8fa83361c1a3a5910aec7f873c18b000000000000000000000ac9',
    '0x2c8dbe8eb86135d9f2f26d196748c088d47f73e7000200000000000000000a29',
    '0x31bccf9e28b94e5dacebaa67fe8bc1603cecd904000000000000000000000a01',
    '0x341068a547c3cde3c09e338714010dd01b32f93f000200000000000000000a34',
    '0x3db543faf7a92052de7860c5c9debabee59ed5bd000000000000000000000a62',
    '0x3dd0843a028c86e0b760b1a76929d1c5ef93a2dd00000000000000000000070d',
    '0x3efb91c4f9b103ee45885695c67794591916f34e000200000000000000000b43',
    '0x402cfdb7781fa85d52f425352661128250b79e12000000000000000000000be3',
    '0x43894de14462b421372bcfe445fa51b1b4a0ff3d000000000000000000000b36',
    '0x4739e50b59b552d490d3fdc60d200977a38510c0000000000000000000000b10',
    '0x48e6b98ef6329f8f0a30ebb8c7c960330d64808500000000000000000000075b',
    '0x4a0b73f0d13ff6d43e304a174697e3d5cfd310a400020000000000000000091c',
    '0x4a77ef015ddcd972fd9ba2c7d5d658689d090f1a000000000000000000000b38',
    '0x4ae3661afa119892f0cc8c43edaf6a94989ac171000000000000000000000c06',
    '0x4ccb966d8246240afb7a1a24628efb930870b1c40002000000000000000009fc',
    '0x52cc8389c6b93d740325729cc7c958066cee4262000000000000000000000b0f',
    '0x5b77107fcdf2b41903bab2bc555d4fc14cf7667d000000000000000000000b32',
    '0x5bae72b75caab1f260d21bc028c630140607d6e8000000000000000000000ac6',
    '0x600bd01b6526611079e12e1ff93aba7a3e34226f0000000000000000000009e4',
    '0x63ce19ccd39930725b8a3d2733627804718ab83d000000000000000000000bf2',
    '0x64efad69f099813021b41f4cac6e749fd55e188f000000000000000000000b39',
    '0x6933ec1ca55c06a894107860c92acdfd2dd8512f000000000000000000000428',
    '0x6abe4e7a497b8293c258389c1b00d177e4f257ed00010000000000000000080d',
    '0x6c8c7fc50247a47038015eb1fd5dc105d05dafba000200000000000000000ba0',
    '0x7079a25dec33be61bbd81b2fb69b468e80d3e72c0000000000000000000009ff',
    '0x71bd10c2a590b5858f5576550c163976a48af906000000000000000000000b27',
    '0x7c82a23b4c48d796dee36a9ca215b641c6a8709d000000000000000000000acd',
    '0x7f4f4942f2a14b6ab7b08b10ada1aacede4ee8d4000200000000000000000b44',
    '0x86aef31951e0a3a54333bd9e72f9a95587d058c5000200000000000000000912',
    '0x882c7a84231484b3e9f3fd45ac04b1eb5d35b076000200000000000000000a91',
    '0x894c82800526e0391e709c0983a5aea3718b7f6d000000000000000000000ac5',
    '0x89b28a9494589b09dbccb69911c189f74fdadc5a000000000000000000000b33',
    '0x89bb15076c9f2d86aa98ec6cffc1a71e31c38953000000000000000000000bf1',
    '0x89f1146fee52b5d9166e9c83cc388b6d8f69f1380001000000000000000009e7',
    '0x8a819a4cabd6efcb4e5504fe8679a1abd831dd8f00000000000000000000042d',
    '0x8b58a1e7fff52001c22386c2918d45938a6a9be30001000000000000000008d9',
    '0x8b8225bfedebaf1708c55743acb4ad43fd4d0f21000200000000000000000918',
    '0x8fbd0f8e490735cfc3abf4f29cbddd5c3289b9a7000000000000000000000b5b',
    '0x8fd39252d683fdb60bddd4df4b53c9380b496d59000200000000000000000b45',
    '0x9321e2250767d79bab5aa06daa8606a2b3b7b4c5000000000000000000000bf4',
    '0x949a12b95ec5b80c375b98963a5d6b33b0d0efff0002000000000000000009fe',
    '0x9a020bdc2faff5bd24c6acc2020d01ff9f2c627a000000000000000000000ae2',
    '0x9cf9358300e34bf9373d30129a1e718d8d058b54000200000000000000000913',
    '0x9e34631547adcf2f8cefa0f5f223955c7b137571000000000000000000000ad5',
    '0xa5a935833f6a5312715f182733eab088452335d7000100000000000000000bee',
    '0xa5fe91dde37d8bf2dacacc0168b115d28ed03f84000000000000000000000b35',
    '0xa8bf1c584519be0184311c48adbdc4c15cb2e8c1000000000000000000000bf6',
    '0xab269164a10fab22bc87c39946da06c870b172d6000000000000000000000bfc',
    '0xac2cae8d2f78a4a8f92f20dbe74042cd0a8d5af3000000000000000000000be2',
    '0xae646817e458c0be890b81e8d880206710e3c44e000000000000000000000acb',
    '0xaef2c171dbe64b0c18977e16e70bfd29d4ee0256000000000000000000000ac8',
    '0xb0c830dceb4ef55a60192472c20c8bf19df03488000000000000000000000be1',
    '0xb266ac3b7c98d7bcb28731dac0ef42dba1b276be000000000000000000000be4',
    '0xb371aa09f5a110ab69b39a84b5469d29f9b22b76000000000000000000000b37',
    '0xb3d658d5b95bf04e2932370dd1ff976fe18dd66a000000000000000000000ace',
    '0xb54b2125b711cd183edd3dd09433439d5396165200000000000000000000075e',
    '0xb59be8f3c85a9dd6e2899103b6fbf6ea405b99a4000000000000000000000b34',
    '0xb878ecce26838fbba4f78cb5b791a0e09152c067000000000000000000000427',
    '0xb973ca96a3f0d61045f53255e319aedb6ed4924000000000000000000000042f',
    '0xbd4e35784c832d0f9049b54cb3609e5907c5b495000100000000000000000b14',
    '0xc55ec796a4debe625d95436a3531f4950b11bdcf000000000000000000000b3e',
    '0xc7e6389e364f4275eb442ef215ed21877028e2af000000000000000000000ac7',
    '0xc83b55bbd005f1f84906545fcdb145dee53523e0000200000000000000000b30',
    '0xcb21a9e647c95127ed784626485b3104cb28d0e7000000000000000000000425',
    '0xd00f9ca46ce0e4a63067c4657986f0167b0de1e5000000000000000000000b42',
    '0xd2f3b9e67c69762dd1c88f1d3dadd1649a190761000200000000000000000bf7',
    '0xd4accb350f9cf59fe3cf7a5ee6ed9ace6a568ea9000200000000000000000b75',
    '0xda1cd1711743e57dd57102e9e61b75f3587703da000000000000000000000acc',
    '0xdae301690004946424e41051ace1791083be42a1000000000000000000000b40',
    '0xde0a77ab6689b980c30306b10f9131a007e1af81000200000000000000000ba1',
    '0xe051605a83deae38d26a7346b100ef1ac2ef8a0b0000000000000000000003ce',
    '0xe1fb90d0d3b47e551d494d7ebe8f209753526b01000000000000000000000ac4',
    '0xe2272cddb2cc408e79e02a73d1db9acc24a843d5000200000000000000000ba7',
    '0xe2dc0e0f2c358d6e31836dee69a558ab8d1390e70000000000000000000009fa',
    '0xe4885ed2818cc9e840a25f94f9b2a28169d1aea7000000000000000000000b29',
    '0xe6909c2f18a29d97217a6146f045e1780606991f000100000000000000000bfe',
    '0xe78b25c06db117fdf8f98583cdaaa6c92b79e917000000000000000000000b2b',
    '0xea11645ac7d8f2def94c9d8d86bd766296c9b6b6000000000000000000000b3a',
    '0xeb480dbbdd921cd6c359e4cc4c65ddea6395e2a1000200000000000000000946',
    '0xed35f28f837e96f81240ebb82e0e3f518c7e8a2f000100000000000000000bb5',
    '0xf0211cceebe6fcc45052b4e57ee95d233f5669d2000100000000000000000c01',
    '0xf22a66046b5307842f21b311ecb4c462c24c0635000000000000000000000b15',
    '0xf28f17be00f8ca3c9b7f66a4aad5513757fb3341000200000000000000000b5a',
    '0xf42ed61450458ee4620f5ef4f29adb25a6ef0fb6000000000000000000000bf8',
    '0xf48f01dcb2cbb3ee1f6aab0e742c2d3941039d56000000000000000000000445',
    '0xf93579002dbe8046c43fefe86ec78b1112247bb8000000000000000000000759',
    '0xf984eb2b8a7ef780245a797a2fccd82f346409ca000000000000000000000a59',
    '0xfa2c0bd8327c99db5bde4c9e9e5cbf30946351bb000000000000000000000948',
    '0xff4ce5aaab5a627bf82f4a571ab1ce94aa365ea600000000000000000000075a',
  ],
  [Network.ARBITRUM]: [
    '0x00fcd3d55085e998e291a0005cedecf58ac14c4000020000000000000000047f',
    '0x077794c30afeccdf5ad2abc0588e8cee7197b71a000000000000000000000352',
    '0x117a3d474976274b37b7b94af5dcade5c90c6e85000000000000000000000381',
    '0x11884da90fb4221b3aa288a7741c51ec4fc43b2f000000000000000000000353',
    '0x19b1c92631405a0a9495ccba0becf4f2e8e908bd000000000000000000000410',
    '0x1e550b7764da9638fdd32c8a701364de31f45ee800000000000000000000047c',
    '0x1fa7f727934226aedab636d62a084931b97d366b000000000000000000000411',
    '0x23ca0306b21ea71552b148cf3c4db4fc85ae19290000000000000000000000c9',
    '0x284eb68520c8fa83361c1a3a5910aec7f873c18b000000000000000000000380',
    '0x2a96254ca32020b20ed3506f8f75318da24709f9000200000000000000000456',
    '0x36942963e3b6f37ecc45a4e72349558514233f0000000000000000000000048a',
    '0x3f53a862919ccfa023cb6ace91378a79fb0f6bf500000000000000000000040f',
    '0x40af308e3d07ec769d85eb80afb116525ff4ac99000000000000000000000485',
    '0x418de00ae109e6f874d872658767866d680eaa1900000000000000000000047d',
    '0x45c4d1376943ab28802b995acffc04903eb5223f000000000000000000000470',
    '0x4689122d360c4725d244c5cfea22861333d862e6000100000000000000000468',
    '0x4739e50b59b552d490d3fdc60d200977a38510c0000000000000000000000409',
    '0x49a0e3334496442a9706e481617724e7e37eaa080000000000000000000003ff',
    '0x519cce718fcd11ac09194cff4517f12d263be067000000000000000000000382',
    '0x52cc8389c6b93d740325729cc7c958066cee4262000000000000000000000408',
    '0x567ecfcb22205d279bb8eed3e066989902bf03d5000000000000000000000452',
    '0x585d95df0231fa08aeee35ff0c16b92fd0ecdc3300020000000000000000045f',
    '0x5a7f39435fd9c381e4932fa2047c9a5136a5e3e7000000000000000000000400',
    '0x5bae72b75caab1f260d21bc028c630140607d6e8000000000000000000000350',
    '0x6cb787a419c3e6ee2e9ff365856c29cd10659113000000000000000000000474',
    '0x7c82a23b4c48d796dee36a9ca215b641c6a8709d000000000000000000000406',
    '0x81fc12c60ee5b753cf5fd0adc342dfb5f3817e3200000000000000000000035d',
    '0x894c82800526e0391e709c0983a5aea3718b7f6d00000000000000000000034f',
    '0x970712708a08e8fb152be4d81b2dc586923f5369000200000000000000000479',
    '0x9bf7c3b63c77b4b4f2717776f15a4bec1b532a280000000000000000000000c8',
    '0x9cebf13bb702f253abf1579294694a1edad00eaa000000000000000000000486',
    '0x9e34631547adcf2f8cefa0f5f223955c7b137571000000000000000000000407',
    '0x9fb7d6dcac7b6aa20108bad226c35b85a9e31b63000200000000000000000412',
    '0xa1ea76c42b2938cfa9abea12357881006c52851300000000000000000000048f',
    '0xa50f89e9f439fde2a6fe05883721a00475da3c4500000000000000000000048b',
    '0xa612b6aed2e7ca1a3a4f23fbca9128461bbb7718000000000000000000000274',
    '0xa8af146d79ac0bb981e4e0d8b788ec5711b1d5d000000000000000000000047b',
    '0xad28940024117b442a9efb6d0f25c8b59e1c950b00000000000000000000046f',
    '0xae646817e458c0be890b81e8d880206710e3c44e00000000000000000000039d',
    '0xaef2c171dbe64b0c18977e16e70bfd29d4ee0256000000000000000000000351',
    '0xbbf9d705b75f408cfcaee91da32966124d2c6f7d00000000000000000000047e',
    '0xbd724eb087d4cc0f61a5fed1fffaf937937e14de000000000000000000000473',
    '0xbe0f30217be1e981add883848d0773a86d2d2cd4000000000000000000000471',
    '0xc46be4b8bb6b5a3d3120660efae9c5416318ed40000000000000000000000472',
    '0xc69771058481551261709d8db44977e9afde645000010000000000000000042a',
    '0xc6eee8cb7643ec2f05f46d569e9ec8ef8b41b389000000000000000000000475',
    '0xcba9ff45cfb9ce238afde32b0148eb82cbe635620000000000000000000003fd',
    '0xcf8b555b7754556cf2ac2165e77ee23ed8517d7900020000000000000000045e',
    '0xd0dc20e6342db2de82692b8dc842301ff9121805000200000000000000000454',
    '0xd3d5d45f4edf82ba0dfaf061d230766032a10e07000200000000000000000413',
    '0xd6d20527c7b0669989ee082b9d3a1c63af742290000000000000000000000483',
    '0xda1cd1711743e57dd57102e9e61b75f3587703da0000000000000000000003fc',
    '0xe1fb90d0d3b47e551d494d7ebe8f209753526b0100000000000000000000034e',
    '0xee02583596aee94cccb7e8ccd3921d955f17982a00000000000000000000040a',
    '0xf984eb2b8a7ef780245a797a2fccd82f346409ca00000000000000000000034d',
    '0xff8f84e8c87532af96aef5582ee451572233678b000200000000000000000478',
  ],
  [Network.GNOSIS]: [
    '0x1ac55c31dac78ca943cb8ebfca5945ce09e036e2000000000000000000000024',
    '0x225e0047671939a8d78e08ebd692788abe63f15c000000000000000000000009',
    '0x41211bba6d37f5a74b22e667533f080c7c7f3f1300000000000000000000000b',
    '0x4de21b365d6543661d0e105e579a34b963862497000200000000000000000045',
    '0x581ec1f5e7ced12b186deae32256adb53bdd5b08000000000000000000000001',
    '0x66f33ae36dd80327744207a48122f874634b3ada000100000000000000000013',
    '0xa3ed6f78edc29f69df8a0d16b1d1ccf9871f918c000000000000000000000032',
    '0xa611a551b95b205ccd9490657acf7899daee5db700000000000000000000002e',
    '0xb95829adbacd8af89e291dee78bc09e24de51d6b000000000000000000000043',
    '0xb973ca96a3f0d61045f53255e319aedb6ed49240000200000000000000000011',
    '0xba1a5b19d09a79dada039b1f974015c5a989d5fd000100000000000000000046',
    '0xbb9cd48d33033f5effbedec9dd700c7d7e1dcf5000000000000000000000000e',
    '0xd16f72b02da5f51231fde542a8b9e2777a478c8800000000000000000000000f',
    '0xd4015683b8153666190e0b2bec352580ebc4caca00000000000000000000000d',
    '0xe15cac1df3621e001f76210ab12a7f1a1691481f000000000000000000000044',
    '0xe7f88d7d4ef2eb18fcf9dd7216ba7da1c46f3dd600000000000000000000000a',
    '0xf48f01dcb2cbb3ee1f6aab0e742c2d3941039d56000200000000000000000012',
    '0xfedb19ec000d38d92af4b21436870f115db22725000000000000000000000010',
    '0xffff76a3280e95dc855696111c2562da09db2ac000000000000000000000000c',
  ],
  [Network.ZKEVM]: [
    '0x02c9dcb975262719a61f9b40bdf0987ead9add3a000000000000000000000006',
    '0x16c9a4d841e88e52b51936106010f27085a529ec00000000000000000000000c',
    '0x32be2d0ddeaf3333501b24a28668ce373ba8e763000200000000000000000014',
    '0x32f03464fdf909fdf3798f87ff3712b10c59bd86000000000000000000000005',
    '0x4b718e0e2fea1da68b763cd50c446fba03ceb2ea00000000000000000000000b',
    '0x68a69c596b3839023c0e08d09682314f582314e5000200000000000000000011',
    '0x6f34a44fce1506352a171232163e7716dd073ade000200000000000000000015',
    '0x9e2d87f904862671eb49cb358e74284762cc9f42000200000000000000000013',
    '0xac4b72c01072a52b73ca71105504f1372efcce0d000000000000000000000003',
    '0xbfd65c6160cfd638a85c645e6e6d8acac5dac935000000000000000000000004',
    '0xe274c9deb6ed34cfe4130f8d0a8a948dea5bb28600000000000000000000000d',
  ],
  [Network.AVALANCHE]: [
    '0x054e7b0c73e1ee5aed6864fa511658fc2b54bcaa000000000000000000000015',
    '0x3f1a2c4a3a751f6626bd90ef16e104f0772d4d6b00020000000000000000001b',
    '0x7275c131b1f67e8b53b4691f92b0e35a4c1c6e22000000000000000000000010',
    '0xa154009870e9b6431305f19b09f9cfd7284d4e7a000000000000000000000013',
    '0xa1d14d922a575232066520eda11e27760946c991000000000000000000000012',
    '0xa826a114b0c7db4d1ff4a4be845a78998c64564c000000000000000000000008',
    '0xea67626e1f0b59e0d172a04f5702ef90bcdf440c00000000000000000000000f',
    '0xeb496161099d45b3ea4892408ef745c6182eb56e00000000000000000000000e',
    '0xece571847897fd61e764d455dc15cf1cd9de8d6f000000000000000000000014',
    '0xed3e2f496cbcd8e212192fb8d1499842f04a0d19000000000000000000000009',
  ],
  [Network.BASE]: [],
  [Network.SEPOLIA]: [
    '0x027a946ac5a861b66a50cca0e8fb8766718b485100000000000000000000002c',
  ],
  [Network.FANTOM]: [],
  [Network.GOERLI]: [],
  [Network.OPTIMISM]: [
    '0x1f131ec1175f023ee1534b16fa8ab237c00e238100000000000000000000004a',
    '0x479a7d1fcdd71ce0c2ed3184bfbe9d23b92e8337000000000000000000000049',
    '0x23ca0306b21ea71552b148cf3c4db4fc85ae19290000000000000000000000ac',
    '0x43da214fab3315aa6c02e0b8f2bfb7ef2e3c60a50000000000000000000000ae',
    '0x62cf35db540152e94936de63efc90d880d4e241b0000000000000000000000ef',
    '0x098f32d98d0d64dba199fc1923d3bf4192e787190001000000000000000000d2',
    '0xb1c9ac57594e9b1ec0f3787d9f6744ef4cb0a02400000000000000000000006e',
    '0x05e7732bf9ae5592e6aa05afe8cd80f7ab0a7bea00020000000000000000005a',
    '0xde45f101250f2ca1c0f8adfc172576d10c12072d00000000000000000000003f',
    '0x981fb05b738e981ac532a99e77170ecb4bc27aef00010000000000000000004b',
    '0x6222ae1d2a9f6894da50aa25cb7b303497f9bebd000000000000000000000046',
    '0x3c74c4ed512050eb843d89fb9dcd5ebb4668eb6d0002000000000000000000cc',
    '0x7fe29a818438ed2759e30f65c2302295711d66fc0000000000000000000000e5',
    '0xb0de49429fbb80c635432bbad0b3965b2856017700010000000000000000004e',
    '0x428e1cc3099cf461b87d124957a0d48273f334b100000000000000000000007f',
    '0x359ea8618c405023fc4b98dab1b01f373792a12600010000000000000000004f',
    '0x62de5ca16a618e22f6dfe5315ebd31acb10c44b6000000000000000000000037',
    '0x7d6bff131b359da66d92f215fd4e186003bfaa42000000000000000000000058',
    '0x9964b1bd3cc530e5c58ba564e45d45290f677be2000000000000000000000036',
    '0x2c4a83f98d1cdbeeec825fabacd09c46e2dd3c570002000000000000000000de',
    '0x8b6d3aa69c1cf47677281691b1abf3831ba1329d0001000000000000000000d0',
    '0x64cee2338369aa9b36fc756ea231eb9bc242926f0000000000000000000000df',
    '0xe0b50b0635b90f7021d2618f76ab9a31b92d009400010000000000000000003a',
    '0x8a2872fd28f42bd9f6559907235e83fbf4167f480001000000000000000000f2',
    '0x362715c164d606682c4ea7e479633e419d9345eb0001000000000000000000e7',
    '0xcd7b2232b7435595bbc7fd7962f1f352fc2cc61a0000000000000000000000f0',
    '0xf572649606db4743d217a2fa6e8b8eb79742c24a000000000000000000000039',
    '0xbec621c9ab4ceddcc2a157ca9b5c475fab65f6a40000000000000000000000f3',
    '0x5470f064a19c65263b3033da3a6124fdf0a9bab80000000000000000000000e6',
    '0xcb89e89d798a4563d1599ea5508282e13b225b520000000000000000000000e4',
    '0xe94c45de980f914904fdcfa9fbbe7c4a0ffe6ac70000000000000000000000e0',
    '0x435272180a4125f3b47c92826f482fc6cc165958000200000000000000000059',
    '0x96a78983932b8739d1117b16d30c15607926b0c500000000000000000000006d',
    '0x593acbfb1eaf3b6ec86fa60325d816996fdcbc0d000000000000000000000038',
    '0xd1af4974fcc995cf36ba40b189caa92964a9126d0000000000000000000000f1',
  ],
  [Network.FRAXTAL]: [],
  [Network.MODE]: [],
};

export const HIGH_RISK_POOL_IDS: string[] = [
  '0xf48f01dcb2cbb3ee1f6aab0e742c2d3941039d56000200000000000000000012',
  '0xbf2ef8bdc2fc0f3203b3a01778e3ec5009aeef3300000000000000000000058d',
  '0x99c88ad7dc566616548adde8ed3effa730eb6c3400000000000000000000049a',
  '0xfedb19ec000d38d92af4b21436870f115db22725000000000000000000000010',
  '0xffff76a3280e95dc855696111c2562da09db2ac000000000000000000000000c',
  '0xbb9cd48d33033f5effbedec9dd700c7d7e1dcf5000000000000000000000000e',
  '0x4a82b580365cff9b146281ab72500957a849abdc000000000000000000000494',
  '0xd16f72b02da5f51231fde542a8b9e2777a478c8800000000000000000000000f',
  '0x60683b05e9a39e3509d8fdb9c959f23170f8a0fa000000000000000000000489',
  '0xe03af00fabe8401560c1ff7d242d622a5b601573000000000000000000000493',
  '0xb3d658d5b95bf04e2932370dd1ff976fe18dd66a000000000000000000000ace',
  '0xa13a9247ea42d743238089903570127dda72fe4400000000000000000000035d',
  '0x2b218683178d029bab6c9789b1073aa6c96e517600000000000000000000058c',
  '0xb973ca96a3f0d61045f53255e319aedb6ed49240000200000000000000000011',
  '0x66f33ae36dd80327744207a48122f874634b3ada000100000000000000000013',
  '0x7c82a23b4c48d796dee36a9ca215b641c6a8709d000000000000000000000acd',
  '0x2ff1a9dbdacd55297452cfd8a4d94724bc22a5f7000000000000000000000484',
  '0x2f4eb100552ef93840d5adc30560e5513dfffacb000000000000000000000334',
  '0xc0064b291bd3d4ba0e44ccfc81bf8e7f7a579cd200000000000000000000042c',
  '0x7b50775383d3d6f0215a8f290f2c9e2eebbeceb20000000000000000000000fe',
  '0x25accb7943fd73dda5e23ba6329085a3c24bfb6a000200000000000000000387',
  '0x50cf90b954958480b8df7958a9e965752f62712400000000000000000000046f',
  '0x41211bba6d37f5a74b22e667533f080c7c7f3f1300000000000000000000000b',
  '0xe7f88d7d4ef2eb18fcf9dd7216ba7da1c46f3dd600000000000000000000000a',
  '0x133d241f225750d2c92948e464a5a80111920331000000000000000000000476',
  '0xdba274b4d04097b90a72b62467d828cefd708037000000000000000000000486',
  '0x2bbf681cc4eb09218bee85ea2a5d3d13fa40fc0c0000000000000000000000fd',
  '0xbc0f2372008005471874e426e86ccfae7b4de79d000000000000000000000485',
  '0xae646817e458c0be890b81e8d880206710e3c44e000000000000000000000acb',
  '0xd4015683b8153666190e0b2bec352580ebc4caca00000000000000000000000d',
  '0xc3bf643799237588b7a6b407b3fc028dd4e037d200000000000000000000022d',
  '0x3c640f0d3036ad85afa2d5a9e32be651657b874f00000000000000000000046b',
  '0xd4e7c1f3da1144c9e2cfd1b015eda7652b4a439900000000000000000000046a',
  '0xeb486af868aeb3b6e53066abc9623b1041b42bc000000000000000000000046c',
  '0x82698aecc9e28e9bb27608bd52cf57f704bd1b83000000000000000000000336',
  '0xae37d54ae477268b9997d4161b96b8200755935c000000000000000000000337',
  '0x23ca0306b21ea71552b148cf3c4db4fc85ae19290000000000000000000000ac',
  '0x48e6b98ef6329f8f0a30ebb8c7c960330d64808500000000000000000000075b',
  '0xda1cd1711743e57dd57102e9e61b75f3587703da000000000000000000000acc',
  '0xa8af146d79ac0bb981e4e0d8b788ec5711b1d5d000000000000000000000047b',
  '0x9210f1204b5a24742eba12f710636d76240df3d00000000000000000000000fc',
  '0x804cdb9116a10bb78768d3252355a1b18067bf8f0000000000000000000000fb',
  '0xff4ce5aaab5a627bf82f4a571ab1ce94aa365ea600000000000000000000075a',
  '0x62cf35db540152e94936de63efc90d880d4e241b0000000000000000000000ef',
  '0x6e6dc948ce85c62125ff7a1e543d761a88f0a4cb000000000000000000000743',
  '0x284eb68520c8fa83361c1a3a5910aec7f873c18b000000000000000000000380',
  '0xb96c5bada4bf6a70e71795a3197ba94751dae2db00000000000000000000007d',
  '0x62ec8b26c08ffe504f22390a65e6e3c1e45e987700000000000000000000007e',
  '0x8a6b25e33b12d1bb6929a8793961076bd1f9d3eb0002000000000000000003e8',
  '0xa611a551b95b205ccd9490657acf7899daee5db700000000000000000000002e',
  '0x92502cd8e00f5b8e737b2ba203fdd7cd27b23c8f000000000000000000000718',
  '0xedcfaf390906a8f91fb35b7bac23f3111dbaee1c00000000000000000000007c',
  '0x20715545c15c76461861cb0d6ba96929766d05a50000000000000000000000e8',
  '0xf970659221bb9d01b615321b63a26e857ffc030b0000000000000000000000e9',
  '0x78ab08bf98f90f29a09c9b1d85b3b549369b03a3000100000000000000000354',
  '0x098f32d98d0d64dba199fc1923d3bf4192e787190001000000000000000000d2',
  '0xf93579002dbe8046c43fefe86ec78b1112247bb8000000000000000000000759',
  '0x178e029173417b1f9c8bc16dcec6f697bc323746000000000000000000000758',
  '0x302b8b64795b064cadc32f74993a6372498608070001000000000000000003e0',
  '0x5ddb92a5340fd0ead3987d3661afcd6104c3b757000000000000000000000187',
  '0x2ff1552dd09f87d6774229ee5eca60cf570ae291000000000000000000000186',
  '0x117a3d474976274b37b7b94af5dcade5c90c6e85000000000000000000000381',
  '0x43da214fab3315aa6c02e0b8f2bfb7ef2e3c60a50000000000000000000000ae',
  '0xb5ad7d6d6f92a77f47f98c28c84893fbccc9480900000000000000000000006c',
  '0x71959b131426fdb7af01de8d7d4149ccaf09f8cc0000000000000000000002e7',
  '0x7e9250cc13559eb50536859e8c076ef53e275fb30000000000000000000000ce',
  '0xdfc65c1f15ad3507754ef0fd4ba67060c108db7e000000000000000000000406',
  '0x6da14f5acd58dd5c8e486cfa1dc1c550f5c61c1c0000000000000000000003cf',
  '0x88d07558470484c03d3bb44c3ecc36cafcf43253000000000000000000000051',
  '0x592fa9f9d58065096f2b7838709c116957d7b5cf00020000000000000000043c',
  '0x6af3737f6d58ae8bcb9f2b597125d37244596e590000000000000000000000d1',
  '0x6f34a44fce1506352a171232163e7716dd073ade000200000000000000000015',
  '0xb1c9ac57594e9b1ec0f3787d9f6744ef4cb0a02400000000000000000000006e',
  '0xf47f4d59c863c02cbfa3eefe6771b9c9fbe7b97800000000000000000000072b',
  '0xff2753aaba51c9f84689b9bd0a21b3cf380a1cff00000000000000000000072e',
  '0x685056d3a4e574b163d0fa05a78f1b0b3aa04a8000000000000000000000071a',
  '0xde45f101250f2ca1c0f8adfc172576d10c12072d00000000000000000000003f',
  '0xe274c9deb6ed34cfe4130f8d0a8a948dea5bb28600000000000000000000000d',
  '0x10441785a928040b456a179691141c48356eb3a50001000000000000000002fa',
  '0x64b301e21d640f9bef90458b0987d81fb4cf1b9e00020000000000000000022e',
  '0x959216bb492b2efa72b15b7aacea5b5c984c3cca000200000000000000000472',
  '0x7cf76bccfa5d3340d42f08351552f5a59dc6089c000000000000000000000396',
  '0x126e7643235ec0ab9c103c507642dc3f4ca23c66000000000000000000000468',
  '0xba7834bb3cd2db888e6a06fb45e82b4225cd0c71000000000000000000000043',
  '0x077794c30afeccdf5ad2abc0588e8cee7197b71a000000000000000000000352',
  '0x4b718e0e2fea1da68b763cd50c446fba03ceb2ea00000000000000000000000b',
  '0x05e7732bf9ae5592e6aa05afe8cd80f7ab0a7bea00020000000000000000005a',
  '0x981fb05b738e981ac532a99e77170ecb4bc27aef00010000000000000000004b',
  '0xba0e9aea8a7fa1daab4edf244191f2387a4e472b000100000000000000000737',
  '0xdd89c7cd0613c1557b2daac6ae663282900204f100000000000000000000003e',
  '0x6222ae1d2a9f6894da50aa25cb7b303497f9bebd000000000000000000000046',
  '0xfe0004ca84bac1d9cf24a3270bf70be7e68e43ac0000000000000000000003c5',
  '0x42538ce99111ea34dc2987b141bd6e9b594752d60000000000000000000002f9',
  '0x3c74c4ed512050eb843d89fb9dcd5ebb4668eb6d0002000000000000000000cc',
  '0x3b998ba87b11a1c5bc1770de9793b17a0da61561000000000000000000000185',
  '0xe1fb90d0d3b47e551d494d7ebe8f209753526b0100000000000000000000034e',
  '0x5bae72b75caab1f260d21bc028c630140607d6e8000000000000000000000350',
  '0x519cce718fcd11ac09194cff4517f12d263be067000000000000000000000382',
  '0x9253d7e1b42fa01ede2c53f3a21b3b4d13239cd4000000000000000000000042',
  '0x16c9a4d841e88e52b51936106010f27085a529ec00000000000000000000000c',
  '0x1e2576344d49779bdbb71b1b76193d27e6f996b700020000000000000000032d',
  '0x72d6df381cac8c2283c0b13fe5262a1f5e8e8d1b0000000000000000000000cb',
  '0xb54b2125b711cd183edd3dd09433439d5396165200000000000000000000075e',
  '0x894c82800526e0391e709c0983a5aea3718b7f6d00000000000000000000034f',
  '0x5bdd8c19b44c3e4a15305601a2c9841bde9366f00000000000000000000000ca',
  '0xa1a77e5d7d769bfbb790a08ec976dc738bf795b9000000000000000000000047',
  '0xa10285f445bcb521f1d623300dc4998b02f11c8f00000000000000000000043b',
  '0x888a6195d42a95e80d81e1c506172772a80b80bc000000000000000000000044',
  '0xc385e76e575b2d71eb877c27dcc1608f77fada99000000000000000000000719',
  '0x9b692f571b256140a39a34676bffa30634c586e100000000000000000000059d',
  '0x56897add6dc6abccf0ada1eb83d936818bc6ca4d0002000000000000000002e8',
  '0x44165fad0b7ea0d54d8856765d936d7026f9e2f20000000000000000000002f8',
  '0xb0de49429fbb80c635432bbad0b3965b2856017700010000000000000000004e',
  '0x428e1cc3099cf461b87d124957a0d48273f334b100000000000000000000007f',
  '0x359ea8618c405023fc4b98dab1b01f373792a12600010000000000000000004f',
  '0xd997f35c9b1281b82c8928039d14cddab5e13c2000000000000000000000019c',
  '0xcaa052584b462198a5a9356c28bce0634d65f65c0000000000000000000004db',
  '0x68a69c596b3839023c0e08d09682314f582314e5000200000000000000000011',
  '0x2e0d46d884af4053787e1838793bf98dcb87488e00020000000000000000072c',
  '0x7fe29a818438ed2759e30f65c2302295711d66fc0000000000000000000000e5',
  '0xdc2007d9e9a33f50630f26069faab69c25f7758c0000000000000000000000d6',
  '0x4fd63966879300cafafbb35d157dc5229278ed230000000000000000000000e9',
  '0xc8c79fcd0e859e7ec81118e91ce8e4379a481ee6000000000000000000000196',
  '0x70b7d3b3209a59fb0400e17f67f3ee8c37363f4900020000000000000000018f',
  '0x442988091cdc18acb8912cd3fe062cda9233f9dc00000000000000000000071d',
  '0x7449f09c8f0ed490472d7c14b4eef235620d027000010000000000000000072d',
  '0x159cb00338fb63f263fd6f621df619cef71da9540000000000000000000004d5',
  '0xc5dc1316ab670a2eed5716d7f19ced321191f38200000000000000000000056e',
  '0xa4e597c1bd01859b393b124ce18427aa4426a87100000000000000000000004c',
  '0x1f131ec1175f023ee1534b16fa8ab237c00e238100000000000000000000004a',
  '0xd0d334b6cfd77acc94bab28c778398238785644900000000000000000000004d',
  '0x4ce0bd7debf13434d3ae127430e9bd4291bfb61f00020000000000000000038b',
  '0x8f4063446f5011bc1c9f79a819efe87776f23704000000000000000000000197',
  '0x335d1709d4da9aca59d16328db5cd4ea66bfe06b0000000000000000000004d6',
  '0xa0051ab2c3eb7f17758428b02a07cf72eb0ef1a300000000000000000000071c',
  '0x3fcb7085b8f2f473f80bf6d879cae99ea4de934400000000000000000000056d',
  '0x8c63702d4d4a521a6a8ecec8ab8f7ce9d1d6299e000200000000000000000443',
  '0xa3823e50f20982656557a4a6a9c06ba5467ae9080000000000000000000000e6',
  '0xe6bcc79f328eec93d4ec8f7ed35534d9ab549faa0000000000000000000000e8',
  '0x652d486b80c461c397b0d95612a404da936f3db30000000000000000000000e7',
  '0x7337224d59cb16c2dc6938cd45a7b2c60c865d6a0000000000000000000004d4',
  '0xa718042e5622099e5f0ace4e7122058ab39e1bbe000200000000000000000475',
  '0x479a7d1fcdd71ce0c2ed3184bfbe9d23b92e8337000000000000000000000049',
  '0x4a0b73f0d13ff6d43e304a174697e3d5cfd310a400020000000000000000091c',
  '0x334c96d792e4b26b841d28f53235281cec1be1f200020000000000000000038a',
  '0x3c1420df122ac809b9d1ba77906f833764d6450100000000000000000000071b',
  '0xc0d7013a05860271a1edb52415cf74bc85b2ace7000000000000000000000048',
  '0x173063a30e095313eee39411f07e95a8a806014e0002000000000000000003ab',
  '0xb85a3fc39993b2e7e6874b8700b436c212a005160000000000000000000003d0',
  '0x1352fd97a1828093bf375f62e088bc196facd1ee000000000000000000000404',
  '0xd7edb56f63b2a0191742aea32df1f98ca81ed9c600000000000000000000058e',
  '0xa5a935833f6a5312715f182733eab088452335d7000100000000000000000bee',
  '0xff09914bf3d1f61ff3468cfcc4529665b908afa3000100000000000000000741',
  '0xb841b062ea8ccf5c4cb78032e91de4ae875560420002000000000000000005b7',
  '0xac976bb42cb0c85635644e8c7c74d0e0286aa61c0000000000000000000003cb',
  '0x4fd4687ec38220f805b6363c3c1e52d0df3b5023000200000000000000000473',
  '0xb973ca96a3f0d61045f53255e319aedb6ed4924000000000000000000000042f',
  '0x5b3240b6be3e7487d61cd1afdfc7fe4fa1d81e6400000000000000000000037b',
  '0x0392ebb4aea38233e5b89acfabf7b418cdea8e4300010000000000000000073f',
  '0x246ffb4d928e394a02e45761fecdba6c2e79b8eb000000000000000000000541',
  '0x3035917be42af437cbdd774be26b9ec90a2bd677000200000000000000000543',
  '0x4c8d2e60863e8d7e1033eda2b3d84e92a641802000000000000000000000040f',
  '0x3dbb8d974b82e82ce79c20c0f5995f4f1f533ede000000000000000000000470',
  '0x52cc8389c6b93d740325729cc7c958066cee4262000000000000000000000b0f',
  '0x8a819a4cabd6efcb4e5504fe8679a1abd831dd8f00000000000000000000042d',
  '0x483006684f422a9448023b2382615c57c5ecf18f000000000000000000000488',
  '0xe1c86d3908dc524aa3555e56adf973de7d3acdba000100000000000000000267',
  '0x624601b34e64a48ef91a6bf888e74ef3eceb1bf9000100000000000000000419',
  '0x31adc46737ebb8e0e4a391ec6c26438badaee8ca000000000000000000000306',
  '0x71bd10c2a590b5858f5576550c163976a48af906000000000000000000000b27',
  '0x0503dd6b2d3dd463c9bef67fb5156870af63393e00000000000000000000042e',
  '0xa55318e5d8b7584b8c0e5d3636545310bf9eeb8f000000000000000000000337',
  '0x9b1c8407a360443a9e5eca004713e4088fab8ac0000000000000000000000497',
  '0x53bc3cba3832ebecbfa002c12023f8ab1aa3a3a0000000000000000000000411',
  '0xfd11ccdbdb7ab91cb9427a6d6bf570c95876d1950000000000000000000004c2',
  '0xbfa413a2ff0f20456d57b643746133f54bfe0cd20000000000000000000004c3',
  '0xd6e355036f41dc261b3f1ed3bbc6003e87aadb4f000000000000000000000495',
  '0xdc063deafce952160ec112fa382ac206305657e60000000000000000000004c4',
  '0x00c2a4be503869fa751c2dbcb7156cc970b5a8da000000000000000000000477',
  '0xf71d0774b214c4cf51e33eb3d30ef98132e4dbaa00000000000000000000046e',
  '0x20b156776114e8a801e9767d90c6ccccc8adf398000000000000000000000499',
  '0x779d01f939d78a918a3de18cc236ee89221dfd4e0000000000000000000004c7',
  '0xba1a5b19d09a79dada039b1f974015c5a989d5fd000100000000000000000046',
  '0x8e6ec57a822c2f527f2df7c7d7d361df3e7530a1000000000000000000000498',
  '0x8d13d878e44e8005efc0db4a831b95f84cb4b1540000000000000000000003c6',
  '0xb0f75e97a114a4eb4a425edc48990e6760726709000000000000000000000198',
  '0x6a1eb2e9b45e772f55bd9a34659a04b6f75da68700000000000000000000040d',
  '0xb5e3de837f869b0248825e0175da73d4e8c3db6b000200000000000000000474',
  '0xe15cac1df3621e001f76210ab12a7f1a1691481f000000000000000000000044',
  '0x9a020bdc2faff5bd24c6acc2020d01ff9f2c627a000000000000000000000ae2',
  '0xb9bd68a77ccf8314c0dfe51bc291c77590c4e9e6000200000000000000000385',
  '0xeb480dbbdd921cd6c359e4cc4c65ddea6395e2a1000200000000000000000946',
  '0x93c7defe51d787010babfdb19504d5a72166e11200020000000000000000041c',
  '0x4739e50b59b552d490d3fdc60d200977a38510c0000000000000000000000b10',
  '0x62de5ca16a618e22f6dfe5315ebd31acb10c44b6000000000000000000000037',
  '0x10b040038f87219d9b42e025e3bd9b8095c87dd9000000000000000000000b11',
  '0x30c016f5cf1d34c1bce4c8c9c302f66a268847fd0001000000000000000003f6',
  '0x9321e2250767d79bab5aa06daa8606a2b3b7b4c5000000000000000000000bf4',
  '0x1aafc31091d93c3ff003cff5d2d8f7ba2e7284250000000000000000000003b3',
  '0xd90dc295d571adc7575563d892aa96ac3811d21c000200000000000000000402',
  '0x3e9cbffd270ae67abb09d28988e7e785498c73730000000000000000000000ee',
  '0xbef1ccaada458a570c37b11a8872988ba1e4fdb90000000000000000000000dd',
  '0x55b1f937b1335be355c82e207fd437182c986ba10000000000000000000000dc',
  '0x4de21b365d6543661d0e105e579a34b963862497000200000000000000000045',
  '0xdc910e2647caae5f63a760b70a2308e1c90d88860000000000000000000006ef',
  '0x75062a04a8cc587c588a6bb50bd0cc009da483dc000000000000000000000035',
  '0xb8b0e5e9f8b740b557e7c26fcbc753523a718a870000000000000000000006ee',
  '0x48ace81c09382bfc08ed102e7eadd37e3b0497520000000000000000000000ec',
  '0xb85245929dc65b5eddb56c4b4e84b20bce69db350000000000000000000000da',
  '0x9e34631547adcf2f8cefa0f5f223955c7b137571000000000000000000000ad5',
  '0x64cee2338369aa9b36fc756ea231eb9bc242926f0000000000000000000000df',
  '0x3db543faf7a92052de7860c5c9debabee59ed5bd000000000000000000000a62',
  '0xe2dc0e0f2c358d6e31836dee69a558ab8d1390e70000000000000000000009fa',
  '0x8fe33d737484ca194dedf64aafa8485327fc53720000000000000000000000d8',
  '0xa5d4802b4ce6b745b0c9e1b4a79c093d197869c80000000000000000000000ea',
  '0x8e58191cb73b283af2d7a8ef3f9ce1544db2b8280000000000000000000005ee',
  '0xd32f78f5ae235269c6d2cabbd26a57ff9fd629670000000000000000000000db',
  '0xf22a66046b5307842f21b311ecb4c462c24c0635000000000000000000000b15',
  '0x055a4cfa8cd9ced1d7bc9ae5eb46c404c130e46d0000000000000000000000cd',
  '0x2e2b8b82123789d895fd79913f6dfa51f5b5a0e60000000000000000000000eb',
  '0x654def39262548cc958d07c82622e23c52411c820000000000000000000006ec',
  '0x8025586ac5fb265a23b9492e7414beccc2059ec30000000000000000000000ed',
  '0xd69959fa7910ceb3a2d359ed33cb8297670b69370000000000000000000005b2',
  '0x3f7a7fd7f214be45ec26820fd01ac3be4fc75aa70002000000000000000004c5',
  '0x9964b1bd3cc530e5c58ba564e45d45290f677be2000000000000000000000036',
  '0xa9cb51abfbbf2ca877b290e988b453f8bf4ab630000000000000000000000430',
  '0xf86785fe1cefd5069e6df1b4b54b72b1992003110000000000000000000005b3',
  '0x55e0499d268858a5e804d7864dc2a6b4ef194c630000000000000000000005b1',
  '0xf7705cd188a8ac806d28f85bdb13a38313e985ff0000000000000000000005b7',
  '0x2c4a83f98d1cdbeeec825fabacd09c46e2dd3c570002000000000000000000de',
  '0x8003eec4add35c6d23eb1ef61b4fa6bbbb23a41a0000000000000000000000d9',
  '0x015f34e47ca0a88675098c4d6601817403f07a32000200000000000000000723',
  '0xbd4e35784c832d0f9049b54cb3609e5907c5b495000100000000000000000b14',
  '0xa9a1f2f7407ce27bcef35d04c47e079e7d6d399e0000000000000000000005b6',
  '0x8b6d3aa69c1cf47677281691b1abf3831ba1329d0001000000000000000000d0',
  '0xe051605a83deae38d26a7346b100ef1ac2ef8a0b0000000000000000000003ce',
  '0xe0b50b0635b90f7021d2618f76ab9a31b92d009400010000000000000000003a',
  '0xce9329f138cd6319fcfbd8704e6ae50b6bb04f31000000000000000000000033',
  '0x57793d39e8787ee6295f6a27a81b6cca68e85cdf000000000000000000000397',
  '0x8a2872fd28f42bd9f6559907235e83fbf4167f480001000000000000000000f2',
  '0x362715c164d606682c4ea7e479633e419d9345eb0001000000000000000000e7',
  '0xac6286126044e2ee2589d394a102cb54b7ab15a7000200000000000000000629',
  '0xd4500f270880488a481de1b3256a19b3d9c8fd7e000000000000000000000710',
  '0xcd7b2232b7435595bbc7fd7962f1f352fc2cc61a0000000000000000000000f0',
  '0x15873081c0aa67ad5c5dba362169d352e2a128a2000000000000000000000032',
  '0xf572649606db4743d217a2fa6e8b8eb79742c24a000000000000000000000039',
  '0xb4aae62a3f10717b9c649ed5ce099d981e5dca8c00000000000000000000042b',
  '0xe191504f9127deb015910768c8a6ac71d185bf91000200000000000000000603',
  '0xecc53ac812123d471360ea3d90023318868b56a5000000000000000000000429',
  '0xbec621c9ab4ceddcc2a157ca9b5c475fab65f6a40000000000000000000000f3',
  '0xd6d20527c7b0669989ee082b9d3a1c63af742290000000000000000000000483',
  '0xd3e47cbdb2a9c1b1deb857c6415b87fce63a4bf5000000000000000000000034',
  '0xd3f155d7f421414dc4177e54e4308274dfa8b9680000000000000000000006ed',
  '0x91e96deddca930669feb699d16cc3416289ec7aa000100000000000000000748',
  '0xdb455199d96d5513d831f0029bd819597bc9d158000200000000000000000728',
  '0x230112fa4b07234769fc92bd616c8c77f4fe0e5300000000000000000000042a',
  '0xc46066ff87b3861ffc5c26ad2e9705190c22aa56000000000000000000000727',
  '0x5470f064a19c65263b3033da3a6124fdf0a9bab80000000000000000000000e6',
  '0xcb89e89d798a4563d1599ea5508282e13b225b520000000000000000000000e4',
  '0x11839d635e2f0270da37e8ef4324d4d5d54329570002000000000000000004d8',
  '0xe94c45de980f914904fdcfa9fbbe7c4a0ffe6ac70000000000000000000000e0',
  '0x904018c54b2382929b15abaae55136a392af4294000100000000000000000729',
  '0x7d6bff131b359da66d92f215fd4e186003bfaa42000000000000000000000058',
  '0x2b4af4bb149cc06f5de580be013e86f81e4d2b30000100000000000000000373',
  '0xfbf87d2c22d1d298298ab5b0ec957583a2731d15000000000000000000000052',
  '0x3dd0843a028c86e0b760b1a76929d1c5ef93a2dd00000000000000000000070d',
  '0x23ca0306b21ea71552b148cf3c4db4fc85ae19290000000000000000000000c9',
  '0xe6909c2f18a29d97217a6146f045e1780606991f000100000000000000000bfe',
  '0x0c06e87c7b88d998f645b91c1f53b51294b12bca000100000000000000000bb9',
  '0x2ba7aa2213fa2c909cd9e46fed5a0059542b36b00000000000000000000003a3',
  '0xeb38aa08bc00ba68237543d2daa1476b4dfd37f800000000000000000000073a',
  '0xf0211cceebe6fcc45052b4e57ee95d233f5669d2000100000000000000000c01',
  '0x252ff6a3a6fd7b5e8e999de8e3f5c3b306ed1401000200000000000000000bec',
  '0xf51776b52dfb5bf9a7f3ed150c20e78d4dff6e640002000000000000000004e9',
  '0x6abe4e7a497b8293c258389c1b00d177e4f257ed00010000000000000000080d',
  '0xe0042e7ee284ff355622b7660ccb34be114936fa000100000000000000000400',
  '0x547e9ad4b824f09e9cf1c6d163cf308d4cf998120001000000000000000003c9',
  '0xfe48fefea11cceb3bdeb328428f1b25446edad700001000000000000000003d2',
  '0xcf87708ea94916d7ccd13b86ce56006472d806fe000100000000000000000378',
  '0x89f1146fee52b5d9166e9c83cc388b6d8f69f1380001000000000000000009e7',
  '0x4b18597d3f7c9786a133827572e6a318d55c9fd200020000000000000000028b',
  '0xea52e5eb660ba64b9ba10ad9ae55a8156aa4d29a0002000000000000000003a2',
  '0xf52fc9d5aa16c782c9ba51be0da10f1ccf05c702000100000000000000000394',
  '0x968024662b9566b42d78af23a0f441bc8723fa83000200000000000000000418',
  '0x600bd01b6526611079e12e1ff93aba7a3e34226f0000000000000000000009e4',
  '0x8d7ca68d9a33148daf3ad1a495ed290f4eee013e0001000000000000000003b9',
  '0x9e2d87f904862671eb49cb358e74284762cc9f42000200000000000000000013',
  '0x5a6a8cffb4347ff7fc484bf5f0f8a2e234d34255000200000000000000000275',
  '0x32be2d0ddeaf3333501b24a28668ce373ba8e763000200000000000000000014',
  '0xff600724d670727872a1f7483049326c111d993d000100000000000000000448',
  '0x7839210cd48356bdd6fd400e30cfc7140e1e5ad6000100000000000000000449',
  '0x59cfc2307e8b218c242ba61407a07cade73bd6d7000100000000000000000585',
  '0xea256adb68dffd067d27e95f4ad14eba12e86079000100000000000000000586',
  '0x4c36a9a52ca3baf1069e3531d57d96c171a66a230002000000000000000001e9',
  '0x980dfa8bd5c4a96e1b762fe8154b8a2045dab2d70002000000000000000003ef',
  '0x2a96254ca32020b20ed3506f8f75318da24709f9000200000000000000000456',
  '0x2c8dbe8eb86135d9f2f26d196748c088d47f73e7000200000000000000000a29',
  '0x341068a547c3cde3c09e338714010dd01b32f93f000200000000000000000a34',
  '0x150e7b885bdfce974f2abe88a72fdbd692175c6f0002000000000000000009fd',
  '0x4ccb966d8246240afb7a1a24628efb930870b1c40002000000000000000009fc',
  '0x02e139d53ebf4033bf78ab66c6a1e7f1f204487f0002000000000000000009f9',
  '0x53dd233c2af0147846579010b7c80bf9440afff4000200000000000000000602',
  '0x9cf9358300e34bf9373d30129a1e718d8d058b54000200000000000000000913',
  '0x05513ca725b6ce035ca2641075474eb469f05f4c00020000000000000000041f',
  '0x8b8225bfedebaf1708c55743acb4ad43fd4d0f21000200000000000000000918',
  '0x559d2ac340216e3a6630741147cda6a2cdbc2be10001000000000000000005de',
  '0x8b58a1e7fff52001c22386c2918d45938a6a9be30001000000000000000008d9',
  '0x6c56e72c551b5ac4bf54a620a76077ca768c8fe40002000000000000000004da',
  '0x88e2a551655daadd7e4e67d14cf48bfb413d72680001000000000000000005d2',
  '0xf227486361252907cb768142a2f4caed08a3d7cb0001000000000000000005dd',
  '0x50fd4d5d60d6df38f5e29721bc241b537e182bf40002000000000000000005f9',
  '0xa5eb9166679a85bdb3eaa2941ed35c8d909484db00020000000000000000052b',
  '0x174d2608b1d794e9078ae2a4861684a38d4e7ae200020000000000000000065b',
  '0x0a0fb4ff697de5ac5b6770cd8ee1b72af80b57cf000000000000000000000496',
  '0x0afbd58beca09545e4fb67772faf3858e610bcd00000000000000000000004b9',
  '0x0d05aac44ac7dd3c7ba5d50be93eb884a057d23400000000000000000000051c',
  '0x331d50e0b00fc1c32742f151e56b9b616227e23e00000000000000000000047c',
  '0x395d8a1d9ad82b5abe558f8abbfe183b27138af40000000000000000000004e5',
  '0x3bb22fc9033b802f2ac47c18885f63476f158afc000000000000000000000483',
  '0x3cdae4f12a67ba563499e102f309c73213cb241c000000000000000000000335',
  '0x454ed96955d04d2f5cdd05e0fd1c77975bfe5307000000000000000000000410',
  '0x4ce277df0feb5b4d07a0ca2adcf5326e4005239d000000000000000000000518',
  '0x74cbfaf94a3577c539a9dcee9870a6349a33b34f000000000000000000000534',
  '0x813e3fe1761f714c502d1d2d3a7cceb33f37f59d00000000000000000000040c',
  '0x9516a2d25958edb8da246a320f2c7d94a0dbe25d000000000000000000000519',
  '0x9fb771d530b0ceba5160f7bfe2dd1e8b8aa1340300000000000000000000040e',
  '0xa8b103a10a94f4f2d7ed2fdcd5545e807557330700000000000000000000048e',
  '0xac5b4ef7ede2f2843a704e96dcaa637f4ba3dc3f00000000000000000000051d',
  '0xdec02e6642e2c999af429f5ce944653cad15e093000000000000000000000469',
  '0xe4dc3c1998ac693d68f4c77476d7c815694c3e94000200000000000000000416',
  '0xf22ff21e17157340575158ad7394e068048dd98b0000000000000000000004b8',
  '0xfef969638c52899f91781f1be594af6f40b99bad00000000000000000000047b',
  '0x0320c1c5b6df19a194d48882aaec1c72940081d9000000000000000000000a7d',
  '0x0889b240a5876aae745ac19f1771853671dc5d36000000000000000000000b3f',
  '0x117a3d474976274b37b7b94af5dcade5c90c6e85000000000000000000000aca',
  '0x11884da90fb4221b3aa288a7741c51ec4fc43b2f000000000000000000000a5f',
  '0x284eb68520c8fa83361c1a3a5910aec7f873c18b000000000000000000000ac9',
  '0x31bccf9e28b94e5dacebaa67fe8bc1603cecd904000000000000000000000a01',
  '0x4ae3661afa119892f0cc8c43edaf6a94989ac171000000000000000000000c06',
  '0x5bae72b75caab1f260d21bc028c630140607d6e8000000000000000000000ac6',
  '0x6933ec1ca55c06a894107860c92acdfd2dd8512f000000000000000000000428',
  '0x7079a25dec33be61bbd81b2fb69b468e80d3e72c0000000000000000000009ff',
  '0x86aef31951e0a3a54333bd9e72f9a95587d058c5000200000000000000000912',
  '0x882c7a84231484b3e9f3fd45ac04b1eb5d35b076000200000000000000000a91',
  '0x894c82800526e0391e709c0983a5aea3718b7f6d000000000000000000000ac5',
  '0x949a12b95ec5b80c375b98963a5d6b33b0d0efff0002000000000000000009fe',
  '0xaef2c171dbe64b0c18977e16e70bfd29d4ee0256000000000000000000000ac8',
  '0xb878ecce26838fbba4f78cb5b791a0e09152c067000000000000000000000427',
  '0xc55ec796a4debe625d95436a3531f4950b11bdcf000000000000000000000b3e',
  '0xc7e6389e364f4275eb442ef215ed21877028e2af000000000000000000000ac7',
  '0xc83b55bbd005f1f84906545fcdb145dee53523e0000200000000000000000b30',
  '0xcb21a9e647c95127ed784626485b3104cb28d0e7000000000000000000000425',
  '0xdae301690004946424e41051ace1791083be42a1000000000000000000000b40',
  '0xe1fb90d0d3b47e551d494d7ebe8f209753526b01000000000000000000000ac4',
  '0xf48f01dcb2cbb3ee1f6aab0e742c2d3941039d56000000000000000000000445',
  '0xf984eb2b8a7ef780245a797a2fccd82f346409ca000000000000000000000a59',
  '0xfa2c0bd8327c99db5bde4c9e9e5cbf30946351bb000000000000000000000948',
  '0x1ac55c31dac78ca943cb8ebfca5945ce09e036e2000000000000000000000024',
  '0x225e0047671939a8d78e08ebd692788abe63f15c000000000000000000000009',
  '0x581ec1f5e7ced12b186deae32256adb53bdd5b08000000000000000000000001',
  '0xa3ed6f78edc29f69df8a0d16b1d1ccf9871f918c000000000000000000000032',
  '0xb95829adbacd8af89e291dee78bc09e24de51d6b000000000000000000000043',
  '0x11884da90fb4221b3aa288a7741c51ec4fc43b2f000000000000000000000353',
  '0x19b1c92631405a0a9495ccba0becf4f2e8e908bd000000000000000000000410',
  '0x1e550b7764da9638fdd32c8a701364de31f45ee800000000000000000000047c',
  '0x1fa7f727934226aedab636d62a084931b97d366b000000000000000000000411',
  '0x3f53a862919ccfa023cb6ace91378a79fb0f6bf500000000000000000000040f',
  '0x52cc8389c6b93d740325729cc7c958066cee4262000000000000000000000408',
  '0x81fc12c60ee5b753cf5fd0adc342dfb5f3817e3200000000000000000000035d',
  '0x9bf7c3b63c77b4b4f2717776f15a4bec1b532a280000000000000000000000c8',
  '0xa612b6aed2e7ca1a3a4f23fbca9128461bbb7718000000000000000000000274',
  '0xae646817e458c0be890b81e8d880206710e3c44e00000000000000000000039d',
  '0xaef2c171dbe64b0c18977e16e70bfd29d4ee0256000000000000000000000351',
  '0xf984eb2b8a7ef780245a797a2fccd82f346409ca00000000000000000000034d',
  '0x11884da90fb4221b3aa288a7741c51ec4fc43b2f00000000000000000000009e',
  '0x24d9ff56fb09e0f02e74407b12f3264c6e520b5e0000000000000000000000d7',
  '0x2da61ef3cdcb97efb0f7099c02527fabfe94dee50000000000000000000000c5',
  '0x34557ef500bfc060b8e3f2da58ef725bb7d322020000000000000000000000c7',
  '0x3b2ff5888f1bdedbb45ece9f1c628356388d22cc000000000000000000000045',
  '0x435272180a4125f3b47c92826f482fc6cc165958000200000000000000000059',
  '0x45d37982784f022a9864748b4e8750e1e70196040000000000000000000000c6',
  '0x5936497ffe0dba0ef272d6301d65c0122862971a00000000000000000000007a',
  '0x593acbfb1eaf3b6ec86fa60325d816996fdcbc0d000000000000000000000038',
  '0x5d8955a2068ee6d1f7ab17640301219afa4b526f00000000000000000000002d',
  '0x81fc12c60ee5b753cf5fd0adc342dfb5f3817e320000000000000000000000a6',
  '0x96a78983932b8739d1117b16d30c15607926b0c500000000000000000000006d',
  '0xd1af4974fcc995cf36ba40b189caa92964a9126d0000000000000000000000f1',
  '0xf984eb2b8a7ef780245a797a2fccd82f346409ca00000000000000000000009d',
  '0xa826a114b0c7db4d1ff4a4be845a78998c64564c000000000000000000000008',
  '0xed3e2f496cbcd8e212192fb8d1499842f04a0d19000000000000000000000009',
  '0x198a22e73aadd2d0ea8e2963799d38ae26adee2e000000000000000000000577',
  '0x3ee7a82c1ecf54a375da1e3452b21ac986f348db00000000000000000000076e',
  '0x49769fe4c796b2775d2e44982cca8cdae36f6671000000000000000000000428',
  '0x64dea772866476c9f88fbe95ee83664d6c909c1800000000000000000000022c',
  '0xa02c23525f12c677156d076268f4b44e12e38e4d00000000000000000000076d',
  '0xa8bcdca345e61bad9bb539933a4009f7a6f4b7ea0000000000000000000006eb',
  '0xd20547850f3f80204439eafc942ec4780d32f2bd000000000000000000000667',
  '0xefc1bb0b97780cf7c22059aa7c7e7e88a049d21100020000000000000000041b',
  '0xffecea216f0d0d08bfe2cf572e03f217f8a2bf1300020000000000000000041a',
  '0x02c9dcb975262719a61f9b40bdf0987ead9add3a000000000000000000000006',
  '0x32f03464fdf909fdf3798f87ff3712b10c59bd86000000000000000000000005',
  '0xac4b72c01072a52b73ca71105504f1372efcce0d000000000000000000000003',
  '0xbfd65c6160cfd638a85c645e6e6d8acac5dac935000000000000000000000004',
];

export function isHighRisk(poolId: string): boolean {
  return HIGH_RISK_POOL_IDS.includes(poolId);
}
